import React from 'react';

import * as SC from './UserStatusLabel.styled';
import useUserFeatures from '../../../Hooks/useUserFeatures';
import { USER_ROLE } from '../../../Util/userfeaturesHelper';

export const UserStatusLabel: React.FC = () => {
    const { data, isPremiumRole, hasRole, hasAnyRole } = useUserFeatures();

    const isAuthenticated = hasRole(USER_ROLE.Authenticated);

    if (!isAuthenticated) {
        return null;
    }

    const isDisplayedAsTrial = !!data?.features.displayAsTrial;

    let labelCopy = 'Basis';

    if (isDisplayedAsTrial) {
        labelCopy = 'Premium-Test';
    } else if (hasAnyRole(
        USER_ROLE.PremiumPlus,
        USER_ROLE.LicenseePremium,
        USER_ROLE.LicenseeCharityPremium,
    )) {
        labelCopy = 'Premium Plus';
    } else if (isPremiumRole || hasRole(USER_ROLE.Student)) {
        labelCopy = 'Premium';
    }

    return (
        <SC.Wrapper className="user-status">
            {labelCopy}
        </SC.Wrapper>
    );
};