import { css } from 'styled-components';

import { Colors } from './Colors';

export const disabledScrollbarStyles = css`
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const synonymHighliteStyles = css`
    background-color: ${Colors.diamond};
`;

export const loadingTextStyles = css`
    color: transparent;
    background-color: ${Colors.mystic};
    pointer-events: none;
    text-decoration: none;
    user-select: none;
    animation: blinking 1s infinite;

    * {
        opacity: 0;
    }

    @keyframes blinking {
        0% {
            opacity: 0.5;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0.5;
        }
    }
`;

export const getLighterHexColor = (color: string, percent: number) => {
    const hex = color.replace(/^#/, '');

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    const lighterR = Math.min(255, Math.round(r + (255 - r) * percent)).toString(16).padStart(2, '0');
    const lighterG = Math.min(255, Math.round(g + (255 - g) * percent)).toString(16).padStart(2, '0');
    const lighterB = Math.min(255, Math.round(b + (255 - b) * percent)).toString(16).padStart(2, '0');

    return `#${lighterR}${lighterG}${lighterB}`;
};