import React from 'react';

import useAiStore, { INTERACTION_MODE } from '../../../../Hooks/useAiStore';
import { AiSuggestionItem } from '../AiSuggestionItem';

type AiSuggestionLoadingStateProps = {
    count?: number;
};

const AiSuggestionLoadingState: React.FC<AiSuggestionLoadingStateProps> = ({ count = 3 }) => {
    const { query, text, mode } = useAiStore(store => store.interaction);

    const isRephrasingMode = mode === INTERACTION_MODE.Rephrase;

    return <>
        {
            Array(count).fill('_').map((_, i) =>
                <AiSuggestionItem key={`loader_${i}`} isLoader word={isRephrasingMode ? undefined : query} text={text!} />)
        }
    </>;
};

export default AiSuggestionLoadingState;
