import {useSelector} from 'react-redux';

import {UserDataPayload, UserFeatureName, UserFeature, UserRole, selectUserRoles} from '../Store/UserState';

export type ReceivedUserData = Omit<UserDataPayload, 'features'> & {availableFeatures: UserFeature[]};

export function hasRole(role: UserRole, roles: UserRole[]): boolean {
    return roles !== undefined && !!roles.find(
        (element: UserRole): boolean => element === role,
    );
}

export function hasAnyRole(roles: UserRole[], ...rolesToCheck: UserRole[]): boolean {
    for (const roleToCheck of rolesToCheck) {
        if (hasRole(roleToCheck, roles)) {
            return true;
        }
    }
    return false;
}

export function hasFeature(featureName: UserFeatureName, features: UserFeature[]): boolean {
    return features !== undefined && !!features.find(
        (element: UserFeature): boolean => element.name === featureName && element.available,
    );
}

export function hasAnyFeature(features: UserFeature[], ...featuresToCheck: UserFeatureName[]): boolean {
    for (const featureToCheck of featuresToCheck) {
        if (hasFeature(featureToCheck, features)) {
            return true;
        }
    }

    return false;
}

export function isBasicOrPlusUser(roles: UserRole[]): boolean {
    return hasAnyRole(roles, UserRole.Basis, UserRole.Plus, UserRole.B2B_Licensee_Basis, UserRole.B2B_Licensemanager_Basis)
        && !hasAnyRole(roles, UserRole.Trial, UserRole.Premium, UserRole.Premium20, UserRole.B2B_Licensemanager_Premium20, UserRole.B2B_Licensee_Premium20);
}

export const isPremiumUser = (roles: UserRole[]) => hasAnyRole(roles,
    UserRole.Premium,
    UserRole.Premium20,
    UserRole.B2B_Licensee_Premium20,
    UserRole.B2B_Licensee_Charity_Premium,
    UserRole.PremiumPlus,
)

export const isTrialUser = (roles: UserRole[]) => hasAnyRole(roles, UserRole.Trial);

export const isPlusUser = (roles: UserRole[]) => hasAnyRole(roles, UserRole.PremiumPlus);

export const isLicenseeUser = (roles: UserRole[]) => hasAnyRole(roles, UserRole.B2B_Licensee_Premium20, UserRole.B2B_Licensee_Charity_Premium);

export function mapUserFeatureData(userData: ReceivedUserData): UserDataPayload {
    return {
        hasUsedTrial: userData.hasUsedTrial,
        roles: userData.roles,
        username: userData.username,
        autoLogInterval: userData.autoLogInterval,
        characterLimit: userData.characterLimit,
        features: userData.availableFeatures,
        id: userData.id,
        trialDaysRemaining: userData.trialDaysRemaining,
        trialDuration: userData.trialDuration,
        inlineAdvices: userData.inlineAdvices,
        showDiscount: userData.showDiscount,
        hasHadPremium: userData.hasHadPremium,
        checkSettings: userData.checkSettings,
        email: userData.email,
        hide_consent: userData.hide_consent,
        hide_onboarding: userData.hide_onboarding
    };
}

export function mapUserRole(role: UserRole): string {
    switch (role) {
        case UserRole.Basis:
            return 'Basis-Nutzer';
        case UserRole.Plus:
            return 'Plus-Nutzer';
        case UserRole.Trial:
        case UserRole.Premium:
            return 'Premium-Nutzer';
        case UserRole.Premium20:
            return 'Premium 20-Nutzer';
        default:
            return 'Gast-Nutzer';
    }
}

export function useUserRoles(...userRolesToCheck: UserRole[]): boolean {
    const userRoles = useSelector(selectUserRoles);
    return hasAnyRole(userRoles, ...userRolesToCheck);
}
