import React from 'react';

import * as SC from './Settings.styled';
import { SettingsForm } from '../../Components/SettingsForm';
import { ErrorWrapper } from '../../Util/ErrorWrapper';


const Settings: React.FC = () => {
    return (
        <SC.Wrapper>
            <ErrorWrapper>
                <SettingsForm />
            </ErrorWrapper>
        </SC.Wrapper>
    );
};

export default Settings;
