import styled from 'styled-components';

import { Colors, Fonts } from '../../Styles';

export const Wrapper = styled.label`
    display: flex;
    gap: 10px;
    cursor: pointer;
    align-items: center;
`;

export const Toggle = styled.div`
    width: 42px;
    height: 24px;
    flex: 0 0 auto;
    border-radius: 24px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: ${Colors.osloGray};
    border: 3px solid ${Colors.osloGray};
    position: relative;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    &:hover {
        opacity: 0.8;
    }

    &:has(input:checked) {
        background-color: ${Colors.mineShaft};
        border-color: ${Colors.mineShaft};

        & > * {
            right: 0;
            left: auto;
        }

        svg {
            opacity: 1;
        }
    }
`;

export const Circle = styled.div`
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    border-radius: 50%;
    background-color: ${Colors.white};
    top: 0;
    left: 0;
    bottom: 0;
    aspect-ratio: 1 / 1;

    & svg {
        opacity: 0;
    }
`;

export const Label = styled.span`
    font-family: ${Fonts.theinhardt};
    font-size: 15px;
    line-height: 15px;
    font-style: normal;
    font-weight: 400;
`;