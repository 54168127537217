import React, { useCallback, useEffect, useRef } from 'react';

import * as SC from './AiGeneralMessage.styled';
import useAiStore, { MESSAGE_CODE } from '../../../Hooks/useAiStore';
import { formatToThousandSeparator } from '../../../Util/formatingHelper';
import AiMessage, { MESSAGE_APPEARANCE } from '../AiMessage';

type AiGeneralMessageProps = {};

const AiGeneralMessage: React.FC<AiGeneralMessageProps> = () => {
    const generalError = useAiStore(store => store.error);
    const charactersLimit = useAiStore(store => store.summary.charactersLimit);

    const mutableStateRef = useRef<{ timeoutId: NodeJS.Timeout | null }>({ timeoutId: null });

    const handleClose = useCallback(() => {
        useAiStore.setState({ error: null });
    }, []);

    let { title, content, code, appearance = MESSAGE_APPEARANCE.General, autoClose } = generalError ?? {};

    if (code === MESSAGE_CODE.Common) {
        title = 'Anfrage nicht erfolgreich';
        content = 'Ihre Anfrage konnte leider nicht verarbeitet werden. Bitte versuchen Sie es erneut.';
    }

    if (code === MESSAGE_CODE.LongText) {
        title = 'Keine Optimierung möglich';
        content = `Der eingegebene Text darf maximal ${formatToThousandSeparator(charactersLimit ?? 0, '\u00A0')} Zeichen lang sein. Bitte kürzen Sie ihn entsprechend.`;
    }

    if (code === MESSAGE_CODE.ZeroChanges) {
        title = 'Alles korrekt';
        content = `Es wurden keine Fehler gefunden.`;
        appearance = MESSAGE_APPEARANCE.Success;
    }

    useEffect(() => {
        if (autoClose) {
            mutableStateRef.current.timeoutId = setTimeout(() => {
                handleClose();
            }, autoClose);
        }

        return () => {
            mutableStateRef.current.timeoutId && clearTimeout(mutableStateRef.current.timeoutId);
        };
    }, [autoClose, generalError, handleClose]);

    if (!title && !content) {
        return null;
    }

    return <SC.Wrapper>
        <AiMessage
            title={title}
            appearance={appearance}
            handleClose={autoClose ? undefined : handleClose}
        >
            {content}
        </AiMessage>
    </SC.Wrapper>;
};

export default AiGeneralMessage;
