import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { initializeSettingsAction } from '../Store/SettingsState';
import { apiFetch } from '../Util/RequestApi';

type FeatureFlags = {
    click_correction: boolean;
    native_editor: boolean;
    new_mentor_ai: boolean;
    paraphrasing: boolean;
    payment: boolean;
    registration: boolean;
    mentor_default_view: 'ai' | 'classic'
}

export type FeatureFlagsResponse = {
    featureFlags: FeatureFlags;
};

const useFeatureFlags = () => {
    const dispatch = useDispatch(); // TODO: revove when saga will be skipped

    const queryResult = useQuery<FeatureFlagsResponse>({
        queryKey: ['featureflags'],
        queryFn: async () => {
            try {
                const response = await apiFetch('api/settings');

                if (response.status !== 200) {
                    return;
                }

                const responseJson = await response.json();

                // Syncronize feature flags state with redux (to keep old logic working)
                dispatch(initializeSettingsAction(responseJson)); // TODO: revove when saga will be skipped

                return responseJson;
            } catch (_err) {
                return;
            }
        },
        staleTime: Number.POSITIVE_INFINITY,
    });

    const normalizedData = useMemo(() => {
        const featureFlags = queryResult.data?.featureFlags;

        return {
            clickCorrection: !!featureFlags?.click_correction,
            nativeEditor: !!featureFlags?.native_editor,
            newMentorAi: !!featureFlags?.new_mentor_ai,
            paraphrasing: !!featureFlags?.paraphrasing,
            payment: !!featureFlags?.payment,
            registration: !!featureFlags?.registration,
            mentorDefaultView: featureFlags?.mentor_default_view ?? 'ai',
        }
    }, [queryResult.data]);

    return {
        ...queryResult,
        data: normalizedData,
    };
};

export default useFeatureFlags;