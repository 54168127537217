import React, { useEffect, useRef } from 'react';

import { useForm } from 'react-hook-form';

import { ForbiddenPopup } from './ForbiddenPopup';
import * as SC from './SettingsForm.styled';
import useCorrectionStore from '../../Hooks/useCorrectionStore';
import useGenericStore, { BANNER_APPEARANCE } from '../../Hooks/useGenericStore';
import useUserFeatures, { UserSettings } from '../../Hooks/useUserFeatures';
import useUserSettingsUpdate from '../../Hooks/useUserSettingsUpdate';
import { USER_ROLE } from '../../Util/userfeaturesHelper';
import { Toggle } from '../Toggle';

type SettingsFormProps = Pick<
    UserSettings,
    'language'
    | 'applyDpfCommaRules'
    | 'styleWordFrequency'
    | 'styleFiller'
    | 'styleOverlong'
    | 'styleOverlongWordCount'
> & { styleGeneral: boolean };

const MINIMUM_WORD_COUNT = 5;
const BANNER_VISIBILITY_TIME = 4000;
const AUTOSAVE_TIMEOUT = 2000;
const validateCountValue = (num?: number) => {
    if (typeof num !== 'number' || !Number.isInteger(num)) return 'Bitte geben Sie nur Ganzzahlen ein.';

    if (num < MINIMUM_WORD_COUNT) return `Bitte geben Sie mindestens ${MINIMUM_WORD_COUNT} ein.`;

    return;
};

const SettingsForm: React.FC = () => {
    const { data: userData, hasAnyRole, isPremiumRole, isTrialRole, isStudentRole } = useUserFeatures();
    const { mutate } = useUserSettingsUpdate();

    const mutableStateRef = useRef<{
        timeoutId: NodeJS.Timeout | null,
        isDirty: boolean,
    }>({ timeoutId: null, isDirty: false });

    const setBanner = useGenericStore(state => state.actions.setBanner);
    const handleSegmentsInvalidate = useCorrectionStore((state) => state.handleSegmentsInvalidate);

    const settings: Partial<UserSettings> = userData?.settings ?? {};
    const isSettingsVisible = !hasAnyRole(
        USER_ROLE.StudentLicenseePremium,
        USER_ROLE.SchoolLicensemanagerBasic,
        USER_ROLE.SchoolLicensemanagerPremium,
    );
    const isSettingsForbidden = !(isPremiumRole || isTrialRole || isStudentRole);

    const { register, watch, formState: { errors }, setValue, getValues } = useForm<SettingsFormProps>({
        mode: 'onChange',
        defaultValues: {
            language: settings.language ?? 'de',
            ...(isSettingsVisible && {
                applyDpfCommaRules: !!settings.applyDpfCommaRules,
                styleWordFrequency: !!settings.styleWordFrequency,
                styleFiller: !!settings.styleFiller,
                styleOverlong: !!settings.styleOverlong,
                styleOverlongWordCount: settings.styleOverlongWordCount ?? 0,
                styleGeneral: !!(settings.styleWordFrequency || settings.styleFiller || settings.styleOverlong),
            }),
        },
    });

    const countErrorMessage = errors.styleOverlongWordCount?.message;

    const [styleGeneral, styleWordFrequency, styleFiller, styleOverlong] = watch([
        'styleGeneral',
        'styleWordFrequency',
        'styleFiller',
        'styleOverlong',
    ]);

    useEffect(() => {
        if (!isSettingsVisible) return;

        if (
            (!styleWordFrequency && !styleOverlong && !styleFiller) ||
            !styleGeneral
        ) {
            setValue('styleWordFrequency', styleGeneral);
            setValue('styleFiller', styleGeneral);
            setValue('styleOverlong', styleGeneral);
            !styleGeneral && setValue('styleOverlongWordCount', settings.styleOverlongWordCount ?? 0, { shouldValidate: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValue, styleGeneral, isSettingsVisible]);

    useEffect(() => {
        if (!isSettingsVisible) return;

        if (styleFiller || styleOverlong || styleWordFrequency) {
            setValue('styleGeneral', true);
        } else if (!styleFiller && !styleOverlong && !styleWordFrequency) {
            setValue('styleGeneral', false);
            !styleOverlong && setValue('styleOverlongWordCount', settings.styleOverlongWordCount ?? 0, { shouldValidate: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSettingsVisible, setValue, styleFiller, styleOverlong, styleWordFrequency]);

    const handleSubmit = (data: Partial<Omit<SettingsFormProps, 'styleGeneral'>>) => {
        const { language, styleOverlongWordCount, ...settingsData } = data;

        const isValid = !isSettingsVisible
            || isSettingsForbidden
            || !settingsData.styleOverlong
            || !validateCountValue(styleOverlongWordCount);

        if (!isValid) return;

        return mutate({
            language,
            ...(!isSettingsForbidden && {
                ...settingsData,
                ...(settingsData.styleOverlong && {
                    styleOverlongWordCount,
                }),
            }),
        }, {
            onSuccess: () => {
                mutableStateRef.current.isDirty = false;
                setBanner({
                    appearance: BANNER_APPEARANCE.Success,
                    message: 'Die Änderungen wurden gespeichert.',
                    visibilityTime: BANNER_VISIBILITY_TIME,
                });
                handleSegmentsInvalidate();
            },
            onError: () => {
                setBanner({
                    appearance: BANNER_APPEARANCE.Error,
                    message: 'Hoppla! Etwas ist schiefgelaufen.',
                    visibilityTime: BANNER_VISIBILITY_TIME,
                });
            },
        });
    };

    const handleSubmitOnChange: React.FormEventHandler<HTMLFormElement> = () => {
        mutableStateRef.current.timeoutId && clearTimeout(mutableStateRef.current.timeoutId);
        mutableStateRef.current.isDirty = true;

        mutableStateRef.current.timeoutId = setTimeout(() => {
            const { styleGeneral, ...settingsData } = getValues();

            handleSubmit(settingsData);
        }, AUTOSAVE_TIMEOUT);
    };

    const handleSubmitOnLeave = () => {
        const { styleGeneral, ...settingsData } = getValues();

        mutableStateRef.current.timeoutId && clearTimeout(mutableStateRef.current.timeoutId);

        if (!mutableStateRef.current.isDirty) return;

        handleSubmit(settingsData);
    };

    useEffect(() => {
        return () => {
            void handleSubmitOnLeave();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <SC.Wrapper>
        <SC.FormWrapper>
            <SC.Form noValidate onChange={handleSubmitOnChange}>
                <SC.Title>Einstellungen</SC.Title>
                <SC.Fieldset>
                    <SC.Legend>Ländereinstellungen</SC.Legend>
                    <SC.Row>
                        <label htmlFor="language-de">Deutschland</label>
                        <Toggle value="de" type="radio" id="language-de" {...register('language')} />
                    </SC.Row>
                    <SC.Row>
                        <label htmlFor="language-at">Österreich</label>
                        <Toggle value="at" type="radio" id="language-at" {...register('language')} />
                    </SC.Row>
                    <SC.Row>
                        <label htmlFor="language-ch">Schweiz</label>
                        <Toggle value="ch" type="radio" id="language-ch" {...register('language')} />
                    </SC.Row>
                </SC.Fieldset>
                {isSettingsVisible && <SC.Fieldset isForbidden={isSettingsForbidden}>
                    <SC.Legend>Prüfeinstellungen für den Mentor Klassik</SC.Legend>
                    {isSettingsForbidden && <ForbiddenPopup />}
                    <SC.Row>
                        <label htmlFor="style-punctuation">Kommasetzung</label>
                        <Toggle id="style-punctuation" disabled={isSettingsForbidden} {...register('applyDpfCommaRules')} />
                    </SC.Row>
                    <SC.Row>
                        <label htmlFor="style-general">Stil und Vokabular</label>
                        <Toggle id="style-general" disabled={isSettingsForbidden} {...register('styleGeneral')} />
                    </SC.Row>
                    <SC.Row>
                        <label htmlFor="style-word-frequency">Wortdopplungen</label>
                        <Toggle id="style-word-frequency" disabled={isSettingsForbidden} {...register('styleWordFrequency')} />
                    </SC.Row>
                    <SC.Row>
                        <label htmlFor="style-filler">Füllwörter</label>
                        <Toggle id="style-filler" disabled={isSettingsForbidden} {...register('styleFiller')} />
                    </SC.Row>
                    <SC.Row borderless>
                        <label htmlFor="style-overlong">Langer Satz</label>
                        <Toggle id="style-overlong" disabled={isSettingsForbidden} {...register('styleOverlong')} />
                    </SC.Row>
                    {styleOverlong && <SC.Row borderless shifted>
                        <label htmlFor="style-overlong-count">Wortanzahl pro Satz:</label>
                        <SC.CountInput
                            disabled={isSettingsForbidden}
                            id="style-overlong-count"
                            type="number"
                            min={MINIMUM_WORD_COUNT}
                            {...register('styleOverlongWordCount', {
                                valueAsNumber: true,
                                validate: validateCountValue,
                            })}
                        />
                    </SC.Row>}
                    {styleOverlong && countErrorMessage && <SC.Row borderless shifted error>
                        <p>{countErrorMessage}</p>
                    </SC.Row>}
                </SC.Fieldset>}
            </SC.Form>
            <SC.VersionWrapper>
                {userData?.settings.liloVersion && <small>{userData.settings.liloVersion}</small>}
                {userData?.settings.llmVersion && <small>{userData?.settings.llmVersion}</small>}
            </SC.VersionWrapper>
        </SC.FormWrapper>
    </SC.Wrapper>;
};

export default SettingsForm;
