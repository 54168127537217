import React from 'react';

import { useSelector } from 'react-redux';

import * as SC from './CorrectionPadBanner.styled';
import useCorrectionStore from '../../Hooks/useCorrectionStore';
import useUserFeatures from '../../Hooks/useUserFeatures';
import { selectTextLength } from '../../Store/PadState';
import { selectFeatureFlags } from '../../Store/SettingsState';
import { DocumentIdentifier } from '../../Util/DocumentIdentifier';
import { formatToThousandSeparator, getDestinationUrl } from '../../Util/formatingHelper';
import { PlatformTypes } from '../../Util/PlatformUtil';
import { USER_ROLE } from '../../Util/userfeaturesHelper';
import PadBanner from '../PadBanner/PadBanner';

const CorrectionPadBanner: React.FC = () => {
    const featureFlags = useSelector(selectFeatureFlags);
    const classicTextLength = useSelector(selectTextLength);

    const { data: userData, hasAnyRole, isGuestRole, isPremiumRole } = useUserFeatures();
    const isNativeEditorModeActive = featureFlags['native_editor'];

    const editorTextSize = useCorrectionStore(store => store.bufferText.length);

    const characterLimit = userData?.characterLimit ?? 0;
    const textLength = isNativeEditorModeActive ? editorTextSize : classicTextLength;

    const isCharacterLimitReached = textLength > characterLimit;

    if (!isCharacterLimitReached) return null;

    const target = [PlatformTypes.lite].includes(DocumentIdentifier.getPlatformIdentifier()) ? '_blank' : '_self'; //TODO
    const message = `Sie haben maximal ${formatToThousandSeparator(characterLimit, ' ')} Zeichen je Prüfung zur Verfügung.`;
    let info;

    if (isGuestRole) {
        const linkUrl = getDestinationUrl('service/abos', { itm_content: 'zg-guest' });

        info = `Für <b>längere Texte</b> empfehlen wir <a href="${linkUrl}" target="${target}">Mentor Premium Plus</a>.`;
    } else if (hasAnyRole(
        USER_ROLE.LicensemanagerBasic,
        USER_ROLE.LicensemanagerPremium,
        USER_ROLE.LicensemanagerCharityBasic,
        USER_ROLE.LicensemanagerCharityPremium,
    )) {
        const isPremium = hasAnyRole(USER_ROLE.LicensemanagerPremium, USER_ROLE.LicensemanagerCharityPremium);

        const linkUrl = getDestinationUrl(
            'user/authenticate',
            `/user/${isPremium ? 'license-dashboard' : 'b2bsubscription/overview'}`,
            { itm_content: 'zg-b2b-basis' },
        );

        info = `Für <b>längere Texte</b> ist eine <a href="${linkUrl}" target="${target}">Duden Business Lizenz</a> nötig.`;
    } else if (hasAnyRole(
        USER_ROLE.PremiumPlus,
        USER_ROLE.LicenseePremium,
        USER_ROLE.LicenseeCharityPremium,
        USER_ROLE.SchoolLicensemanagerBasic,
        USER_ROLE.StudentLicenseePremium,
        USER_ROLE.Student,
    )) {
        info = 'Bitte kürzen Sie Ihren Text entsprechend.';
    } else {
        const linkUrl = getDestinationUrl(
            'user/authenticate',
            '/user/premium_product/overview',
            { itm_content: isPremiumRole ? 'zg-premium' : 'zg-basis' },
        );

        info = `Für <b>längere Texte</b> empfehlen wir <a href="${linkUrl}" target="${target}">Mentor Premium Plus</a>.`;
    }

    return <SC.Wrapper>
        <PadBanner
            title="Zeichenlimit erreicht"
            testId="pad-banner-classic"
            message={message}
            info={info}
        />
    </SC.Wrapper>;
};

export default CorrectionPadBanner;
