import useCorrectionStore, { Advice, ADVICE_TYPE, ERROR_CODE, GrammarcheckBlock, NormalizedAdvice, Segment } from '../Hooks/useCorrectionStore';

export const checkCorruptedChildNode = (node: HTMLDivElement | null) => {
    if (!node) return false;

    for (const childNode of node.childNodes) {
        if (!(childNode as HTMLSpanElement)?.classList?.contains('text-segment')) return true;
    }

    return false;
};

export const normalizeAdvices = (segment: Segment, advices?: {
    spellAdvices?: Advice[], styleAdvices?: Advice[]
}) => {
    const { id: segmentId, beginOffset, initSpellAdvices, initStyleAdvices, rejectedAdvices } = segment;

    const { spellAdvices, styleAdvices } = advices ?? {
        spellAdvices: initSpellAdvices,
        styleAdvices: initStyleAdvices,
    };

    const normalizedSpellAdvices: NormalizedAdvice[] = spellAdvices?.reduce<NormalizedAdvice[]>((acc, advice) => {
        const lastAdvice = acc.at(-1);

        if (rejectedAdvices?.some(rejectedAdvice => rejectedAdvice.text === advice.originalError && rejectedAdvice.errorCode === advice.errorCode)) {
            return acc;
        }

        if (lastAdvice) {
            if (lastAdvice.offset === advice.offset) {
                if (lastAdvice.type === 'gram') {
                    acc.pop();
                } else {
                    return acc;
                }
            } else if (lastAdvice.offset + lastAdvice.length > advice.offset) {
                return acc;
            }
        }

        return [...acc, {
            ...advice,
            id: `advice-${beginOffset}-${advice.offset}`,
            adviceType: ADVICE_TYPE.Spelling,
            segmentId,
        }];
    }, []) ?? [];

    const normalizedStyleAdvices: NormalizedAdvice[] = styleAdvices?.reduce<Advice[]>((acc, advice) => {
        if (rejectedAdvices?.some(rejectedAdvice => rejectedAdvice.text === advice.originalError && rejectedAdvice.errorCode === advice.errorCode)) {
            return acc;
        }

        if (advice.errorCode === ERROR_CODE.DuplicatedWord && advice.occurrences.length > 1) {
            return [...acc, ...advice.occurrences.map((occurrence) => ({
                ...advice,
                offset: occurrence.offset,
                originalError: occurrence.text,
                length: occurrence.text?.length ?? 0,
                synonyms: occurrence.synonyms ?? [],
            }))];
        }

        return [...acc, advice];
    }, [])
        .sort((a: Advice, b: Advice) => a.offset - b.offset)
        .reduce<NormalizedAdvice[]>((acc, advice) => {
            const lastAdvice = acc.at(-1);

            if (lastAdvice) {
                if (lastAdvice.offset + lastAdvice.length >= advice.offset) {
                    return acc;
                }
            }

            const isSpellOverlaping = normalizedSpellAdvices.some((spellAdvice) => {
                const spellStart = spellAdvice.offset;
                const spellEnd = spellStart + spellAdvice.length;
                const adviceStart = advice.offset;
                const adviceEnd = adviceStart + advice.length;

                return spellStart <= adviceEnd && spellEnd >= adviceStart;
            });

            if (isSpellOverlaping) {
                return acc;
            }

            return [...acc, {
                ...advice,
                id: `advice-${beginOffset}-${advice.offset}`,
                adviceType: ADVICE_TYPE.Style,
                segmentId,
            }];
        }, []) ?? [];

    return {
        normalizedSpellAdvices,
        normalizedStyleAdvices,
    };
};

export const normalizeBlocks = (text: string, advices?: NormalizedAdvice[]) => {
    const blocks: GrammarcheckBlock[] = [];
    let sinitizedText = text;

    const sortedAdvices = advices?.sort((a, b) => b.offset - a.offset) ?? [];

    if (sortedAdvices?.length) {
        sortedAdvices.forEach((advice) => {
            const commonCopy = sinitizedText?.substring(advice.offset + advice.length);
            const markCopy = advice.originalError;
            const acceptedValue = advice.acceptedValue;

            if (!markCopy) return;

            commonCopy && blocks.unshift({
                type: 'common',
                copy: commonCopy,
            });

            blocks.unshift(acceptedValue ? {
                type: 'common',
                copy: acceptedValue,
            } : {
                id: advice.id,
                type: advice.adviceType,
                copy: markCopy,
            });

            sinitizedText = sinitizedText?.substring(0, advice.offset);
        });

        sinitizedText && blocks.unshift({
            type: 'common',
            copy: sinitizedText,
        });
    } else {
        blocks.push({
            type: 'common',
            copy: sinitizedText,
        });
    }

    return blocks;
};

export const setCaretPosition = (node: HTMLDivElement | ChildNode | null, caretPosition: number): void => {
    if (!node) return;

    let position = caretPosition;

    if (node.nodeType === Node.TEXT_NODE) {
        if (position <= node.textContent!.length) {
            const range = document.createRange();
            range.setStart(node, position);
            range.setEnd(node, position);

            const selection = window.getSelection();
            selection?.removeAllRanges();
            selection?.addRange(range);
        }
    } else {
        for (const childNode of node.childNodes) {
            const childNodeLength = childNode.textContent?.length;

            if (typeof childNodeLength === 'undefined') return;

            if (childNodeLength >= position) {
                return setCaretPosition(childNode, position);
            }

            position = position - childNodeLength;
        }
    }
};

const getParentSegment = (node: Element, counter?: number): Element | null => {
    if (counter) {
        const parentNode = node.parentNode as Element;

        if (parentNode) {
            if (parentNode.className === 'text-segment') {
                return parentNode;
            }

            return getParentSegment(parentNode, counter - 1);
        }
    }

    return null;
};

export const getCaretPosition = (node: HTMLDivElement | null, isSilent?: boolean) => {
    if (!node) return;

    const selection = window.getSelection();

    if (selection?.rangeCount && selection.rangeCount > 0) {
        const range = selection?.getRangeAt(0);
        const clonedRange = range?.cloneRange();

        if (!range || !clonedRange) return;

        const currentNode = range.endContainer as Element;
        const segmentNode = getParentSegment(currentNode, 4);
        const segmentId = (segmentNode as Element)?.id;

        clonedRange.selectNodeContents(segmentId ? segmentNode as Element : node);

        clonedRange.setEnd(currentNode, range.endOffset);

        const index = clonedRange.toString().replaceAll('/n', ' ').length;

        useCorrectionStore.getState().handleUpdateCursorPosition({
            index,
            segment: segmentNode,
            segmentId,
            isTyping: !isSilent,
        });

        return index;
    }

    return;
};

export const unwrapTextNode = (node: HTMLElement | null) => {
    if (node) {
        const textContent = node.textContent;

        if (textContent) {
            const textNode = document.createTextNode(textContent);

            node.parentNode?.replaceChild(textNode, node);

        }
    }
};