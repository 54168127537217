import getBrowserFingerprint from 'get-browser-fingerprint';

import { DocumentIdentifier } from './DocumentIdentifier';
import { PlatformTypes } from './PlatformUtil';
import useAuthStore, { getAccessToken, getRefreshToken } from '../Hooks/useAuthStore';

export enum RequestMethod {
    post = 'POST',
    get = 'GET',
    delete = 'DELETE',
}

export class RequestApi {
    public static readonly URL: string = process.env.REACT_APP_API_URI!;
    public static jwt: string | undefined;

    public static fetch(method: RequestMethod, path: string, header?: Headers, body?: BodyInit): Promise<Response> {
        /**
         * enable cookies for all versions to enable profiling in lite version
         */
        let credentials = 'include';

        if (process.env.NODE_ENV !== 'production') {
            credentials = 'include';
        }

        return fetch(`${RequestApi.URL}/${path}?_format=json`, {
            method,
            headers: RequestApi.getHeader(header),
            credentials: credentials as RequestCredentials,
            body,
        });
    }

    private static getHeader(customHeader?: Headers): HeadersInit {
        const header = customHeader || new Headers({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache',
        });

        if (RequestApi.jwt !== undefined) {
            header.append('Application-Authorization', `Bearer ${RequestApi.jwt}`);
        }

        if (process.env.REACT_APP_API_TEST_SERVER_TOKEN) {
            header.append('Authorization', process.env.REACT_APP_API_TEST_SERVER_TOKEN);
        }

        return header;
    }
}

export const apiFetch = async (
    path: string,
    fetchInit?: Partial<RequestInit> & {
        method?: RequestMethod,
        baseUrl?: string,
        headers?: Record<string, string>,
        body?: BodyInit,
        debugResponse?: boolean;
        skipAuth?: boolean;
        skipRefresh?: boolean;
        checkFingerprintId?: boolean;
    }): Promise<Response> => {
    const {
        method = RequestMethod.get,
        headers = {},
        body,
        baseUrl = process.env.REACT_APP_API_URI,
        debugResponse = false,
        skipAuth = false,
        skipRefresh = false,
        checkFingerprintId = false,
        ...restInit
    } = fetchInit ?? {};
    const url = `${baseUrl}/${path}${debugResponse ? '?_format=json' : ''}`;
    const isFingerprintMode = checkFingerprintId && DocumentIdentifier.getPlatformIdentifier() === PlatformTypes.lite; // TODO: move to store

    const reqHeaders = new Headers({
        'Content-Type': 'application/json',
        ...(!skipAuth && { 'Cache-Control': 'no-cache' }),
        ...headers,
    });

    const jwt = skipAuth ? undefined : getAccessToken();

    if (jwt !== undefined) {
        reqHeaders.append('Application-Authorization', `Bearer ${jwt}`);
    }

    if (isFingerprintMode) {
        const fingerprintId = await getFingerprintId();

        fingerprintId && reqHeaders.append('Window-Resolution-Id', fingerprintId);
    }

    const response = await fetch(url, {
        ...restInit,
        method,
        headers: reqHeaders,
        credentials: 'include',
        body,
    });

    if (response.status === 401 && !isFingerprintMode) { // TODO check code
        if (!skipRefresh) {
            const tokenResponse = await apiGetToken();

            if (tokenResponse) {
                return apiFetch(path, { ...fetchInit, skipRefresh: true });
            }
        }
    }

    return response;
};

export const apiGetToken = async (authData?: { email: string, password: string }) => {
    const isRefreshMode = !authData;

    const formData = new URLSearchParams();

    if (isRefreshMode) {
        const refreshToken = getRefreshToken();

        if (!refreshToken) return;

        formData.append('refresh_token', refreshToken);
        formData.append('grant_type', 'refresh_token');
    } else {
        formData.append('username', authData.email);
        formData.append('password', authData.password);
        formData.append('grant_type', 'password');
    }

    formData.append('scope', 'offline_access,openid');
    formData.append('client_id', process.env.REACT_APP_CLIENT_ID ?? '');
    formData.append('client_secret', process.env.REACT_APP_CLIENT_SECRET ?? '');

    try {
        const res = await apiFetch('oxauth/restv1/token', {
            method: RequestMethod.post,
            baseUrl: process.env.REACT_APP_CLIENT_HOST,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData,
            skipRefresh: true,
            skipAuth: true,
        });

        if (res.status !== 200) {
            return;
        }

        const {
            access_token: accessToken,
            refresh_token: refreshToken,
            expires_in: expiresIn,
        }: {
            access_token: string;
            token_type: string;
            expires_in: number;
            refresh_token: string;
            scope: string;
        } = await res.json();

        const { init } = useAuthStore.getState().actions;

        const initData = {
            accessToken,
            refreshToken,
            expiresIn,
        };

        init(initData);

        return initData;
    } catch (_err) {
        return;
    }
};

export const getFingerprintId = async () => {
    try {
        const shouldCache = false; //hardcoded because we cant do cache busts on localstorage and need to wait for it to expire

        if (shouldCache) {
            const currentId = localStorage.getItem('fingerprintId');
            if (currentId) {
                return currentId;
            }
        }

        const newId = (await getBrowserFingerprint({hardwareOnly: false})).toString();

        localStorage.setItem('fingerprintId', newId);

        return newId;
    } catch {
        return null;
    }
};