import React from 'react';

import * as SC from './AiPadBanner.styled';
import useAiStore from '../../../Hooks/useAiStore';
import useUserFeatures from '../../../Hooks/useUserFeatures';
import { DocumentIdentifier } from '../../../Util/DocumentIdentifier';
import { getDestinationUrl } from '../../../Util/formatingHelper';
import { PlatformTypes } from '../../../Util/PlatformUtil';
import { USER_ROLE } from '../../../Util/userfeaturesHelper';
import PadBanner from '../../PadBanner/PadBanner';

const AiPadBanner: React.FC = () => {
    const { isGuestRole, isPremiumRole, hasRole, hasAnyRole } = useUserFeatures();
    const requestsRemaining = useAiStore((store) => store.requestsRemaining);
    const requestsLimit = useAiStore((store) => store.summary.requestsLimit) ?? 'XX';

    if (requestsRemaining || typeof requestsRemaining === 'undefined') {
        return null;
    }

    if (hasAnyRole(
        USER_ROLE.PremiumPlus,
        USER_ROLE.LicenseeBasic,
        USER_ROLE.LicenseePremium,
        USER_ROLE.LicenseeCharityPremium,
    )) {
        return null;
    }

    const target = [PlatformTypes.lite].includes(DocumentIdentifier.getPlatformIdentifier()) ? '_blank' : '_self'; //TODO
    let title = 'Freikontingent aufgebraucht';
    let message = 'Das Freikontingent an KI-Abfragen ist momentan aufgebraucht.';
    let info = '';

    if (isGuestRole) {
        const linkUrl = getDestinationUrl('service/abos', { itm_content: 'zg-guest' });

        info = `Für <b>unbegrenzte KI-Abfragen</b> empfehlen wir <a href="${linkUrl}" target="${target}">Mentor Premium Plus</a>.`;
    } else if (hasAnyRole(
        USER_ROLE.LicensemanagerBasic,
        USER_ROLE.LicensemanagerPremium,
        USER_ROLE.LicensemanagerCharityBasic,
        USER_ROLE.LicensemanagerCharityPremium,
    )) {
        const isPremium = hasAnyRole(USER_ROLE.LicensemanagerPremium, USER_ROLE.LicensemanagerCharityPremium);
        const linkUrl = getDestinationUrl(
            'user/authenticate',
            `/user/${isPremium ? 'license-dashboard' : 'b2bsubscription/overview'}`,
            { itm_content: 'zg-b2b-basis' },
        );

        info = `Für <b>unbegrenzte KI-Abfragen</b> ist eine <a href="${linkUrl}" target="${target}">Duden Business Lizenz</a> nötig.`;
    } else if (hasAnyRole(
        USER_ROLE.StudentLicenseeBasic,
        USER_ROLE.StudentLicenseePremium,
        USER_ROLE.SchoolLicensemanagerBasic,
    )) {
        //use default title and message
    } else if (hasRole(USER_ROLE.Student)) {
        title = 'Monatslimit erreicht';
        message = `Ab nächstem Monat stehen Ihnen wieder ${requestsLimit} KI-Abfragen zur Verfügung.`;
    } else {
        if (isPremiumRole) {
            title = 'Monatslimit erreicht';
            message = `Ab nächstem Monat stehen Ihnen wieder ${requestsLimit} KI-Abfragen zur Verfügung.`;
        }

        const linkUrl = getDestinationUrl(
            'user/authenticate',
            '/user/premium_product/overview',
            { itm_content: isPremiumRole ? 'zg-premium' : 'zg-basis' },
        );

        info = `Für <b>unbegrenzte KI-Abfragen</b> empfehlen wir <a href="${linkUrl}" target="${target}">Mentor Premium Plus</a>.`;
    }

    return <SC.Wrapper>
        <PadBanner
            title={title}
            message={message}
            info={info}
        />
    </SC.Wrapper>;
};

export default AiPadBanner;
