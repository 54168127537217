import styled, { css } from 'styled-components';

import { Colors, Fonts, MediaBreakpoints } from '../../Styles';

export const Wrapper = styled.div`
    font-family: ${Fonts.theinhardt};
    background: ${Colors.white};
    overflow-y: auto;
`;

export const Title = styled.h1`
    margin: 0;
`;

export const FormWrapper = styled.div`
    position: relative;
    max-width: 761px;
    padding: 25px;

    @media screen and ${MediaBreakpoints.medium} {
        padding-top: 45px;
    }

    @media screen and ${MediaBreakpoints.large} {
        padding-left: 100px;
    }
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;

    padding-bottom: 30px;

    @media screen and ${MediaBreakpoints.medium} {
        padding-bottom: 50px;

        & > button {
            align-self: flex-start;
        }
    }
`;

export const Row = styled.div<{ borderless?: boolean, shifted?: boolean, error?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    padding: 10px 0 10px ${p => p.shifted ? '25px' : '0'};
    border-bottom: ${p => p.borderless ? 'none' : `1px solid ${Colors.iron}`};
    
    & > label {
        cursor: pointer;
    }
    
    ${p => p.error && css`
        padding-top: 0;

        p {
            margin: -5px 0 0;
            font-size: 16px;
            color: ${Colors.crimson};
        }
    `};
`;

export const Fieldset = styled.fieldset<{ isForbidden?: boolean }>`
    padding: 17px 0 24px;
    margin: 50px 0 0;
    position: relative;
    border: none;

    ${({ isForbidden }) => isForbidden && css`
        padding: 50px 0;

        @media screen and ${MediaBreakpoints.medium} {
            padding: 17px 0 24px;
        }

        & > ${Row} {
            display: none;

            @media screen and ${MediaBreakpoints.medium} {
                display: flex;
            }
        }
    `}
`;

export const Legend = styled.legend`
    font-family: ${Fonts.theinhardt};
    font-weight: bold;
    font-size: 26px;
    line-height: 31px;
    color: ${Colors.mineShaft};
    margin: 0;
`;

export const CountInput = styled.input`
    width: 40px;
    height: 24px;
    padding: 5px 8px 5px 9px;
    font-size: 13px;
    text-align: center;
    border: 1px solid ${Colors.iron};
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export const VersionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    color: ${Colors.osloGray};

    & > * {
        font-size: inherit;
    }
`;