import React from 'react';

import { ERROR_CODE } from '../Hooks/useAiStore';

export const getSanitizedInnerText = (node: HTMLDivElement | null) => {
    const nodeClone = node?.cloneNode(true) as HTMLDivElement | null;

    nodeClone
        ?.querySelectorAll(`[data-llm-type="${ERROR_CODE.Trace}"]`)
        ?.forEach(element => element.remove());

    return nodeClone?.innerText ?? '';
};

export const handleNormalizedEnterPress: React.KeyboardEventHandler<HTMLElement> = (event) => {
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);

    if (!selection || !range) return;

    const clonedRange = range?.cloneRange();
    const editorNode = event.currentTarget;

    clonedRange.setStart(range.endContainer, range.endOffset);
    clonedRange.setEndAfter(editorNode.lastChild as Node);

    const isAtEnd = clonedRange.toString().length === 0;

    if (isAtEnd) {
        return;
    }

    event.preventDefault();

    range.deleteContents();

    const lineNode = document.createTextNode('\n');

    range.insertNode(lineNode);
    range.setStartAfter(lineNode);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
};