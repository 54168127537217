import React from 'react';

import * as SC from './Login.styled';
import { AiBannerMessage } from '../../Components/AiMessage/AiBannerMessage';
import { Header } from '../../Components/Header';
import { LoginForm } from '../../Components/LoginForm';

type LoginProps = {};

const Login: React.FC<LoginProps> = () => {
    return <>
        <Header empty />
        <SC.Wrapper>
            <AiBannerMessage />
            <SC.Headline>Einloggen oder registrieren</SC.Headline>
            <SC.Subheadline>Herzlich willkommen!</SC.Subheadline>
            <LoginForm />
        </SC.Wrapper>
    </>;
};

export default Login;
