/**
 * Formats a number with a thousand separator.
 *
 * @param {number} int - The number to format.
 * @param {string} [separator='.'] - The separator to use. Defaults to '.'.
 * @returns {string} The formatted number as a string.
 */
export const formatToThousandSeparator = (int: number, separator?: string) =>
    int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator ?? '.');

/**
 * Splits a given text into two parts: the main text and the trailing whitespace.
 *
 * @param {string} text - The input text to be split.
 * @returns {Array<string | undefined>} An array containing two elements:
 *   - The main text without trailing whitespace.
 *   - The trailing whitespace.
 */
export const splitTextAndWhiteSpace = (text?: string) => {
    if (text) {
        const match = text.match(/^(.*?)(\s*)$/s);

        return match ? [match[1], match[2]] : [text, ''];
    }

    return [text, ''];
};

type ItmDestinationParams = Record<string, string>;
/**
 * Constructs a destination URL with the given pathname, destination, and parameters.
 *
 * @param {string} pathname - The base pathname for the URL.
 * @param {string | ItmDestinationParams} [destination] - The destination path or parameters.
 * @param {ItmDestinationParams} [params] - Additional parameters for the URL.
 * @returns {string | undefined} The constructed URL as a string, or undefined if an error occurs.
 */
export const getDestinationUrl = (...args: Array<string | ItmDestinationParams>) => {
    const [pathname, destination, params] = args;

    if (typeof pathname !== 'string') return;

    const isDestinationMode = typeof destination === 'string';

    const itmSearchParams = new URLSearchParams({
        itm_source: 'duden_mentor',
        itm_campaign: 'hp_zeichenlimit',
        itm_medium: 'premium_int',
        itm_content: 'zg-basis',
        ...(isDestinationMode ? params as ItmDestinationParams | undefined : destination as ItmDestinationParams | undefined),
    }).toString();

    try {
        const baseUrl = new URL(pathname as string, process.env.REACT_APP_DUDENDE_URL);

        if (isDestinationMode) {
            baseUrl.searchParams.append('destination', `${destination}?${itmSearchParams}`);
        } else {
            baseUrl.search = itmSearchParams;
        }

        return baseUrl.toString();
    } catch (error) {
        return;
    }
};

/**
 * Picks specified properties from the source object.
 *
 * @param {Object} [source] - The source object to pick properties from.
 * @param {Array<string>} [properties] - The properties to pick from the source object.
 * @returns {Object} An object containing the picked properties.
 */
export const pickProps = <T extends Record<string, unknown>>(source?: T, properties?: (keyof T)[]) =>
    properties?.reduce((acc: Partial<T>, key) => {
        if (source?.hasOwnProperty(key)) {
            acc[key] = source[key];
        }
        return acc;
    }, {});

/**
 * Sanitizes a raw prompt response by parsing it as JSON and extracting the relevant data.
 *
 * @param {string} response - The raw response string to be sanitized.
 * @returns {unknown[] | undefined} - The sanitized response data as an array of specified item or undefined if parsing fails or the data is invalid.
 */
export const sanitizeRawPromptResponse = <T extends unknown>(response: string) => {
    try {
        const responseJSON: T[] | Record<string, T[]> = JSON.parse(response);

        if (!responseJSON) return;

        if (Array.isArray(responseJSON)) return responseJSON;

        if (typeof responseJSON !== 'object') return;

        const responseProperties = Object.keys(responseJSON);

        if (responseProperties.length === 1 && Array.isArray(responseJSON[responseProperties[0]])) {
            return responseJSON[responseProperties[0]];
        }

        return;
    } catch (_error) {
        return;
    }

};