import React from 'react';

import { create } from 'zustand';
import { createJSONStorage, persist, subscribeWithSelector } from 'zustand/middleware';

import { MESSAGE_APPEARANCE, type MessageAppearance } from '../Components/AiMessage';
import { normalizeBlocks } from '../Util/expressHelper';
import { apiFetch, RequestMethod } from '../Util/RequestApi';
import { type ObjectValues } from '../Util/typesHelper';

const ENDING_REGEX = /\s+$/gm;

export const GRAMMAR_CHECK_MODE = 'grammarCheck';
export const PARAPHRASING_MODE = {
    Neutral: 'neutral',
    Lighter: 'simpler',
    Formal: 'formal',
} as const;
export type ParaphrasingMode = ObjectValues<typeof PARAPHRASING_MODE>

export const ERROR_CODE = {
    Replace: 'replace',
    Insert: 'insert',
    Remove: 'remove',
    Trace: 'trace',
} as const;
export type ErrorCode = ObjectValues<typeof ERROR_CODE>

export const MESSAGE_CODE = {
    Common: 'common',
    LongText: 'longText',
    ZeroChanges: 'zeroChanges',
} as const;
export type MessageCode = ObjectValues<typeof MESSAGE_CODE>

export const INTERACTION_MODE = {
    Synonym: 'synonym',
    Rephrase: 'rephrase',
} as const;
export type InteractionMode = ObjectValues<typeof INTERACTION_MODE>

export type LLMError = {
    type: string;
    errorcode: ErrorCode;
    offset: number;
    length: number;
    proposals: string[];
}

export type CorrectionBlock = { type: ErrorCode | 'common'; copy: string };

export type Alignment = {
    diff: LLMError[];
    length: number;
    mate: string;
    offset: number;
    text: string;
}

export type Segment = {
    id: string;
    segmentizeId: string;
    beginOffset: number;
    endOffset: number;
    currentText?: string;
    text: string;
    originText?: string;
    blocks?: CorrectionBlock[];
    traceBlocks?: CorrectionBlock[];
    isSkipped?: boolean;
    checkMode?: ParaphrasingMode | typeof GRAMMAR_CHECK_MODE;
    error?: boolean;
    corrections?: LLMError[];
    code?: `llm_${number}`;
    issue?: {
        code?: Segment['code'];
        title?: string;
        message?: string;
    },
};

let selectTimeoutId: NodeJS.Timeout | undefined;
let correctionAbortController: AbortController | null;

export type AiStore = {
    text: string;
    segments: Segment[];
    bufferText: string;
    isSingleScreenMode: boolean;
    isSingleScreenMobileMode: boolean;
    isSingleScreenFullMode: boolean;
    isSingleScreenResultMode: boolean;
    isResultViewInteractive: boolean;
    paraphrasingMode: ParaphrasingMode | null,
    setParaphrasingMode: (value: ParaphrasingMode) => void,
    isHighlightModeActive: boolean;
    requestsRemaining?: number;
    getSegment: (segmentId: string) => Segment | undefined;
    setSegment: (segmentId: string, segmentData: Partial<Segment>) => void;
    editorNode: HTMLDivElement | null;
    resultEditorNode: HTMLDivElement | null;
    cleanEditorNode: () => void;
    setEditorNode: (node: HTMLDivElement | null) => void;
    setResultEditorNode: (node: HTMLDivElement | null) => void;
    handleSegmentize: () => Promise<boolean>;
    isCorrectionFinished: boolean;
    handleCorrection: () => Promise<void>;
    handleSegmentCorrection: (id: string) => Promise<boolean>;
    handleAbortCorrection: () => void;
    segmentsLoading: Record<string, boolean>;
    isSegmentLoading: (id: string) => boolean;
    isSegmentizeLoading: boolean;
    isSegmentizeSilentLoading: boolean;
    selectedSegmentId: string | null;
    handleSelectSegment: (segmentId: AiStore['selectedSegmentId'], opts?: { isForced?: boolean }) => void;
    activeSegmentId: string | null;
    interaction: {
        id?: string;
        segmentId?: string;
        query?: string;
        text?: string;
        offset?: number;
        mode?: InteractionMode;
    },
    setBufferText: (text: string) => void;
    summary: {
        requestsLimit?: number,
        charactersLimit?: number,
        showCounter?: boolean,
        segmentizeId: string | null,
    },
    handleInitSummary: () => void;
    error: {
        title?: string;
        content?: React.ReactNode;
        code?: MessageCode;
        appearance?: MessageAppearance;
        autoClose?: number;
    } | null;
}

const useAiStore = create<AiStore>()(subscribeWithSelector(persist((set, get) => ({
    text: '',
    segments: [],
    bufferText: '',
    isSingleScreenMode: false,
    isSingleScreenMobileMode: false,
    isSingleScreenFullMode: false,
    isSingleScreenResultMode: false,
    isResultViewInteractive: false,
    paraphrasingMode: null,
    setParaphrasingMode: (value) => set({
        paraphrasingMode: value,
        isCorrectionFinished: false,
        isSegmentizeLoading: false,
        segmentsLoading: {},
    }),
    isHighlightModeActive: false,
    getSegment: (segmentId) => get().segments.find((s) => s.id === segmentId),
    setSegment: (segmentId, segmentData) => set((state) => {
        return {
            segments: state.segments.map((segment) => {
                if (segment.id === segmentId) {
                    return { ...segment, ...segmentData };
                }

                return segment;
            }),
        };
    }),
    segmentsLoading: {},
    isSegmentLoading: (id) => !!get().segmentsLoading[id],
    isSegmentizeLoading: false,
    isSegmentizeSilentLoading: false,
    editorNode: null,
    resultEditorNode: null,
    cleanEditorNode: () => set((state) => {
        if (state.editorNode) state.editorNode.innerHTML = '';
        if (state.resultEditorNode) state.resultEditorNode.innerHTML = '';

        return {
            text: '',
            bufferText: '',
            segments: [],
        };
    }),
    setEditorNode: (node) => set({ editorNode: node }),
    setResultEditorNode: (node) => set({ resultEditorNode: node }),
    handleSegmentize: async () => {
        const currentText = get().editorNode?.innerText.trim();

        if (!currentText) return false;

        try {
            set((store) => ({
                isSegmentizeLoading: true,
                isSegmentizeSilentLoading: true,
                summary: {
                    ...store.summary,
                    segmentizeId: null,
                },
            }));

            setTimeout(() => {
                set({ isSegmentizeSilentLoading: false });
            }, 2000);

            const response = await apiFetch('apigateway/segmentize', {
                method: RequestMethod.post,
                baseUrl: process.env.REACT_APP_GATEKEEPER_URI,
                body: JSON.stringify({
                    text: currentText,
                    ...(get().paraphrasingMode && {
                        tonality: get().paraphrasingMode,
                    }),
                }),
                checkFingerprintId: true,
            });

            const responseData: {
                result?: Omit<Segment, 'id'>[],
                maxCharacters?: number,
                requestsRemaining?: number,
                requestsTotal?: number,
                error?: string,
                identifier?: string,
            } = await response.json();

            const {
                result: segments = [],
                requestsRemaining,
                requestsTotal,
                error,
                identifier: segmentizeId,
            } = responseData;

            if (!response.ok || error || !segments) {
                set({
                    isSegmentizeLoading: false,
                    error: {
                        code: MESSAGE_CODE.Common,
                    },
                });

                return false;
            }

            // clean up editor content
            const editorNode = get().editorNode;
            const resultEditorNode = get().resultEditorNode;

            if (editorNode) editorNode.innerHTML = '';
            if (resultEditorNode) resultEditorNode.innerHTML = '';

            const currentSegments: Segment[] = [...get().segments];

            const timestamp = new Date().getTime();

            const normalizedSegments = segments.map((segment) => {
                const id = `segment-${timestamp}-${segment.beginOffset}`;

                // check current results only for llm_diff req
                const existingSegment = !get().paraphrasingMode
                    ? currentSegments.find((currentSegment) => {
                        if (currentSegment.checkMode !== GRAMMAR_CHECK_MODE) return false;

                        const actualText = currentSegment.currentText ?? currentSegment.text;
                        return actualText.trim() === segment.text?.trim();
                    })
                    : undefined;

                if (existingSegment) {
                    return {
                        ...existingSegment,
                        originText: segment.text,
                        text: segment.text,
                        currentText: segment.text,
                        ...(segment.text !== existingSegment.text && {
                            blocks: normalizeBlocks(segment.text, existingSegment.corrections),
                            traceBlocks: normalizeBlocks(segment.text, existingSegment.corrections, { showTraces: true }),
                        }),
                        id,
                        segmentizeId,
                    } as Segment;
                }

                return {
                    ...segment,
                    originText: segment.text,
                    id,
                    segmentizeId,
                } as Segment;
            });

            set((state) => ({
                    isSegmentizeLoading: false,
                    summary: {
                        ...state.summary,
                        limit: requestsTotal,
                        segmentizeId: segmentizeId ?? null,
                    },
                    requestsRemaining: requestsRemaining ?? state.requestsRemaining ?? 0,
                    segments: normalizedSegments,
                }),
            );

            return true;
        } catch (err) {
            console.error('handleSegmentize ', err);
            set({
                isSegmentizeLoading: false,
                error: {
                    code: MESSAGE_CODE.Common,
                },
            });

            return false;
        }
    },
    isCorrectionFinished: false,
    handleCorrection: async () => {
        const { isSegmentizeLoading, isSegmentizeSilentLoading } = get();

        if (isSegmentizeLoading || isSegmentizeSilentLoading) return;

        set({
            error: {},
        });

        if (!await get().handleSegmentize()) {
            return;
        }

        const segmentsLoading: AiStore['segmentsLoading'] = {};
        const currentSegments = get().segments;
        const filteredSegments = currentSegments
            .filter((segment) => {
                const isNotCorrected = !segment.currentText || segment.currentText !== segment.text || !!segment.error;

                if (isNotCorrected) {
                    segmentsLoading[segment.id] = true;
                }

                return isNotCorrected;
            });

        set({
            segmentsLoading,
            ...(!filteredSegments.length && { isCorrectionFinished: true }),
            ...(get().isSingleScreenMode && { isSingleScreenResultMode: true, isSingleScreenFullMode: true }),
        });

        let isCorrectionFinished = true;

        for (const [, segment] of filteredSegments.entries()) {
            const segmentizeId = get().summary.segmentizeId;

            if (!segmentizeId || segmentizeId !== segment.segmentizeId) {
                isCorrectionFinished = false;
                set({
                    segmentsLoading: {},
                });
                break;
            }

            if (!segment.currentText || segment.currentText !== segment.text) {
                const wasCorrected = await get().handleSegmentCorrection(segment.id);

                if (!wasCorrected) {
                    isCorrectionFinished = false;
                    set({
                        segmentsLoading: {},
                    });
                    break;
                }
            }
        }

        if (isCorrectionFinished) {
            const currentSegments = get().segments;

            const isNotChanged = !!currentSegments.length && currentSegments.every(s => !s.issue && s.originText?.trim() === s.text.trim());

            set({
                isCorrectionFinished,
                ...(isNotChanged && {
                    error: {
                        code: MESSAGE_CODE.ZeroChanges,
                    },
                }),
            });
        }
    },
    handleSegmentCorrection: async (id) => {
        const activeSegment: Segment | undefined = get().segments.find((segment: Segment) => segment.id === id);

        if (!activeSegment) return false;

        const originText = activeSegment.currentText || activeSegment.text;

        const paraphrasingMode = get().paraphrasingMode;
        const url = `apigateway/${paraphrasingMode ? 'paraphrase' : 'llm_diff'}`;

        try {
            set({ segmentsLoading: { ...get().segmentsLoading, [activeSegment.id]: true } });

            correctionAbortController = new AbortController();
            const signal = correctionAbortController.signal;

            const response = await apiFetch(url, {
                method: RequestMethod.post,
                baseUrl: process.env.REACT_APP_GATEKEEPER_URI,
                body: JSON.stringify({
                    text: originText,
                    identifier: activeSegment.segmentizeId,
                    ...(!!paraphrasingMode && {
                        tonality: paraphrasingMode,
                    }),
                }),
                signal,
                checkFingerprintId: true,
            });

            correctionAbortController = null;

            const responseData: {
                checkResults?: {
                    text?: string,
                    alignment?: Alignment[],
                    errors: LLMError[],
                    code?: `llm_${number}`,
                },
                messages?: { message: string, shortMessage: string },
                error?: string,
            } = await response.json();

            const { text, alignment, errors, code } = responseData.checkResults ?? {};
            let resultText = text ?? alignment?.map(alignmentItem => alignmentItem.text).join('') ?? '';

            const { message, shortMessage } = responseData.messages ?? (resultText ? {} : {
                message: 'Leider ist etwas schiefgegangen. Bitte starten Sie die Textprüfung erneut.',
            });

            const hasIssue = !!(message || shortMessage);
            const hasError = !response.ok || !!responseData.error;

            // keep spacing from origin text in case of non paraphrasing mode
            if (text) {
                const originTextRightSpace = originText.search(ENDING_REGEX);
                const resultTextRightSpace = resultText.search(ENDING_REGEX);

                if (originTextRightSpace !== resultTextRightSpace && originTextRightSpace !== -1) {
                    resultText = (resultTextRightSpace === -1 ? resultText : resultText?.substring(0, resultTextRightSpace)) + originText.substring(originTextRightSpace);
                }
            }

            if (hasIssue && !resultText) {
                resultText = originText;
            }

            set((store) => {
                const normalizedSegments = !hasIssue && alignment?.length ?
                    store.segments.reduce((accumulator: Segment[], segment) => {
                        if (segment.id === id) {
                            const alignmentSegments: Segment[] = alignment.map((alignmentItem) => {
                                const alignmentText = alignmentItem.text;
                                const alignmentDiff = alignmentItem.diff;

                                const blocks = normalizeBlocks(alignmentText, alignmentDiff);
                                const traceBlocks = normalizeBlocks(alignmentText, alignmentDiff, { showTraces: true });

                                return {
                                    ...segment,
                                    id: `${segment.id}-alignment-${alignmentItem.offset}`,
                                    beginOffset: segment.beginOffset + alignmentItem.offset,
                                    text: alignmentItem.text,
                                    originText: alignmentItem.mate,
                                    currentText: alignmentItem.text,
                                    blocks,
                                    traceBlocks,
                                    isSkipped: false,
                                    corrections: alignmentItem.diff,
                                    code,
                                    checkMode: paraphrasingMode || GRAMMAR_CHECK_MODE,
                                };
                            });

                            return [...accumulator, ...alignmentSegments];
                        }

                        return [...accumulator, segment];
                    }, []) :
                    store.segments.map((segment) => {
                        if (segment.id === id) {

                            const blocks = normalizeBlocks(resultText, errors);
                            const traceBlocks = normalizeBlocks(resultText, errors, { showTraces: true });

                            return {
                                ...segment,
                                text: resultText,
                                originText,
                                currentText: resultText,
                                blocks,
                                traceBlocks,
                                isSkipped: false,
                                corrections: errors,
                                checkMode: paraphrasingMode || GRAMMAR_CHECK_MODE,
                                code,
                                ...(hasIssue && {
                                    issue: {
                                        code: code ?? 'llm_3',
                                        title: shortMessage,
                                        message,
                                    },
                                }),
                                ...(hasError && {
                                    error: responseData.error || 'Error',
                                    text: originText,
                                    blocks: [],
                                    traceBlocks: [],
                                }),
                            } as Segment;
                        }

                        return segment;
                    });

                return {
                    segmentsLoading: { ...store.segmentsLoading, [activeSegment.id]: false },
                    segments: normalizedSegments,
                };
            });

            return !hasError;
        } catch (err) {
            const isAbortError = (err as { name?: string }).name === 'AbortError';

            set({
                segmentsLoading: { ...get().segmentsLoading, [activeSegment.id]: false },
                error: isAbortError
                    ? {
                        content: 'Die Textoptimierung wurde abgebrochen.',
                        appearance: MESSAGE_APPEARANCE.Default,
                        autoClose: 3000,
                    }
                    : {
                        code: MESSAGE_CODE.Common,
                    },
            });
            return false;
        }

    },
    handleAbortCorrection: () => {
        if (correctionAbortController) {
            correctionAbortController.abort();
            correctionAbortController = null;
        }

        set((store) => ({
            summary: {
                ...store.summary,
                segmentizeId: null,
            },
        }));
    },
    selectedSegmentId: null,
    handleSelectSegment: (segmentId, opts) => {
        const { isForced } = opts ?? {};

        selectTimeoutId && clearTimeout(selectTimeoutId);

        if (segmentId || isForced) {
            return set({ selectedSegmentId: segmentId });
        }

        selectTimeoutId = setTimeout(() => set({ selectedSegmentId: segmentId }), 450);
    },
    activeSegmentId: null,
    interaction: {},
    setBufferText: (text) => set(() => {
        const normalizedText = text.trim();

        return {
            bufferText: normalizedText,
            ...(!normalizedText && { segments: [], text: '' }),
        };
    }),
    summary: {
        segmentizeId: null,
    },
    handleInitSummary: async () => {
        try {
            const response = await apiFetch('apigateway/quota', {
                method: RequestMethod.post,
                baseUrl: process.env.REACT_APP_GATEKEEPER_URI,
                checkFingerprintId: true,
            });

            const {
                requestsTotal,
                requestsRemaining,
                characterLimit,
                plans,
            }: {
                requestsTotal: number,
                requestsRemaining: number,
                characterLimit: number,
                plans: Record<string, string | boolean>
            } = await response.json();

            // TODO: workaround for showCounter value (should be implemented on BE)
            let showCounter = true;
            for (const key of Object.keys(plans)) {
                if (key.includes('_req_limit')) {
                    if (plans[key] === requestsTotal.toString()) {
                        const [keyPrefix] = key.split('_req_limit');
                        const showCounterValue = plans[`${keyPrefix}_show_counter`];

                        showCounter = typeof showCounterValue === 'boolean' ? showCounterValue : showCounter;
                        break;
                    }
                }
            }

            return set((store) => {
                return {
                    summary: {
                        ...store.summary,
                        requestsLimit: requestsTotal,
                        charactersLimit: characterLimit,
                        showCounter: showCounter,
                    },
                    requestsRemaining: store.requestsRemaining ?? requestsRemaining,
                };
            });
        } catch (err) {
            console.error('quotaRequest', err);
            return;
        }

    },
    error: null,
}), {
    name: 'AiState',
    storage: createJSONStorage(() => sessionStorage),
    partialize: (state) => ({
        text: state.text,
        bufferText: state.bufferText,
        segments: state.segments,
        paraphrasingMode: state.paraphrasingMode,
    }),
})));

export const selectIsSegmentsLoading = (store: AiStore) => store.isSegmentizeLoading || store.isSegmentizeSilentLoading || Object.values(store.segmentsLoading).some(segmentId => segmentId);

export default useAiStore;