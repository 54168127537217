import * as React from 'react';

const EmptyIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none" {...props}>
        <circle cx="24.5" cy="24.5" r="24" stroke="#333333" />
        <line x1="12.354" y1="19.3535" x2="18.718" y2="25.7174" stroke="#333333" />
        <line x1="18.7173" y1="19.3536" x2="12.3534" y2="25.7175" stroke="#333333" />
        <line x1="30.354" y1="19.3535" x2="36.718" y2="25.7174" stroke="#333333" />
        <line x1="36.7173" y1="19.3536" x2="30.3534" y2="25.7175" stroke="#333333" />
        <path d="M13 38.5352C13 38.5352 16 34.5352 24 34.5352C32 34.5352 35 38.5352 35 38.5352" stroke="#333333" strokeLinecap="round" />
    </svg>
);

export default EmptyIcon;