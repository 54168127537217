import React from 'react';

import * as SC from './ForbiddenPopup.styled';
import useUserFeatures from '../../../Hooks/useUserFeatures';
import { getDestinationUrl } from '../../../Util/formatingHelper';
import { USER_ROLE } from '../../../Util/userfeaturesHelper';
import { ArrowRightIcon } from '../../Icons';
import { Knob, KnobAppearance } from '../../Knob';

const ForbiddenPopup: React.FC = () => {
    const { hasAnyRole } = useUserFeatures();

    let linkUrl = getDestinationUrl(
        'user/authenticate',
        '/user/premium_product/overview',
        {
            itm_content: 'button_zg-basis',
            itm_campaign: 'benutzerkonto_einstellungen',
        },
    );

    if (hasAnyRole(
        USER_ROLE.LicensemanagerBasic,
        USER_ROLE.LicensemanagerPremium,
        USER_ROLE.LicensemanagerCharityBasic,
        USER_ROLE.LicensemanagerCharityPremium,
    )) {
        const isPremium = hasAnyRole(USER_ROLE.LicensemanagerPremium, USER_ROLE.LicensemanagerCharityPremium);
        linkUrl = getDestinationUrl(
            'user/authenticate',
            `/user/${isPremium ? 'license-dashboard' : 'b2bsubscription/overview'}`,
            {
                itm_content: 'button_zg-b2b-basis',
                itm_campaign: 'benutzerkonto_einstellungen',
            },
        );
    }

    return <SC.Wrapper>
        <SC.Content>
            <p>Diese Optionen erhalten Sie mit einem Premium-Abonnement.</p>
            <Knob
                target="_blank"
                appearance={KnobAppearance.Contrasted}
                href={linkUrl}
            >
                Premium holen <ArrowRightIcon />
            </Knob>
        </SC.Content>
    </SC.Wrapper>;
};

export default ForbiddenPopup;
