import styled from 'styled-components';

import { MediaBreakpoints } from '../../../Styles';

export const Wrapper = styled.div`
    & > * {
        min-width: 138px;

        @media screen and ${MediaBreakpoints.medium} {
            min-width: 145px;
        }
    }
`;