import React, { useCallback, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import useAiStore, { MESSAGE_CODE } from '../../../../Hooks/useAiStore';
import { sanitizeRawPromptResponse, splitTextAndWhiteSpace } from '../../../../Util/formatingHelper';
import { apiFetch, RequestMethod } from '../../../../Util/RequestApi';
import { AiTooltip as Tooltip, TOOLTIP_POSITION } from '../../../AiTooltip';
import { RefreshIcon } from '../../../Icons';
import { IconKnob, KnobAppearance, KnobSize } from '../../../Knob';
import { AiEmptyList } from '../AiEmptyList';
import * as SC from '../AiResultPopup.styled';
import { AiSuggestionItem } from '../AiSuggestionItem';
import AiSuggestionLoadingState from '../AiSuggestionLoadingState/AiSuggestionLoadingState';

type SentenceRephraseResponse = {
    checkResults: {
        code: string;
        text: string;
    }
};

const AiRephraseList: React.FC = () => {
    const { text: rawText } = useAiStore(store => store.interaction);

    const [text, endSpace] = splitTextAndWhiteSpace(rawText);

    const { data: paraphraseResponse, isFetching, refetch } = useQuery<SentenceRephraseResponse>({
        queryKey: ['sentence-rephrase', text],
        queryFn: async () => {
            try {
                const response = await apiFetch(
                    'apigateway/llm',
                    {
                        method: RequestMethod.post,
                        baseUrl: process.env.REACT_APP_GATEKEEPER_URI,
                        checkFingerprintId: true,
                        body: JSON.stringify({
                            text: text,
                            frontend_value: 'paraphrase',
                        }),
                    },
                );

                if (response.status !== 200) {
                    return;
                }

                return await response.json();
            } catch (_err) {
                useAiStore.setState({
                    error: {
                        code: MESSAGE_CODE.Common,
                    },
                });
            }
        },
        enabled: !!text,
        staleTime: Number.POSITIVE_INFINITY,
    });

    const handleRefetchClick = useCallback(() => {
        return refetch({ throwOnError: true });
    }, [refetch]);

    const rephraseList = useMemo(() => {
        if (!paraphraseResponse?.checkResults?.text) return;

        const sanitizedList = sanitizeRawPromptResponse<string>(paraphraseResponse?.checkResults?.text);

        return sanitizedList?.filter(Boolean).map((rephrasedText) => ({
            text: `${rephrasedText}${endSpace}`,
            highlitedText: rephrasedText,
        }));
    }, [endSpace, paraphraseResponse]);

    const isEmpty = !isFetching && !rephraseList?.length;

    return <SC.ContentWrapper>
        <SC.Header>
            <span>Satz auswählen</span>
            <Tooltip
                content="Neue Anfrage"
                position={TOOLTIP_POSITION.Bottom}
                shift={TOOLTIP_POSITION.Left}
            >
                <IconKnob
                    icon={<RefreshIcon />}
                    appearance={KnobAppearance.Secondary}
                    size={KnobSize.Large}
                    onClick={handleRefetchClick}
                    disabled={isFetching}
                />
            </Tooltip>
        </SC.Header>
        {isEmpty
            ? <AiEmptyList message="Für diesen Satz sind keine Umformulierungen verfügbar." />
            : (
                <SC.ScrollWrapper>
                    <SC.Content>
                        {
                            isFetching
                                ? <AiSuggestionLoadingState />
                                : rephraseList?.map(((suggestion, i) => (
                                    <AiSuggestionItem
                                        key={`rephrase_${i}`}
                                        text={suggestion.text}
                                        highlitedText={suggestion.highlitedText}
                                    />
                                )))
                        }
                    </SC.Content>
                </SC.ScrollWrapper>
            )}
    </SC.ContentWrapper>;
};

export default AiRephraseList;
