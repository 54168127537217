import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as SC from './AiResultPad.styled';
import { AiResultPadHeader } from './AiResultPadHeader';
import { AiResultPopup } from './AiResultPopup';
import useAiStore from '../../Hooks/useAiStore';
import { getSanitizedInnerText, handleNormalizedEnterPress } from '../../Util/domHelper';
import { AiPadFooter } from '../AiPad/AiPadFooter';
import { AiTextSegment } from '../AiTextSegment';
import { TippIcon } from '../Icons';

type AiResultPadProps = {};

const AiResultPad: React.FC<AiResultPadProps> = () => {
    const segments = useAiStore(store => store.segments);
    const { id: interactionId, segmentId: interactionSegmentId } = useAiStore(store => store.interaction);
    const isHighlightModeActive = useAiStore(store => store.isHighlightModeActive);
    const [resultEditorNode, setResultEditorNode] = useAiStore((store) => [store.resultEditorNode, store.setResultEditorNode]);
    const selectedSegmentId = useAiStore((store) => store.selectedSegmentId);
    const selectSegment = useAiStore((store) => store.handleSelectSegment);
    const activeSegmentId = useAiStore((store) => store.activeSegmentId);

    const [textLength, setTextLength] = useState(resultEditorNode?.innerText?.length ?? 0);

    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const editorWrapperRef = useRef<HTMLDivElement | null>(null);

    const handleEditorChange = useCallback(() => {
        if (resultEditorNode) {
            if (isHighlightModeActive) {
                const sanitizedInnerText = getSanitizedInnerText(resultEditorNode);

                setTextLength(sanitizedInnerText.length);
            } else {
                setTextLength(resultEditorNode.innerText.length);
            }
        }
    }, [resultEditorNode, isHighlightModeActive]);

    const handleEditorKeyPress: React.KeyboardEventHandler<HTMLDivElement> = useCallback((event) => {
        if (event.key === 'Enter') {
            handleNormalizedEnterPress(event);

            handleEditorChange();
        }
    }, [handleEditorChange]);

    const handleMouseLeave = useCallback(() => {
        selectSegment(null, { isForced: true });
    }, [selectSegment]);

    const handlePointerEnter = useCallback(() => {
        useAiStore.setState({ isResultViewInteractive: true });
    }, []);

    useEffect(() => {
        handleEditorChange();
    }, [handleEditorChange, segments, isHighlightModeActive]);

    const isEmpty = !segments.length;

    return (
        <SC.Wrapper ref={wrapperRef}>
            <AiResultPadHeader />
            {isEmpty && <SC.EmptyResult>
                <TippIcon />
                <p>Hier wird Ihr optimierter Text angezeigt.</p>
            </SC.EmptyResult>}
            <SC.EditorWrapper
                ref={editorWrapperRef}
                onPointerEnter={handlePointerEnter}
            >
                <div onMouseLeave={handleMouseLeave}>
                    <SC.Editor
                        ref={setResultEditorNode}
                        contentEditable={!isEmpty}
                        suppressContentEditableWarning
                        placeholder="Beginnen Sie zu schreiben oder fügen Sie einen Text ein."
                        onKeyPress={handleEditorKeyPress}
                        onInput={handleEditorChange}
                        selectedId={activeSegmentId ?? selectedSegmentId ?? interactionSegmentId}
                        interactionId={interactionId}
                    >
                        {isEmpty ? '' : segments.map((segment, index, segments) => (
                            <AiTextSegment
                                key={segment.id}
                                previousKey={segments[index - 1]?.id}
                                data={segment}
                            />
                        ))}
                    </SC.Editor>
                </div>
            </SC.EditorWrapper>
            <AiPadFooter isResultView textLength={textLength} />
            {interactionId && <AiResultPopup wrapperRef={wrapperRef} editorWrapperRef={editorWrapperRef} />}
        </SC.Wrapper>
    );
};

export default AiResultPad;
