import React, { forwardRef } from 'react';

import * as S from './Knob.styled';

export enum KnobAppearance {
    Frame = 'frame',
    Primary = 'primary',
    Secondary = 'secondary',
    Contrasted = 'contrasted',
    Inactive = 'inactive',
    Success = 'success',
}

export enum KnobSize {
    Default = 'default',
    Small = 'small',
    Large = 'large',
}

export enum KnobShape {
    Default = 'default',
    Curved = 'curved',
}


export type KnobProps = (React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
> | React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
>) & {
    appearance?: KnobAppearance | boolean;
    size?: KnobSize;
    shape?: KnobShape;
    href?: HTMLAnchorElement['href'];
    target?: HTMLAnchorElement['target'];
    disabled?: HTMLButtonElement['disabled'];
};

export const Knob = forwardRef<HTMLButtonElement | HTMLAnchorElement, KnobProps>(
    ({ appearance, size, shape, children, ...knobProps }, forwardedRef) => {

        return (
            <S.Button
                as={knobProps.href ? 'a' : 'button'}
                {...knobProps}
                appearance={appearance}
                // @ts-ignore
                ref={forwardedRef}
                size={size}
                shape={shape}
            >
                {children}
            </S.Button>
        );
    },
);
