import styled, { css, keyframes } from 'styled-components';

import { BANNER_APPEARANCE, BannerAppearance } from '../../../Hooks/useGenericStore';
import { Colors, MediaBreakpoints } from '../../../Styles';

const fadeInUp = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const Wrapper = styled.div<{ isLoginRoute: boolean }>`
    position: fixed;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    z-index: 5;
    top: 15px;
    pointer-events: unset;
    
    ${(p) => p.isLoginRoute && css`
        left: 0;
    `}

    @media ${MediaBreakpoints.medium} {
        position: absolute;
        width: unset;
        min-width: 600px;
        padding: 15px 25px;
        top: 70px;

        ${(p) => p.isLoginRoute && css`
            padding-top: 0;
        `}
    }
`;

export const Banner = styled.div<{ appearance?: BannerAppearance }>`
    display: flex;
    z-index: 10;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 15px 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px 0;
    pointer-events: auto;
    border-radius: 2px;
    gap: 10px;
    cursor: pointer;
    animation-duration: 500ms;
    animation-fill-mode: both;
    animation-name: ${fadeInUp};

    ${(p) => {
        switch (p.appearance) {
            case BANNER_APPEARANCE.Error:
                return css`
                    background: ${Colors.cosmos};
                    border-bottom: 1px solid ${Colors.crimson};
                `;
            case BANNER_APPEARANCE.Success:
                return css`
                    background: ${Colors.grannySmithApple};
                    border-bottom: 1px solid ${Colors.pastelGreen};
                `;
            case BANNER_APPEARANCE.Warning:
                return css`
                    background: ${Colors.visVis};
                    border-bottom: 1px solid ${Colors.supernova};
                `;
            case BANNER_APPEARANCE.Info:
            default:
                return css`
                    background: ${Colors.mystic};
                    border-bottom: 1px solid ${Colors.white};
                `;
        }
    }};

    @media ${MediaBreakpoints.medium} {
        padding: 15px;
    }

    svg {
        flex: 0 0 auto;
    }
`;

export const Message = styled.p`
    margin: 0;
    font-weight: bold;
    font-size: 15px;
`;