import React, { useCallback } from 'react';

import { useRouteMatch } from 'react-router-dom';

import * as SC from './AiBannerMessage.styled';
import useGenericStore, { BANNER_APPEARANCE } from '../../../Hooks/useGenericStore';
import { RoundedCheckIcon } from '../../Icons/CheckIconRounded';
import { WarningIcon } from '../../Icons/WarningIcon';

type AiBannerMessageProps = {};

const AiBannerMessage: React.FC<AiBannerMessageProps> = () => {
    const isLoginRoute = !!useRouteMatch({ exact: true, path: '/login' });

    const { message, appearance } = useGenericStore(state => state.banner) || {};
    const setBannerData = useGenericStore(state => state.actions.setBanner);

    const isErrorIcon = appearance === BANNER_APPEARANCE.Error || appearance === BANNER_APPEARANCE.Warning;

    const handleBannerClose = useCallback(() => {
        setBannerData({ message: null });
    }, [setBannerData]);

    return <SC.Wrapper isLoginRoute={isLoginRoute}>
        {message && <SC.Banner onClick={handleBannerClose} appearance={appearance}>
            <SC.Message>{message}</SC.Message>
            {isErrorIcon ? <WarningIcon /> : <RoundedCheckIcon />}
        </SC.Banner>}
    </SC.Wrapper>;
};

export default AiBannerMessage;
