import { create } from 'zustand';
import { persist, createJSONStorage, subscribeWithSelector } from 'zustand/middleware';

import { type Synonym } from './useSynonymList';
import { DocumentIdentifier } from '../Util/DocumentIdentifier';
import { checkCorruptedChildNode, normalizeAdvices, unwrapTextNode } from '../Util/grammarcheckHelper';
import { apiFetch, RequestMethod } from '../Util/RequestApi';
import { type ObjectValues } from '../Util/typesHelper';

export const ADVICE_TYPE = {
    Spelling: 'spelling',
    Style: 'style',
} as const;
export type AdviceType = ObjectValues<typeof ADVICE_TYPE>;

export const ERROR_CODE = {
    DuplicatedWord: 'c006',
} as const;

export type GrammarcheckBlock = { id?: string; type: AdviceType | 'common'; copy: string };

export type Segment = {
    id: string;
    beginOffset: number;
    currentText?: string;
    text: string;
    originText?: string;
    blocks?: GrammarcheckBlock[];
    initSpellAdvices?: Advice[];
    initStyleAdvices?: Advice[];
    spellAdvices?: NormalizedAdvice[];
    styleAdvices?: NormalizedAdvice[];
    isStale?: boolean;
    isActive?: boolean;
    rejectedAdvices?: RejectedAdvice[];
    // error?: boolean;
    issue?: {
        code?: string;
        title?: string;
        message?: string;
    }
};

export type Advice = {
    errorCode: string;
    errorMessage: string;
    shortMessage: string;
    length: number;
    offset: number;
    originalError: string;
    proposals: string[];
    synonyms: Synonym[];
    occurrences: Array<{
        offset: Advice['offset'];
        text: Advice['originalError'];
        synonyms: Advice['synonyms'];
    }>;
    occurrenceIndex: number;
    type: string; // gram
    additionalInformation: string;
    entityKey: string; // TODO
}

export type NormalizedAdvice = Advice & { id: string, adviceType: AdviceType, segmentId: string, acceptedValue?: string };
export type RejectedAdvice = { text: Advice['originalError'], errorCode: Advice['errorCode'] };

export type CorrectionStore = {
    isAlternativeModeActive: boolean,
    setIsAlternativeModeActive: (value: boolean) => void;
    text: string;
    segments: Segment[];
    correctionMode: AdviceType;
    handleSetCorrectionMode: (mode: AdviceType) => void;
    bufferText: string;
    editorNode: HTMLDivElement | null;
    setEditorNode: (node: HTMLDivElement | null) => void;
    handleSegmentize: () => Promise<unknown>;
    isSegmentizeLoading: boolean;
    isSegmentizeSilentLoading: boolean;
    segmentsLoading: Record<string, AbortController | null>;
    cleanEditorNode: () => void;
    synonymQuery?: string;
    synonymId: string;
    handleSetSynonymQuery: (value: string) => void;
    handleCleanSynonymData: () => void;
    handleAcceptAdvice: (advice: NormalizedAdvice, value?: string) => void,
    adviceSuggestion: { id: string, segmentId: string, suggestion: string } | null,
    handleSetAdviceSuggestion: (data: CorrectionStore['adviceSuggestion']) => void,
    charsRemaining?: number;
    handleGrammarcheck: () => void;
    handleSegmentUpdate: (data: { id: string; text?: string; isActive?: boolean }) => void;
    handleSegmentsInvalidate: () => void;
    handleRejectAdvice: (advice: NormalizedAdvice) => void;
    handleSegmentGrammarcheck: (id: string) => Promise<boolean>;
    isGrammarcheckLoading: boolean;
    setBufferText: (text: string) => void;
    syncBufferText: () => void;
    activeIndex: number;
    selectedIndex: number;
    highlightedAdvice?: {
        id: string;
        entityId?: string;
    };
    cursorPosition: {
        index?: number;
        segment?: Element | null;
        segmentId?: string;
        isTyping: boolean;
    };
    handleUpdateCursorPosition: (data: CorrectionStore['cursorPosition']) => void;
    handleSetHighlightedAdvice: (data: CorrectionStore['highlightedAdvice']) => void;
    handleSetActiveIndex: (props: { id?: string; value?: number; shiftValue?: number }) => void;
    handleStartToCloseInlineAdvice: () => void;
    handleStopToCloseInlineAdvice: () => void;
    closeInlineAdviceTimestamp?: NodeJS.Timer;
}

const useCorrectionStore = create<CorrectionStore>()(subscribeWithSelector(persist((set, get) => ({
    isAlternativeModeActive: true,
    setIsAlternativeModeActive: (value) => set({
        isAlternativeModeActive: value,
    }),
    text: '',
    segments: [],
    correctionMode: ADVICE_TYPE.Spelling,
    handleSetCorrectionMode: (mode) => set({ correctionMode: mode }),
    editorNode: null,
    setEditorNode: (node) => set({ editorNode: node }),
    handleSegmentize: async () => {
        const currentText: string | undefined = get().editorNode?.innerText;

        if (!currentText) return;

        if (currentText.length < 1500) {
            const editorNode = get().editorNode;
            if (editorNode) {
                editorNode.innerHTML = '';
            }

            return set({
                segments: [
                    {
                        id: `segment-${new Date().getTime()}-0`,
                        beginOffset: 0,
                        text: currentText,
                        isStale: true,
                        isActive: true,
                    }],
            });
        }

        try {
            set({ isSegmentizeLoading: true, isSegmentizeSilentLoading: true });

            setTimeout(() => {
                set({ isSegmentizeSilentLoading: false });
            }, 2000);

            const response = await apiFetch('apigateway/segmentize-without-ai', {
                method: RequestMethod.post,
                baseUrl: process.env.REACT_APP_GATEKEEPER_URI,
                body: JSON.stringify({
                    text: currentText,
                }),
            });

            const responseData = await response.json();
            const segments: Omit<Segment, 'id'>[] = responseData.sentences;

            if (responseData.error || !segments) {
                set({ isSegmentizeLoading: false });

                return;
            }

            // clean up editor content
            const editorNode = get().editorNode;
            if (editorNode) {
                editorNode.innerHTML = '';
            }

            const timestamp = new Date().getTime();

            const normalizedSegments = segments.map((segment, index) => {
                const id = `segment-${timestamp}-${segment.beginOffset}`;

                return {
                    ...segment,
                    id,
                    isStale: true,
                    isActive: index < 3,
                };
            });

            return set((state) => ({
                    isSegmentizeLoading: false,
                    segments: normalizedSegments,
                }),
            );
        } catch (err) {
            console.error('handleSegmentize ', err);
            set({ isSegmentizeLoading: false });

            return;
        }
    },
    isSegmentizeLoading: false,
    isSegmentizeSilentLoading: false,
    segmentsLoading: {},
    handleSegmentUpdate: ({ id, text, isActive }) => {
        const abortController = get().segmentsLoading[id];
        abortController?.abort('Request is stale');

        set((store) => ({
            segments: store.segments.map((segment) => {
                if (segment.id === id) {
                    let newText = text;

                    if (typeof text === 'undefined') {
                        const segmentNode = get().editorNode?.querySelector(`#${id}`);

                        if (segmentNode) {
                            newText = (segmentNode as HTMLElement).innerText;
                        }
                    }

                    return newText ? {
                        ...segment,
                        currentText: newText,
                        isStale: true,
                        ...(typeof isActive !== 'undefined' && { isActive }),
                    } : segment;
                }

                return segment;
            }),
        }));
    },
    handleSegmentsInvalidate: () => set(({ segments }) => ({ segments: segments.map(segment => ({ ...segment, isStale: true })) })),
    handleRejectAdvice: ({ segmentId, offset: adviceOffset, errorCode, adviceType }) => {
        set((store) => ({
            segments: store.segments.map((segment) => {
                if (segment.id === segmentId) {
                    let rejectedAdvice: RejectedAdvice | undefined;
                    const { initStyleAdvices, initSpellAdvices, rejectedAdvices = [] } = segment;

                    const filterCb = (advice: Advice) => {
                        if (advice.offset === adviceOffset && advice.errorCode === errorCode) {
                            rejectedAdvice = {
                                text: advice.originalError,
                                errorCode: advice.errorCode,
                            };

                            return false;
                        }

                        return true;
                    };

                    const currentSpellAdvices = adviceType === ADVICE_TYPE.Spelling ? initSpellAdvices?.filter(filterCb) : initSpellAdvices;
                    const currentStyleAdvices = adviceType === ADVICE_TYPE.Style ? initStyleAdvices?.filter(filterCb) : initStyleAdvices;

                    const {
                        normalizedSpellAdvices,
                        normalizedStyleAdvices,
                    } = normalizeAdvices(segment, {
                        spellAdvices: currentSpellAdvices,
                        styleAdvices: currentStyleAdvices,
                    });

                    return {
                        ...segment,
                        initStyleAdvices: currentStyleAdvices,
                        initSpellAdvices: currentSpellAdvices,
                        spellAdvices: normalizedSpellAdvices,
                        styleAdvices: normalizedStyleAdvices,
                        ...(rejectedAdvice && {
                            rejectedAdvices: [...rejectedAdvices, rejectedAdvice],
                        }),
                    };
                }

                return segment;
            }),
        }));
    },
    handleSegmentGrammarcheck: async (id: string) => {
        const { isTyping, segmentId } = get().cursorPosition;

        if (isTyping && segmentId && segmentId === id) {
            return false;
        }

        const activeSegment: Segment | undefined = get().segments.find((segment: Segment) => segment.id === id);

        if (!activeSegment) return false;

        const originText = activeSegment.currentText || activeSegment.text;

        try {
            const currentSegmentsLoading = get().segmentsLoading;
            const previousController = currentSegmentsLoading[activeSegment.id];

            previousController?.abort('Request is stale');

            const initController = new AbortController();
            const signal = initController.signal;

            set({ segmentsLoading: { ...currentSegmentsLoading, [activeSegment.id]: initController } });
            const response = await apiFetch('api/grammarcheck', {
                method: RequestMethod.post,
                baseUrl: process.env.REACT_APP_GATEKEEPER_URI,
                body: JSON.stringify({
                        text: originText,
                        userInteraction: false, //TODO
                        documentID: DocumentIdentifier.get(),
                        maxProposals: 7,
                    },
                ),
                signal,
            });

            const { isTyping, segmentId } = get().cursorPosition;

            if (isTyping && segmentId && segmentId === id) {
                return false;
            }

            const currentController = get().segmentsLoading[activeSegment.id];

            if (currentController && currentController !== initController) {
                return false;
            }

            const responseData = await response.json();

            const { data: { spellAdvices, styleAdvices } }: { data: { spellAdvices: Advice[], styleAdvices: Advice[] } } = responseData;

            const resultText = originText;
            // const hasIssue = !!(message || shortMessage);
            const hasError = !!responseData.error || !responseData.charsRemaining;

            set((store) => {
                const normalizedSegments = store.segments.map((segment) => {
                    if (segment.id === id) {
                        const {
                            normalizedSpellAdvices,
                            normalizedStyleAdvices,
                        } = normalizeAdvices(segment, {
                            spellAdvices,
                            styleAdvices,
                        });

                        return {
                            ...segment,
                            text: resultText,
                            originText,
                            currentText: resultText,
                            isStale: false,
                            initSpellAdvices: spellAdvices,
                            initStyleAdvices: styleAdvices,
                            spellAdvices: normalizedSpellAdvices,
                            styleAdvices: normalizedStyleAdvices,
                        };
                    }

                    return segment;
                });

                return {
                    // summary: { ...store.summary, limit: responseData.charsTotal },
                    charsRemaining: responseData.charsRemaining ?? store.charsRemaining ?? 0,
                    segmentsLoading: { ...store.segmentsLoading, [activeSegment.id]: null },
                    segments: normalizedSegments,
                };
            });

            return !hasError;
        } catch (err) {
            console.error('handleSegmentize ', err);
            set({ segmentsLoading: { ...get().segmentsLoading, [activeSegment.id]: null } });
            return false;
        }

    },
    cleanEditorNode: () => set((state) => {
        if (state.editorNode) state.editorNode.innerHTML = '';

        return {
            activeIndex: -1,
            selectedIndex: -1,
            text: '',
            bufferText: '',
            segments: [],
        };
    }),
    synonymQuery: '',
    synonymId: '',
    handleSetSynonymQuery: (query) => {
        return set({
            synonymQuery: query,
            synonymId: `synonym-${new Date().getTime()}`,
        });
    },
    handleCleanSynonymData: () => {
        const synonymNode = document.querySelector('.synonym');

        unwrapTextNode(synonymNode as HTMLElement);

        return set({
            synonymQuery: '',
            synonymId: '',
        });
    },
    handleAcceptAdvice: ({ id, segmentId, adviceType }, acceptedValue) => {
        set((store) => ({
            segments: store.segments.map((segment) => {
                if (segment.id === segmentId && typeof acceptedValue === 'string') {
                    const { spellAdvices, styleAdvices } = segment;

                    const actualAdvices = (adviceType === ADVICE_TYPE.Style ? styleAdvices : spellAdvices)?.map((advice) => {
                        if (advice.id === id) {

                            return {
                                ...advice,
                                acceptedValue,
                            };
                        }

                        return advice;
                    });

                    return adviceType === ADVICE_TYPE.Style ? {
                        ...segment,
                        styleAdvices: actualAdvices,
                    } : {
                        ...segment,
                        spellAdvices: actualAdvices,
                    };
                }

                return segment;
            }),
        }));


        setTimeout(() => {
            get().handleSegmentUpdate({ id: segmentId });
            get().handleSegmentGrammarcheck(segmentId);
        }, 100);
    },
    adviceSuggestion: null,
    handleSetAdviceSuggestion: (data) => set({
        adviceSuggestion: data,
    }),
    handleGrammarcheck: async () => {
        const { isSegmentizeLoading, isSegmentizeSilentLoading, editorNode, handleUpdateCursorPosition } = get();

        handleUpdateCursorPosition({ isTyping: false });

        if (isSegmentizeLoading || isSegmentizeSilentLoading) return;

        if (checkCorruptedChildNode(editorNode)) {
            await get().handleSegmentize();
        }

        const currentSegments = get().segments;

        const filteredSegments = currentSegments
            .filter((segment) => {
                return segment.isStale && segment.isActive;
            });

        for (const [, segment] of filteredSegments.entries()) {
            get().handleSegmentGrammarcheck(segment.id);
        }
    },
    bufferText: '',
    isGrammarcheckLoading: false,
    setBufferText: (text) => set(() => {
        const normalizedText = text.trim();

        return {
            bufferText: normalizedText,
            ...(!normalizedText && { segments: [], text: '' }),
        };
    }),
    syncBufferText: () => set((store) => ({
        text: store.bufferText,
    })),
    activeIndex: -1,
    selectedIndex: -1,
    cursorPosition: {
        isTyping: false,
    },
    handleUpdateCursorPosition: ({ index, isTyping, segmentId, segment }) => {
        const cursorPosition = get().cursorPosition;
        typeof index !== 'undefined' && (cursorPosition.index = index);
        typeof isTyping !== 'undefined' && (cursorPosition.isTyping = isTyping);
        typeof segmentId !== 'undefined' && (cursorPosition.segmentId = segmentId);
        typeof segment !== 'undefined' && (cursorPosition.segment = segment);
    },
    handleSetHighlightedAdvice: (data) => {
        get().handleStopToCloseInlineAdvice();

        set({ highlightedAdvice: data });
    },
    handleSetActiveIndex: ({ id, value, shiftValue }) => set((store) => {
        if (id) {
            const [spellAdvices, styleAdvices] = selectAdvices(store);

            const currentAdvice = [...spellAdvices, ...styleAdvices].find((advice) => advice.id === id);

            if (!currentAdvice) return {};

            const correctionMode = currentAdvice?.adviceType;

            const advices = correctionMode === ADVICE_TYPE.Style ? styleAdvices : spellAdvices;

            const currentIndex = advices.findIndex((advice) => advice.id === id);

            if (currentIndex >= 0) {
                return {
                    activeIndex: currentIndex,
                    correctionMode,
                };
            }

            return {};
        }

        if (typeof value === 'number') {
            return {
                activeIndex: value,
            };
        }

        if (typeof shiftValue === 'number') {
            const currentIndex = store.activeIndex;
            const newIndex = currentIndex + shiftValue;
            const [spellAdvices, styleAdvices] = selectAdvices(store);

            const advices = store.correctionMode === ADVICE_TYPE.Style ? styleAdvices : spellAdvices;

            const activeIndex = Math.max(0, Math.min(advices.length - 1, newIndex));

            return {
                activeIndex,
            };
        }

        return {};
    }),
    handleStartToCloseInlineAdvice: () => {
        get().handleStopToCloseInlineAdvice();

        const closeInlineAdviceTimestamp = setTimeout(() => get().handleSetHighlightedAdvice(undefined), 1000);

        set({
            closeInlineAdviceTimestamp,
        });
    },
    handleStopToCloseInlineAdvice: () => {
        const closeInlineAdviceTimestamp = get().closeInlineAdviceTimestamp;

        if (closeInlineAdviceTimestamp) {
            clearTimeout(closeInlineAdviceTimestamp);

            set({
                closeInlineAdviceTimestamp: undefined,
            });
        }
    },
}), {
    name: 'CorrectionText',
    storage: createJSONStorage(() => sessionStorage),
    partialize: (state) => ({
        text: state.text,
        bufferText: state.bufferText,
        segments: state.segments,
    }),
})));

export const selectAdvices = (store: CorrectionStore) => {
    return store.segments.reduce((acc, segment) => {
        return [
            [...acc[0], ...(segment.spellAdvices?.filter(({ acceptedValue }) => typeof acceptedValue === 'undefined') ?? [])],
            [...acc[1], ...(segment.styleAdvices?.filter(({ acceptedValue }) => typeof acceptedValue === 'undefined') ?? [])],

        ] as [NormalizedAdvice[], NormalizedAdvice[]];
    }, [[], []] as [NormalizedAdvice[], NormalizedAdvice[]]);
};

export const selectActiveAdvice = (store: CorrectionStore) => {
    const [spellAdvices, styleAdvices] = selectAdvices(store);
    const activeIndex = store.activeIndex;
    const advices = store.correctionMode === ADVICE_TYPE.Style ? styleAdvices : spellAdvices;

    return activeIndex > -1 ? advices[activeIndex] : advices[0];
};

export const selectHighlightedAdvice = (store: CorrectionStore) => {
    const [spellAdvices, styleAdvices] = selectAdvices(store);
    const adviceId = store.highlightedAdvice?.id;

    return adviceId ? [...spellAdvices, ...styleAdvices].find(advice => advice.id === adviceId) : undefined;
};

export default useCorrectionStore;