import { useEffect, useLayoutEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';

import useFeatureFlags from './useFeatureFlags';
import useUserFeatures from './useUserFeatures';
import { initHideStyleAdviceAction } from '../Store/UserState';
import { GTMDataLayerPush } from '../Util/GoogleTagManager';
import { USER_ROLE } from '../Util/userfeaturesHelper';

const useInitializeApp = () => {
    const dispatch = useDispatch();

    useFeatureFlags();
    const { data: userData } = useUserFeatures();

    const innerRef = useRef<{
        initialized: boolean;
        received: boolean;
    }>({ initialized: false, received: false });

    useLayoutEffect(() => {
        dispatch(initHideStyleAdviceAction(void 0));
    }, []);

    /** send datalayer push, only when user state was initial loaded */
    useEffect(() => {
        if (!innerRef.current.initialized || innerRef.current.received) {
            innerRef.current.initialized = true;

            return;
        }

        innerRef.current.received = true;

        if (!userData) {
            return;
        }

        GTMDataLayerPush({
            event: 'user_status',
            role: userData.roles ?? [USER_ROLE.Guest],
            hasUsedTrial: userData.hasUsedTrial,
            hasHadPremium: userData.hasHadPremium,
            trialDaysRemaining: userData.trialDaysRemaining,
            characterLimit: userData.characterLimit,
        });
    }, [userData]);
};

export default useInitializeApp;
