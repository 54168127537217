// tslint:disable:no-any
import { all, call, delay, Effect, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import {
    checkResultsExternallyChangedAction,
    editorChangedAction,
    EditorChangePayload,
    getSynonymsFailedAction,
    getSynonymsPendingAction,
    getSynonymsSuccessfulAction,
    keyDownAction,
    rerenderDecorationsAction,
    textCheckAction,
    textCheckErrorAction,
    textCheckPendingAction,
    textCheckReceivedAction,
    textCheckSuccessfulAction,
    textPastedAction,
    UserActionPayload,
    wordHighlightedAction,
} from './Actions';
// import { OneClickEditorState } from "./State";
import { AdviceData, SynonymListProps } from '../../Components/Advices/Util/Props';
import { DocumentIdentifier } from '../../Util/DocumentIdentifier';
import { apiFetch, RequestMethod } from '../../Util/RequestApi';
import { saveEditorContentToStorage } from '../../Util/Storage';
import { resetAdviceAction, UserAdviceInteractionPayload } from '../AdviceState';

export const textCheckDelay = 1000;
const getCharacterLimit = (state: any) => state.userState.characterLimit;

export function* padSaga(): IterableIterator<Effect> {
    yield all([
        // takeLatest<Action<CopyOneClickTextPayload>>(copyOneClickTextAction.type, copyOneClickTextHandler),
        takeLatest<Action<string>>(wordHighlightedAction.type, checkSynonymHandler),
        takeLatest<Action<UserActionPayload>>(textCheckAction.type, checkTextHandler),
        takeLatest<Action<EditorChangePayload>>(editorChangedAction.type, editorChangedHandler),
        takeLatest<Action<UserActionPayload>>(textPastedAction.type, textPastedHandler),
        takeLatest<Action<UserActionPayload>>(keyDownAction.type, keyDownHandler),
        takeLatest<Action<UserActionPayload>>(
            checkResultsExternallyChangedAction.type,
            resultsExternallyChangedHandler,
        ),
        // takeLatest<Action<any>>(triggerOneClickAction.type, triggerOneClickHandler),
    ]);
}

function* editorChangedHandler({ payload: editorChange }: Action<EditorChangePayload>): IterableIterator<Effect> {
    yield delay(500);
    yield call(saveEditorContentToStorage, editorChange.newEditorState);

    if (editorChange.oldEditorState.getCurrentContent() !== editorChange.newEditorState.getCurrentContent()) {
        const characterLimit = yield select(getCharacterLimit);
        // There was a change in the content so we need to rerender decorations for the grey text
        const oldLength = editorChange.oldEditorState.getCurrentContent().getPlainText().length;
        const newLength = editorChange.newEditorState.getCurrentContent().getPlainText().length;

        if (oldLength > characterLimit || newLength > characterLimit) {
            yield put(rerenderDecorationsAction(editorChange.newEditorState));
        }
    }
}

function* textPastedHandler({ payload: { text } }: Action<UserActionPayload>): IterableIterator<Effect> {
    yield put(textCheckPendingAction({ textLength: text.length }));
    yield delay(textCheckDelay);
    yield put(textCheckAction({ text }));
}

function* keyDownHandler({ payload: { text } }: Action<UserActionPayload>): IterableIterator<Effect> {
    yield put(textCheckPendingAction({ textLength: text.length }));
    yield delay(textCheckDelay);
    yield put(textCheckAction({ text }));
}

function* resultsExternallyChangedHandler({ payload: { text } }: Action<UserActionPayload>): IterableIterator<Effect> {
    yield put(textCheckPendingAction({ textLength: text.length }));
    yield delay(500);
    yield put(textCheckAction({ text }));
}

/**
 * Synonyms response handler
 * @param word
 */
function* checkSynonymHandler({ payload: word }: Action<string>): IterableIterator<Effect> {
    yield put(getSynonymsPendingAction(void 0));
    try {
        const characterLimit = yield select(getCharacterLimit);
        const response = yield call(checkSynonym, word, characterLimit);
        if (response.status === 200) {
            const json = yield call([response, response.json]);
            yield put(getSynonymsSuccessfulAction({ query: word, list: json as unknown as SynonymListProps[] }));
        } else {
            yield put(getSynonymsFailedAction(void 0));
        }
    } catch (err) {
        yield put(getSynonymsFailedAction(void 0));
    }
}

/**
 * Spell check response handler
 * @param text
 * @param userInteraction
 */
function* checkTextHandler({
   payload: { text, userInteraction },
}: Action<UserActionPayload>): IterableIterator<Effect> {
    if (text.length === 0) {
        yield call(DocumentIdentifier.reset);
        yield put(resetAdviceAction(void 0));
    } else {
        const characterLimit = yield select(getCharacterLimit);
        try {
            const response = yield call(checkText, text, userInteraction, characterLimit);
            if (response.status === 200 && !reloadForced()) {
                const json = yield call([response, response.json]);
                json.data.spellAdvices = json.data.spellAdvices
                    .reduce((prev: AdviceData[], advice: AdviceData) => {
                        if (prev[advice.offset] === undefined) {
                            prev[advice.offset] = advice;
                        } else if (prev[advice.offset].type === 'gram') {
                            prev[advice.offset] = advice;
                        }
                        return prev;
                    }, [])
                    .filter(() => true);
                yield put(textCheckSuccessfulAction(json));
            } else {
                const errorMessage = yield call([response, response.text]);
                yield put(textCheckErrorAction({ data: errorMessage }));
            }
        } catch (err) {
            yield put(textCheckErrorAction({ data: err.message }));
        }
    }
}

/**
 * Synonyms connection timeout handler
 * @param word
 * @param characterLimit
 * @param timeout
 */
function* checkSynonym(word: string, characterLimit: number, timeout: number = 15000): IterableIterator<Effect> {
    const { response } = yield race({
        response: call(fetchSynonym, trimText(word, characterLimit)),
        timeout: delay(timeout),
    });

    // call to checkText returned first
    if (response) {
        return response;
    } else {
        throw new Error('Connection timed out.');
    }
}

/**
 * Spell check connection timeout handler
 * @param text
 * @param userInteraction
 * @param characterLimit
 * @param timeout
 */
function* checkText(
    text: string,
    // tslint:disable-next-line:no-null-keyword
    userInteraction: UserAdviceInteractionPayload | null = null,
    characterLimit: number,
    timeout: number = 50000,
): IterableIterator<Effect> {
    const trimmedText = trimText(text, characterLimit);
    const { response, timeoutAlert } = yield race({
        response: call(fetchText, trimmedText, userInteraction),
        timeoutAlert: delay(timeout),
        newRequest: take<Action<UserActionPayload>>(textCheckAction.type),
    });
    yield put(textCheckReceivedAction(void 0));

    if (response) {
        return response;
    } else if (timeoutAlert) {
        throw new Error('Connection timed out.');
    }
}

/**
 * Synonyms fetch data from api
 * @param word
 */
function fetchSynonym(word: string): Promise<Response> {
    return apiFetch(
        'api/synonyms',
        {
            method: RequestMethod.post,
            baseUrl: process.env.REACT_APP_GATEKEEPER_URI,
            body: JSON.stringify({
                word,
                numberOfReturnedSynonyms: 50,
                documentID: DocumentIdentifier.get(),
            }),
        },
    );
}

/**
 * Spell check fetch data from api
 * @param text
 * @param userInteraction
 */
// tslint:disable-next-line:no-null-keyword
function fetchText(text: string, userInteraction: UserAdviceInteractionPayload | null = null): Promise<Response> {
    return apiFetch('api/grammarcheck', {
        method: RequestMethod.post,
        baseUrl: process.env.REACT_APP_GATEKEEPER_URI,
        body: JSON.stringify({
            text,
            userInteraction,
            documentID: DocumentIdentifier.get(),
            maxProposals: 7,
        }),
    });
}

/**
 * This function trims text to character Limit and removes last word if it is split by this
 * @param {string} text
 * @returns {string} correctText
 */
export function trimText(text: string, characterLimit: number): string {
    let correctText: string = text;
    if (text.length > characterLimit && characterLimit > 0) {
        const whiteSpace = new RegExp(/^\s$/);
        correctText = text.substr(0, characterLimit);
        const firstExceededChar = text.substr(characterLimit, 1);
        if (!whiteSpace.test(firstExceededChar)) {
            const idx = correctText.lastIndexOf(' ');
            correctText = idx > -1 ? correctText.substr(0, idx) : correctText;
        }
    }
    return correctText;
}

const reloadForced = (): boolean => {
    const search = window.location.search;

    if (search) {
        const params = new URLSearchParams(search);
        return params.get('force_reload') === 'true';
    }

    return false;
};
