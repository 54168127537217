import React from 'react';

import { useRouteMatch } from 'react-router-dom';

import * as SC from './AiDesk.styled';
import useAiStore from '../../Hooks/useAiStore';
import useUserFeatures from '../../Hooks/useUserFeatures';
import { DocumentIdentifier } from '../../Util/DocumentIdentifier';
import { PlatformTypes } from '../../Util/PlatformUtil';
import { Skyscraper } from '../Ads/Skyscraper';
import { AiMenu } from '../AiMenu';
import { AiBannerMessage } from '../AiMessage/AiBannerMessage';


type AiDeskProps = {
    children?: React.ReactNode;
}

const AiDesk: React.FC<AiDeskProps> = (props) => {
    const { data: userData } = useUserFeatures();
    const isLiteVersion = DocumentIdentifier.getPlatformIdentifier() !== PlatformTypes.full;
    const isAiRoute = useRouteMatch({ exact: true, path: '/ai' });
    const isSingleScreenFullMode = useAiStore((store) => store.isSingleScreenFullMode);

    const showAdvertisements = !userData?.features.noAdvertisements;
    const isFullScreenMode = !!isAiRoute && isSingleScreenFullMode;

    return <SC.Wrapper isLite={isLiteVersion}>
        <SC.Sidebar isFullScreenMode={isFullScreenMode}>
            <AiMenu />
        </SC.Sidebar>
        {!isLiteVersion && showAdvertisements && (
            <SC.Advertisement>
                <Skyscraper />
            </SC.Advertisement>
        )}

        <SC.Content isFullScreenMode={isFullScreenMode}>
            <AiBannerMessage />
            {props.children}
        </SC.Content>
    </SC.Wrapper>;
};

export default AiDesk;