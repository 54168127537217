import actionCreatorFactory from 'typescript-fsa';

import { UserDataPayload, CheckSettings } from './State';
import { UserFeatures } from '../../Hooks/useUserFeatures';

const actionCreator = actionCreatorFactory('USER');
export const initializeUserAction = actionCreator<UserFeatures>('INITIALIZE');
export const userDataLoadedAction = actionCreator<UserDataPayload>('DATA_LOADED');
export const userDataFailedAction = actionCreator<void>('DATA_LOADING_FAILED');
export const setHideStyleAdviceAction = actionCreator<boolean>('SET_HIDE_STILE_ADVICE');
export const initHideStyleAdviceAction = actionCreator<void>('INIT_HIDE_STILE_ADVICE');

export interface UserApiFailedPayload {
    errorMessage: string;
}

export interface UpdateCheckSettingsPayload {
    settings: Partial<CheckSettings>,
}

const checkSettingsFactory = actionCreatorFactory('UPDATE_CHECK_SETTINGS');
export const updateCheckSettingsAction = checkSettingsFactory<UpdateCheckSettingsPayload>('SUBMIT');
export const fillCheckSettingsAction = checkSettingsFactory<UpdateCheckSettingsPayload>('FILL');
export const updateCheckSettingsPendingAction = checkSettingsFactory<void>('PENDING');
export const updateCheckSettingsFailedAction = checkSettingsFactory<UserApiFailedPayload>('FAILED');
export const updateCheckSettingsSuccessfulAction = checkSettingsFactory<UpdateCheckSettingsPayload>('SUCCESSFUL');
export const removeMessagesAction = checkSettingsFactory<void>('REMOVE_MESSAGES');
