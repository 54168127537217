import React, { useCallback, useState } from 'react';

import * as SC from './AiTooltip.styled';
import type { ObjectValues } from '../../Util/typesHelper';

export const TOOLTIP_APPEARANCE = {
    Default: 'default',
    Success: 'success',
} as const;
export type TooltipAppearance = ObjectValues<typeof TOOLTIP_APPEARANCE>;

export const TOOLTIP_POSITION = {
    Top: 'top',
    Right: 'right',
    Bottom: 'bottom',
    Left: 'left',
} as const;
export type TooltipPosition = ObjectValues<typeof TOOLTIP_POSITION>;

export type TooltipProps = {
    className?: string;
    content: React.ReactNode;
    controlledContent?: React.ReactNode;
    position?: TooltipPosition;
    shift?: TooltipPosition;
    appearance?: TooltipAppearance;
};

export const AiTooltip: React.FC<TooltipProps> = ({
  children,
  className,
  content,
  controlledContent,
  position,
  shift,
  appearance,
}) => {
    const [isVisible, setIsVisible] = useState(false);

    const isControlled = !!controlledContent;

    const showContent = useCallback(() => setIsVisible(true), []);
    const hideContent = useCallback(() => setIsVisible(false), []);

    return (
        <SC.Wrapper className={className}>
            <SC.Source
                onClick={hideContent}
                onMouseEnter={showContent}
                onMouseLeave={hideContent}
            >
                {children}
            </SC.Source>
            <SC.Content isVisible={isControlled || isVisible} position={position} shift={shift} appearance={appearance}>
                <span>{controlledContent || content}</span>
            </SC.Content>
        </SC.Wrapper>
    );
};
