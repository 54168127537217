import styled, { css } from 'styled-components';

import { Colors, MediaBreakpoints } from '../../../../Styles';
import { loadingTextStyles, synonymHighliteStyles, disabledScrollbarStyles } from '../../../../Styles/Core';

export const Wrapper = styled.div<{ isLoader?: boolean; isOverlapped?: boolean; isRephrase?: boolean }>`
    display: flex;
    box-sizing: border-box;
    height: 49px;
    align-items: center;
    padding-left: 10px;
    gap: 10px;
    cursor: pointer;
    flex-shrink: 0;
    position: relative;
    border-top: 1px solid ${Colors.iron};

    &:first-of-type {
        border-top: unset;
    }

    ${p => p.isLoader
        ? css`
            pointer-events: none;

            & > span {
                ${loadingTextStyles}
            }
        `
        : css`
            &:hover {
                background-color: ${Colors.mystic};
            }
        `}

    ${p => !!p.isOverlapped && css`
        &::after {
            content: ' ';
            position: absolute;
            inset: 0;
            background: linear-gradient(90deg, rgba(239, 243, 245, 0.00) 65%, ${Colors.white} 100%);
            pointer-events: none;
        }
    `}

    ${p => !!p.isRephrase && css`
        height: unset;
        padding: 12px 20px 12px 10px;
    `}
`;

export const Synonym = styled.span`
    white-space: nowrap;
`;

export const Text = styled.span<{ isFullText?: boolean }>`
    ${(p) => !p.isFullText && css`
        white-space: nowrap;
        overflow-x: auto;

        ${disabledScrollbarStyles};

        mark {
            text-decoration-line: underline;
            text-decoration-style: dotted;
            text-decoration-thickness: 2px;
            text-underline-offset: 4px;
            ${synonymHighliteStyles};


            @media screen and ${MediaBreakpoints.medium} {
                text-underline-offset: 5px;
            }
        }
    `}
`;

export const IconWrapper = styled.div`
    display: flex;
    margin-right: -2px;

    svg {
        fill: ${Colors.mineShaft};
    }
`;