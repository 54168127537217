import * as React from 'react';

export const RefreshIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.7228 13.2355C30.5781 13.1206 30.3945 13.0695 30.2128 13.0937C30.0311 13.1178 29.8663 13.2152 29.7551 13.3641L27.4327 16.4502C26.7305 11.641 22.8898 7.95377 18.1501 7.53864C13.4104 7.12351 9.01198 10.0891 7.51961 14.7061C6.02725 19.3231 7.83145 24.3834 11.8801 26.9363C15.9288 29.4892 21.1626 28.8666 24.5296 25.4316C24.7968 25.1585 24.7968 24.7156 24.5296 24.4425C24.2624 24.1693 23.8291 24.1693 23.5619 24.4425C20.6175 27.4532 16.0263 27.9798 12.5024 25.7108C8.9785 23.4418 7.46043 18.9816 8.8456 14.9669C10.2308 10.9521 14.1502 8.45212 18.287 8.94469C22.4239 9.43726 25.6762 12.7912 26.1215 17.0239L22.4878 14.65C22.3323 14.5438 22.1412 14.5071 21.9586 14.5481C21.776 14.5892 21.6176 14.7045 21.5201 14.8676C21.4209 15.0296 21.3911 15.2262 21.4378 15.4114C21.4844 15.5965 21.6034 15.7539 21.7668 15.8468L26.5085 18.9379C26.6203 19.0049 26.7467 19.0423 26.8763 19.0467C27.0888 19.0487 27.2897 18.9478 27.4182 18.7747L30.8632 14.2197C30.9725 14.0703 31.0191 13.8825 30.9928 13.6978C30.9665 13.5132 30.8694 13.3468 30.7228 13.2355Z"
            fill="#333333"
        />
    </svg>
);
