import styled from "styled-components";

import { Colors, MediaBreakpoints } from '../../Styles';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    background-color: ${Colors.cosmos};
    border-radius: 6px;
    padding: 0 10px;

    color: ${Colors.mineShaft};
    font-size: 11px;
    line-height: 14px;
    
    @media screen and ${MediaBreakpoints.medium} {
        font-size: 15px;
        line-height: 22px;
    }
`;

export const TitleWrapper = styled.div`
    display: flex;
    height: 42px;
    align-items: center;
    gap: 10px;

    font-size: 13px;
    line-height: 13px;
    font-weight: bold;
    margin: 0;

    @media screen and ${MediaBreakpoints.medium} {
        height: 54px;
        
        font-size: 15px;
        line-height: 15px;
    }
`;

export const Content = styled.div`
    padding-bottom: 15px;
    
    p {
        margin: 0;
    }
`;

export const ExpandIcon = styled.a`
    position: absolute;
    right: 10px;
    top: 8px;

    @media screen and ${MediaBreakpoints.medium} {
        top: 14px;
    }
`;

export const Message = styled.p``

export const Info = styled.p`
    a {
        font-weight: bold;
    }
`
