import React, { useCallback, useEffect, useRef } from 'react';

import * as SC from './AiSuggestionItem.styled';
import useAiStore, { INTERACTION_MODE } from '../../../../Hooks/useAiStore';
import { ArrowRightIcon } from '../../../Icons';

type AiSuggestionItemProps = {
    word?: string;
    text: string;
    highlitedText?: string;
    isLoader?: boolean;
};

const AiSuggestionItem: React.FC<AiSuggestionItemProps> = ({ word, text, highlitedText, isLoader }) => {
    const { segmentId, mode } = useAiStore(state => state.interaction);
    const setSegment = useAiStore(state => state.setSegment);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const textRef = useRef<HTMLSpanElement | null>(null);
    // const [isOverlapped, setIsOverlapped] = useState(false);

    const isRephraseMode = mode === INTERACTION_MODE.Rephrase;

    const currentText = highlitedText ?? text;

    // TODO: Scope of DUMEN-1926: logic with blurred edge when text is more then container was temporary disabled
    // useEffect(() => {
    //     const wrapperNode = wrapperRef.current;
    //
    //     if (isRephraseMode) return;
    //
    //     const handleOverlapCheck = () => {
    //         if (!isLoader && wrapperNode && (wrapperNode.scrollWidth > wrapperNode.clientWidth)) {
    //             setIsOverlapped(true);
    //         } else {
    //             setIsOverlapped(false);
    //         }
    //     };
    //
    //     handleOverlapCheck();
    //
    //     window.addEventListener('resize', handleOverlapCheck);
    //
    //     return () => {
    //         window.removeEventListener('resize', handleOverlapCheck);
    //     };
    // }, [isLoader, isRephraseMode]);

    useEffect(() => {
        if (!isRephraseMode && textRef.current) {
            const markNode = textRef.current.querySelector('mark');

            if (markNode) {
                const { width: textWidth, left: textLeft } = textRef.current.getBoundingClientRect();
                const { width, left } = markNode.getBoundingClientRect();

                const innerLeft = Math.abs(left - textLeft);
                const expectedLeft = (textWidth - width) / 2;
                const delta = innerLeft - expectedLeft;

                if (delta > 0) {
                    textRef.current.scrollBy({
                        left: delta,
                    });
                }
            }
        }
    }, [isRephraseMode, word]);

    const handleSuggestionAccept = useCallback(() => {
        if (segmentId) {
            setSegment(segmentId, {
                text,
                currentText: text,
                blocks: [],
                traceBlocks: [],
                isSkipped: false,
            });

            useAiStore.setState({ interaction: {} });
        }
    }, [segmentId, setSegment, text]);

    return <SC.Wrapper
        ref={wrapperRef}
        onClick={handleSuggestionAccept}
        isLoader={isLoader}
        isRephrase={isRephraseMode}
    >
        {!!word && <>
            <SC.Synonym>{word}</SC.Synonym>
            <SC.IconWrapper>
                <ArrowRightIcon width={16} height={16} />
            </SC.IconWrapper>
        </>}
        {!!currentText && <SC.Text ref={textRef} isFullText={isRephraseMode} dangerouslySetInnerHTML={{ __html: currentText }} />}
    </SC.Wrapper>;
};

export default AiSuggestionItem;
