import styled, { css } from 'styled-components';

import { Colors, MediaBreakpoints } from '../../../../Styles';
import { AiTooltip } from '../../../AiTooltip';

export const Wrapper = styled.div`
    display: flex;
    height: 48px;
    width: fit-content;
    box-sizing: border-box;
    border-radius: 10px;
    background: ${Colors.white};
    box-shadow: 0 2px 24px 0 rgba(52, 57, 60, 0.32);
    border: 1px solid ${Colors.iron};
    pointer-events: auto;
`;

export const Item = styled.a<{ isActive?: boolean; maxWidth?: number }>`
    display: flex;
    padding: 12px 7px;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;

    ${p => !!p.isActive && css`background-color: ${Colors.mystic};`};

    ${p => !!p.maxWidth && css`
        width: ${p.maxWidth}px;
        padding: 0;
    `};

    @media screen and ${MediaBreakpoints.medium} {
        padding: 12px 25px;

        ${p => !!p.maxWidth && css`
            width: ${p.maxWidth}px;
            padding: 0;
        `};
    }

    &:hover {
        background-color: ${Colors.mystic};
    }

    &:nth-child(n + 2) {
        border-left: 1px solid ${Colors.iron};
    }
`;

export const Tooltip = styled(AiTooltip)`
    display: flex;
    align-items: center;
    height: 40px;
    width: 25px;
`;