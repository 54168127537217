import React from 'react';
import { useLayoutEffect, useState } from 'react';

import { GTMDataLayerPush } from '../../../Util/GoogleTagManager';
import { apiFetch } from '../../../Util/RequestApi';
import { MarketingMenuEntry } from '../../Desk/MarketingDesk';
import { FacebookIcon, InstagramIcon, LinkedinIcon, TwitterIcon, YouTube } from '../../Icons';
import { Logo } from '../../Logo/Logo';

import '../../../Styles/layout/marketing-footer.sass';


type Link = {
    name: string | JSX.Element;
    href: string;
}

type Section = {
    heading: string;
    links: Link[];
}

const MarketingFooter: React.FC = () => {
    const [sections, setSections] = useState<Section[]>([]);
    const mapMarketingFooterMenuEntry = (entries: MarketingMenuEntry[]): Section[] => (
        entries.map(entry => ({
            heading: entry.title,
            links: entry.children.map((child) => ({
                href: child.url,
                name: child.title,
            })),
        }))
    );

    useLayoutEffect(() => {
        apiFetch('api/duden-marketing-menus/marketing-footer')
            .then((response: Response) => {
                if (response.status !== 200) {
                    return [];
                }
                return response.json();
            })
            .then((json: MarketingMenuEntry[]) => setSections(mapMarketingFooterMenuEntry(json)))
            .catch((e) => console.error(e));
    }, []);

    const socialMediaLinks = [
        { icon: <InstagramIcon />, href: 'https://www.instagram.com/dudenverlag/' },
        { icon: <FacebookIcon />, href: 'https://www.facebook.com/Duden/' },
        { icon: <YouTube />, href: 'https://www.youtube.com/user/Dudenverlag' },
        { icon: <LinkedinIcon />, href: 'https://www.linkedin.com/company/bibliographisches-institut-gmbh-dudenverlag/' },
    ];

    const onMenuItemClick = (text: string) => {
        GTMDataLayerPush({
            event: 'dm_navigation_click',
            option: text,
            navigation_location: 'footer',
        });
    };

    return (<div className="marketing-footer__container">
        <footer className="marketing-footer">
            <nav className="marketing-footer__menu">
                {sections.map((section, index) => (
                    <section key={index} className="marketing-footer__section">
                        <h2>{section.heading}</h2>
                        <ul>
                            {section.links.map((link, linkIndex) => (
                                <li key={linkIndex}>
                                    <a href={link.href} target="_blank" rel="noreferrer" onClick={() => onMenuItemClick(link.name.toString())}>{link.name}</a>
                                </li>
                            ))}
                        </ul>
                    </section>
                ))}
                <section className="marketing-footer__section marketing-footer__section--social-media">
                    <h2>Folgen Sie uns</h2>
                    <ul>
                        {socialMediaLinks.map((link, socialIndex) => (
                            <li key={socialIndex}>
                                <a href={link.href} target="_blank" rel="noreferrer">{link.icon}</a>
                            </li>),
                        )}
                    </ul>
                </section>
            </nav>
            <div className="marketing-footer__logo">
                <Logo />
            </div>
        </footer>
    </div>);
};

export default MarketingFooter;
