import React from 'react';

import * as SC from './AiEmptyList.styled';
import EmptyIcon from './EmptyIcon';

type AiEmptyListProps = {
    message: string;
};

const AiEmptyList: React.FC<AiEmptyListProps> = ({ message }) => {
    return <SC.Wrapper>
        <EmptyIcon />
        <SC.Message>
            {message}
        </SC.Message>
    </SC.Wrapper>;
};

export default AiEmptyList;
