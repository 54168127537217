import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { type UserFeatures, type UserSettings } from './useUserFeatures';
import { fillCheckSettingsAction } from '../Store/UserState';
import { apiFetch, RequestMethod } from '../Util/RequestApi';

type SettingsProps = Partial<Pick<
    UserSettings,
    'language'
    | 'applyDpfCommaRules'
    | 'styleWordFrequency'
    | 'styleFiller'
    | 'styleOverlong'
    | 'styleOverlongWordCount'
>>

const useUserSettingsUpdate = () => {
    const dispatch = useDispatch(); // TODO: remove when saga will be skipped
    const queryClient = useQueryClient();

    const mutation = useMutation<unknown, unknown, SettingsProps>({
        mutationFn: async (data) => {
            const response = await apiFetch(
                'api/user/settings',
                {
                    method: RequestMethod.post,
                    body: JSON.stringify(data),
                },
            );

            if (response.status !== 204) {
                throw new Error('Error updating user settings');
            }
        },
        onSuccess: (_data, payload) => {
            queryClient.setQueryData<UserFeatures>(['userfeatures'], (userFeatures) => {
                if (!userFeatures) return userFeatures;

                const updatedSettings = {
                    ...userFeatures?.checkSettings,
                    ...payload,
                };

                // Syncronize user state with redux (to keep old logic working)
                dispatch(fillCheckSettingsAction({ settings: updatedSettings })); // TODO: remove when saga will be skipped

                return {
                    ...userFeatures,
                    checkSettings: updatedSettings,
                };
            });
        },
        networkMode: 'always',
    });

    return mutation;
};

export default useUserSettingsUpdate;