import * as React from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import useAiStore from '../../Hooks/useAiStore';
import { selectUsername, selectUserRoles, UserRole } from '../../Store/UserState';
import { useUserRoles, hasRole } from '../../Util/UserUtils';
import { Menu } from '../Header';
import { UserIcon } from '../Icons';
import { UserStatusLabel } from '../User';

export interface IndicatorLink {
    href: string;
    text: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const ACQ_CHANNEL_PARAM = 'acq_chnl';

function getAccountIndicatorLinks(userRoles: UserRole[]): IndicatorLink[] {
    if (hasRole(UserRole.Authenticated, userRoles)) {
        return [
            { href: process.env.REACT_APP_DUDENDE_URL + '/user/authenticate?destination=/user', text: 'Mein Profil' },
            { href: '/settings', text: 'Einstellungen' },
            { href: '/user/expire', text: 'Abmelden', onClick: () => useAiStore.getState().cleanEditorNode() },
        ];
    }

    return [
        { href: process.env.REACT_APP_PUBLIC_URL + '/user/authenticate?destination=/', text: 'Einloggen' },
        {
            href: process.env.REACT_APP_DUDENDE_URL + '/user/register?source=mentor',
            text: 'Registrieren',
            onClick: (event) => {
                const currentSearchParams = new URLSearchParams(window.location.search);
                const acqChannelValue = currentSearchParams.get(ACQ_CHANNEL_PARAM);

                if (acqChannelValue) {
                    const currentHref = event.currentTarget?.getAttribute('href');

                    if (currentHref) {
                        event.preventDefault();

                        const currentUrl = new URL(currentHref);

                        currentUrl.searchParams.append(ACQ_CHANNEL_PARAM, acqChannelValue);

                        const currentUrlString = currentUrl.toString();

                        window.location.assign(currentUrlString);
                    }
                }
            },
        },
    ];
}

export const AccountIndicator: React.FC = () => {
    const loginStatus = useUserRoles(UserRole.Authenticated);
    const userRoles = useSelector(selectUserRoles);
    const username = useSelector(selectUsername);
    const menuEntry = {
        text: (<span className="account-indicator">
                <span className={classNames('account-indicator__title', {
                    'account-indicator__title--username': loginStatus && username,
                })}>
                    {loginStatus && username ? username : 'Mein Duden'}
                </span>
                <UserStatusLabel />
                <UserIcon />

            </span>),
        children: getAccountIndicatorLinks(userRoles),
        isRight: true,
    };

    return (
        <Menu entries={[menuEntry]} />
    );
};
