import styled, { css } from 'styled-components';

import { Colors, MediaBreakpoints } from '../../../Styles';

type WrapperProps = { isReverse: boolean, offset: number };

export const Wrapper = styled.div<WrapperProps>`
    position: absolute;
    left: -5px;
    display: ${(p) => p.offset ? 'flex' : 'none'};
    z-index: 5;
    gap: 5px;
    max-width: 100%;
    pointer-events: none;

    @media screen and ${MediaBreakpoints.medium} {
        left: -20px;
        right: -20px;
    }

    ${(p) => p.isReverse
        ? css<WrapperProps>`
            flex-direction: column-reverse;
            top: 10px;
            bottom: ${p => p.offset}px;
        ` : css<WrapperProps>`
            flex-direction: column;
            top: ${p => p.offset}px;
            bottom: 10px;
        `}
`;

export const ContentWrapper = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    border-radius: 10px;
    padding: 0;
    background: ${Colors.white};
    box-shadow: 0 2px 24px 0 rgba(52, 57, 60, 0.32);
    font-size: 15px;
    line-height: 22px;
    pointer-events: auto;
    flex-grow: 1;
    min-height: 170px;
    max-height: 300px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    flex: 0 0 auto;
    font-weight: 700;
    margin: 0 15px;
    border-bottom: 1px solid ${Colors.iron};
`;

export const ScrollWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 15px 0;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    flex: 0 1 100%;

    scrollbar-width: auto;
    scrollbar-color: ${Colors.iron} transparent;
`;

export const Content = styled.div`
    padding: 0 15px;
`;