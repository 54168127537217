import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Colors, Fonts, MediaBreakpoints } from '../../../Styles';

const activeStateMobileStyles = css`
    padding: 8px 0 5px 0;
    border-bottom: 3px solid ${Colors.mineShaft};

    & > div {
        background: ${Colors.mystic};
    }
`;

const activeStateStyles = css`
    padding: 0 8px 0 5px;
    border-bottom: none;
    border-left: 3px solid ${Colors.mineShaft};
`;

export const Wrapper = styled(NavLink)<{ isactiveforce?: boolean }>`
    text-decoration: none;
    padding: 8px 0;
    color: ${Colors.mineShaft};
    
    &.active {
        ${activeStateMobileStyles}
    }
    
    ${({ isactiveforce }) => isactiveforce && activeStateMobileStyles}

    @media screen and ${MediaBreakpoints.medium} {
        padding: 0 8px;

        &.active {
           ${activeStateStyles};
        }

        ${({ isactiveforce }) => isactiveforce && activeStateStyles}
    }
`;

export const SystemWrapper = styled(NavLink)`
    padding: 23px 0;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    &.active {
        padding: 23px 0 20px 0;
        border-bottom: 3px solid ${Colors.mineShaft};

        & > div {
            opacity: 0.9;
        }
    }

    @media screen and ${MediaBreakpoints.medium} {
        padding: 0 23px;
        height: auto;
        width: 100%;

        &.active {
            padding: 0 23px 0 20px;
            border-bottom: none;
            border-left: 3px solid ${Colors.mineShaft};
        }
    }
`;

export const SystemContent = styled.div`
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    background-color: ${Colors.mineShaft};
    fill: ${Colors.white};

    &:hover {
        opacity: 0.9;
    }
`;

export const Content = styled.div`
    display: flex;
    position: relative;
    gap: 2px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 74px;
    height: 74px;
    text-align: center;
    border-radius: 10px;

    &:hover {
        background: ${Colors.mystic};
    }
`;

export const Pin = styled.div`
    display: block;
    position: absolute;
    top: -5px;
    right: -5px;
    padding: 2px 6px;
    color: ${Colors.mineShaft};
    background-color: ${Colors.supernova};
    font-family: ${Fonts.theinhardt};
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.3px;
    border-radius: 12px;
    user-select: none;
`;

export const Copy = styled.span`
    color: ${Colors.mineShaft};
    text-align: center;
    font-family: ${Fonts.theinhardt};
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
`;