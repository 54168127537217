import * as React from 'react';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { DictionaryEntry } from './DictionaryEntry';
import * as Dictionary from './DictionaryPanel.styled';
import { useResize } from '../../Hooks/useResize';
import useUserFeatures from '../../Hooks/useUserFeatures';
import {
    addDictionaryEntryAction,
    deleteDictionaryEntryAction,
    DictionaryEntry as DictionaryEntryProps,
    selectDictionaryEntries,
    selectIsLoading,
} from '../../Store/DictionaryState';
import { toastType, ToastOverlay } from '../../Util/ToastOverlay';
import { PremiumTouchPointLink } from '../Advices/Util/PremiumTouchPointLink';
import { AcceptIcon, ArrowRightIcon, LoadingDotsIcon } from '../Icons';
import '../../Styles/component/authoring/dictionary/dictionary.sass';
import { ArrowDownIcon } from '../Icons';
import { CrossSimpleIcon } from '../Icons/CrossSimpleIcon';
import { PlusIcon } from '../Icons/PlusIcon';

export const DictionaryPanel = () => {
    const dispatch = useDispatch();
    const { isMobile, isTablet } = useResize();
    const dictionaryEntries = useSelector(selectDictionaryEntries);
    const isLoading = useSelector(selectIsLoading);
    const { data: userData } = useUserFeatures();
    const [addWordText, setAddWordText] = useState('');
    const [message, setMessage] = useState('');

    const hasUnlimitedDictionaryEntries = !!userData?.features.unlimitedDictionaryEntries;

    const [unlimitedEntriesTeaser, setUnlimitedEntriesTeaser] = useState(!hasUnlimitedDictionaryEntries && dictionaryEntries.length >= 10);
    const [isOpen, setIsOpen] = useState(false);
    const optionsRef = useRef<HTMLDivElement | null>(null);
    const [currentFilter, setCurrentFilter] = useState<'alphabet' | 'zuletzt'>('zuletzt');
    const maximumCharacters = 256;

    /**
     * show premium touchpoint if number of dictionaryEntries exceeds 10 and user has not 'unlimitedDictionaryEntries' feature
     */
    useLayoutEffect(() => {
        if (!hasUnlimitedDictionaryEntries && dictionaryEntries.length >= 10 && !unlimitedEntriesTeaser) {
            setUnlimitedEntriesTeaser(true);
        }
    }, [dictionaryEntries, hasUnlimitedDictionaryEntries]);

    useEffect(() => {
        window.addEventListener('mousedown', handleClickOutside);
        return () => {
            window.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const filteredEntries = useMemo(() => {
        if (dictionaryEntries.length) {
            if (currentFilter === 'alphabet') {
                return dictionaryEntries.slice().sort((a, b) => a.word.localeCompare(b.word));
            } else {
                return dictionaryEntries.slice().sort((a, b) => parseInt(String(b.id)) - parseInt(String(a.id)));
            }
        }
        return [];
    }, [dictionaryEntries, currentFilter]);

    const emptyAddWordText = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        setAddWordText('');
        setMessage('');
    };

    const changeAddWordText = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value.length < 2) {
            setMessage('Bitte mindestens 2 Zeichen eingeben.');
        } else if (event.target.value.split(' ').length > 2) {
            setMessage('Sie können jeweils nur ein Wort oder eine Kombination aus zwei Wörtern hinzufügen.');
        } else if (event.target.value.length >= maximumCharacters) {
            setMessage(`Sie können maximal ${maximumCharacters} Zeichen eingeben, bitte kürzen Sie den Eintrag.`);
        } else {
            setMessage('');
        }

        const existingEntries = dictionaryEntries.filter(
            (entry: DictionaryEntryProps) => entry.word === event.target.value,
        );

        if (existingEntries.length > 0) {
            setMessage('✓ Dieses Wort steht bereits in Ihrem Wörterbuch.');
        }
        setAddWordText(event.target.value);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter') {
            event.preventDefault();
            addToDictionary();
        }
    };

    const handleBlur = () => {
        setIsOpen(false);
    };

    const handleSortClick = () => {
        setIsOpen(prevState => !prevState);
    };

    const addToDictionary = (): void => {
        if (addWordText === '' || addWordText.split(' ').length > 2) {
            return;
        }
        if (!hasUnlimitedDictionaryEntries && dictionaryEntries.length >= 10) {
            setMessage('Sie haben das Limit von zehn Einträgen erreicht. Als Premium-Nutzer können Sie beliebig viele Begriffe in Ihrem persönlichen Wörterbuch speichern.');
            return;
        }
        dispatch(addDictionaryEntryAction({ word: addWordText, doNotCount: true }));
        setAddWordText('');
    };

    const deleteDictionaryEntry = (id: number): void => {
        dispatch(deleteDictionaryEntryAction({ id: id }));
        setMessage('');
    };

    const handleSubmitClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        addToDictionary();
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (optionsRef.current && !optionsRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    const handleFilterChange = () => {
        setCurrentFilter(prevState => prevState === 'alphabet' ? 'zuletzt' : 'alphabet');
        setIsOpen(false);
    };

    const getToastText = (): JSX.Element => {
        if (!userData?.hasUsedTrial) {
            return <>
                Mehr Wörter erhalten Sie mit der Premium-Funktionalität<br />
                <PremiumTouchPointLink />
            </>;
        }

        return <PremiumTouchPointLink />;
    };

    return (
        <Dictionary.Container>
            <Dictionary.Header>
                <Dictionary.Form>
                    <Dictionary.Title htmlFor="add-to-dictionary">
                        Meine Wörter ({dictionaryEntries.length})
                    </Dictionary.Title>
                    <p>In Ihrem Wörterbuch werden alle Begriffe gespeichert, <br />die nicht als Fehler markiert werden sollen.</p>
                    <Dictionary.InputWrapper>
                        <Dictionary.Input
                            id="add-to-dictionary"
                            placeholder="Wort hinzufügen"
                            value={addWordText}
                            onChange={changeAddWordText}
                            onKeyPress={handleKeyPress}
                            autoComplete="off"
                        />
                        {addWordText.length > 0 &&
                            <Dictionary.DeleteButton onClick={emptyAddWordText}>
                                <CrossSimpleIcon />
                            </Dictionary.DeleteButton>
                        }
                        <Dictionary.SubmitButton
                            id="submit-button"
                            onClick={handleSubmitClick}
                            disabled={!!message}
                        >
                            {isTablet || isMobile ? <PlusIcon /> : <>HINZUFÜGEN<ArrowRightIcon /></>}
                        </Dictionary.SubmitButton>
                    </Dictionary.InputWrapper>
                    {message && <Dictionary.Message>{message}</Dictionary.Message>}
                </Dictionary.Form>
                {isLoading && <Dictionary.Loader><LoadingDotsIcon width="60" /></Dictionary.Loader>}
            </Dictionary.Header>
            {dictionaryEntries.length > 0 ? (
                <>
                    <Dictionary.SortingWrapper>
                        {dictionaryEntries.length >= 2 &&
                            <Dictionary.Select ref={optionsRef}>
                                <div className="placeholder" onBlur={handleBlur} onClick={handleSortClick}>
                                    <p>Sortieren nach</p>
                                    <ArrowDownIcon />
                                </div>
                                {isOpen &&
                                    <div className="options">
                                        <div className="option" onClick={handleFilterChange}>Alphabet{currentFilter === 'alphabet' && <AcceptIcon />}</div>
                                        <Dictionary.Divider />
                                        <div className="option" onClick={handleFilterChange}>zuletzt hinzugefügt{currentFilter === 'zuletzt' && <AcceptIcon />}</div>
                                    </div>
                                }
                            </Dictionary.Select>
                        }
                        {unlimitedEntriesTeaser && <Dictionary.ToastContainer id="premium__toast">
                            <ToastOverlay
                                onOverlayClose={() => setUnlimitedEntriesTeaser(false)}
                                type={toastType.ink}
                                positionOffset={{ left: 200, top: 20 }}
                            >
                                {getToastText()}
                            </ToastOverlay>
                        </Dictionary.ToastContainer>}
                    </Dictionary.SortingWrapper>
                    <Dictionary.Main>
                        {filteredEntries.map((entry: DictionaryEntryProps, index) => (
                            <DictionaryEntry key={index} word={entry.word} id={entry.id} onDelete={deleteDictionaryEntry} />
                        ))}</Dictionary.Main>
                </>) : (
                <Dictionary.Main>
                    <Dictionary.NoEntry>Bisher befinden sich keine Wörter im Wörterbuch.</Dictionary.NoEntry>
                </Dictionary.Main>
            )
            }
        </Dictionary.Container>
    );
};
