import * as React from 'react';

export const CheckIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="inherit" {...props}>
        <g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.97174 12.1519L1.62536 8.07717C1.59015 8.04483 1.57031 8.00057 1.57031 7.95436C1.57031 7.90816 1.59015 7.86389 1.62536 7.83156L2.41132 7.09471C2.48494 7.02983 2.59969 7.02983 2.67331 7.09471L6.10274 10.3073L13.2445 3.61191C13.279 3.5789 13.3262 3.5603 13.3755 3.5603C13.4248 3.5603 13.472 3.5789 13.5065 3.61191L14.2925 4.34875C14.363 4.41727 14.363 4.52584 14.2925 4.59436L6.23373 12.1519C6.19924 12.1849 6.15202 12.2035 6.10274 12.2035C6.05345 12.2035 6.00624 12.1849 5.97174 12.1519Z"
                fill="inherit"
            />
        </g>
    </svg>
);