import React, { useCallback, useEffect, useMemo } from 'react';

import useAiStore, { type Segment } from '../../../Hooks/useAiStore';

type AiRootTextSegmentProps = {
    data: Segment;
    previousKey?: string;
};

const AiRootTextSegment: React.FC<AiRootTextSegmentProps> = ({ data, previousKey }) => {
    const { id, text, originText, issue } = data;
    const editorNode = useAiStore((store) => store.editorNode);
    const selectSegment = useAiStore((store) => store.handleSelectSegment);

    // const requestsRemaining = useAiStore((store) => store.requestsRemaining);

    // const isCorrected = !!traceBlocks?.length;
    const hasIssue = !!issue;

    const segmentNode = useMemo(() => {
        const spanNode = document.createElement('span');

        spanNode.classList.add('text-segment');

        if (id) {
            spanNode.id = `editor-${id}`;
        }

        return spanNode;
    }, [id]);

    const handleSegmentClick = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        if (hasIssue) {
            useAiStore.setState({ activeSegmentId: id });
        } else {
            useAiStore.setState({ activeSegmentId: null });
        }
    }, [id, hasIssue]);

    const handleSegmentHighlight = useCallback((_event) => {
        selectSegment(id);
    }, [selectSegment, id]);

    const handleRemoveSegmentHighlight = useCallback((_event) => {
        selectSegment(null);
    }, [selectSegment]);

    // const isBlured = requestsRemaining === 0 && !isCorrected;

    // append dom node
    useEffect(() => {
        const previousSegmentNode = previousKey && editorNode?.querySelector(`#editor-${previousKey}`);

        if (previousSegmentNode) {
            previousSegmentNode.insertAdjacentElement('afterend', segmentNode);
        } else {
            editorNode?.insertAdjacentElement('afterbegin', segmentNode);
        }

    }, [editorNode, previousKey, segmentNode]);


    // cleanup dom node
    useEffect(() => {

        return () => {
            segmentNode?.remove();
        };
    }, [editorNode, segmentNode]);

    useEffect(() => {
        const blocksContent = originText ?? text;

        segmentNode && (segmentNode.innerHTML = blocksContent);
    }, [id, segmentNode]);

    // useEffect(() => {
    //     if (isBlured) {
    //         segmentNode?.classList.add('blured');
    //     } else {
    //         segmentNode?.classList.remove('blured');
    //     }
    //
    // }, [isBlured, segmentNode]);

    useEffect(() => {
        if (hasIssue) {
            segmentNode?.classList.add('issue');
        } else {
            segmentNode?.classList.remove('issue');
        }

    }, [hasIssue, segmentNode]);

    useEffect(() => {
        segmentNode?.addEventListener('click', handleSegmentClick);
        segmentNode?.addEventListener('mouseenter', handleSegmentHighlight);
        segmentNode?.addEventListener('mouseleave', handleRemoveSegmentHighlight);

        return () => {
            segmentNode?.removeEventListener('click', handleSegmentClick);
            segmentNode?.removeEventListener('mouseenter', handleSegmentHighlight);
            segmentNode?.removeEventListener('mouseleave', handleRemoveSegmentHighlight);
        };
    }, [handleSegmentClick, handleSegmentHighlight, handleRemoveSegmentHighlight, segmentNode]);

    return null;
};

export default AiRootTextSegment;