import React from 'react';

import * as SC from './AiMenuItem.styled';

type AiMenuItemProps = {
    to: string;
    text?: string;
    pinLabel?: string;
    icon?: React.ReactNode;
    handleClick?: React.MouseEventHandler<HTMLAnchorElement>;
    isSystem?: boolean;
    isActive?: boolean;
};

const AiMenuItem: React.FC<AiMenuItemProps> = ({ to, text, pinLabel, icon, handleClick, isActive, isSystem }) => {
    if (isSystem) {
        return <SC.SystemWrapper to={to} onClick={handleClick} exact>
            <SC.SystemContent>
                {icon}
            </SC.SystemContent>
        </SC.SystemWrapper>;
    }

    return <SC.Wrapper isactiveforce={isActive} to={to} onClick={handleClick} exact>
        <SC.Content>
            {!!pinLabel && <SC.Pin>{pinLabel}</SC.Pin>}
            {icon}
            {!!text && <SC.Copy>{text}</SC.Copy>}
        </SC.Content>
    </SC.Wrapper>;
};

export default AiMenuItem;
