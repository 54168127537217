import styled from 'styled-components';

import { MediaBreakpoints } from '../../Styles';

export const Wrapper = styled.div`
    display: inline-block;
    padding: 9px 1em 0 71px;

    & > * {
        line-height: 26px;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        height: 40px;
    }

    @media screen and ${MediaBreakpoints.large} {
        padding-left: 0;
    }
`;