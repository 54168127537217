import React from 'react';

import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import * as SC from './AiMenu.styled';
import { AiMenuItem } from './AiMenuItem';
import useUserFeatures from '../../Hooks/useUserFeatures';
import { selectFeatureFlags } from '../../Store/SettingsState';
import { DocumentIdentifier } from '../../Util/DocumentIdentifier';
import { GTMDataLayerPush } from '../../Util/GoogleTagManager';
import { PlatformTypes } from '../../Util/PlatformUtil';
import { DictionaryIcon, MenuAiSpellcheckIcon, MenuSpellcheckIcon, SettingsIcon } from '../Icons';

type AiMenuProps = {};

const AiMenu: React.FC<AiMenuProps> = () => {
    const { isGuestRole } = useUserFeatures();
    const featureFlags = useSelector(selectFeatureFlags);

    const isLiteVersion = DocumentIdentifier.getPlatformIdentifier() !== PlatformTypes.full;

    const showMentorAiView = featureFlags['new_mentor_ai'];
    const mentorDefaultView = featureFlags['mentor_default_view'];

    const isClassicDefaultView = mentorDefaultView !== 'ai';

    const isAiRoute = useRouteMatch({ exact: true, path: '/ai' });

    const openSettingsToast = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
        GTMDataLayerPush({ event: 'app-menu', option: 'settings' });
        if (isGuestRole) {
            event.preventDefault();
        }
    };

    return (
        <SC.Wrapper>
            <SC.MenuGroup isReverse={isClassicDefaultView}>
                {showMentorAiView && <AiMenuItem
                    to="/ai"
                    icon={<MenuAiSpellcheckIcon width={36} height={36} style={{ transform: 'translate(6px, -2px)' }} />}
                    text="KI-Assistent"
                    pinLabel="Neu"
                    handleClick={() => {
                        GTMDataLayerPush({ event: 'app-menu', option: 'express_spellcheck' });
                        GTMDataLayerPush({ event: 'spellcheck_select_option', option: 'mentor_express' });
                    }}
                />}
                <AiMenuItem
                    to="/classic"
                    icon={<MenuSpellcheckIcon width={36} height={36} />}
                    text="Mentor Klassik"
                    handleClick={() => {
                        GTMDataLayerPush({ event: 'app-menu', option: 'spellcheck' });
                        GTMDataLayerPush({ event: 'spellcheck_select_option', option: 'mentor_standard' });
                    }}
                />
            </SC.MenuGroup>
            {!isLiteVersion && <SC.MenuGroup>
                {!isAiRoute && <AiMenuItem
                    to="/dictionary"
                    text="Meine Wörter"
                    icon={<DictionaryIcon width={36} height={36} />}
                    handleClick={() => GTMDataLayerPush({ event: 'app-menu', option: 'dictionary' })}
                />}
                <AiMenuItem
                    to="/settings"
                    icon={<SettingsIcon width={30} height={30} style={{ transform: 'translateX(1px)' }} />}
                    text="Einstellungen"
                    isSystem
                    handleClick={openSettingsToast}
                />
            </SC.MenuGroup>}
        </SC.Wrapper>
    );
};

export default AiMenu;
