import React, { useCallback } from 'react';

import * as SC from './AlertSpace.styled';
import useUserFeatures from '../../Hooks/useUserFeatures';
import { getDestinationUrl } from '../../Util/formatingHelper';
import { GTMDataLayerPush } from '../../Util/GoogleTagManager';
import { USER_ROLE } from '../../Util/userfeaturesHelper';
import { ACQ_CHANNEL_PARAM } from '../AccountIndicator/AccountIndicator';
import { Knob, KnobAppearance } from '../Knob';

const CTA_TRIAL_COPY = 'Jetzt Premium testen';
const CTA_PURCHASE_COPY = 'Jetzt Premium holen';

export const AlertSpace: React.FC = () => {
    const { isGuestRole, isPremiumRole, hasAnyRole } = useUserFeatures();

    const isCtaHidden = isPremiumRole || hasAnyRole(
        USER_ROLE.LicenseeBasic,
        USER_ROLE.PremiumTrial,
        USER_ROLE.Student,
        USER_ROLE.SchoolLicensemanagerBasic,
        USER_ROLE.SchoolLicensemanagerPremium,
        USER_ROLE.StudentLicenseeBasic,
        USER_ROLE.StudentLicenseePremium,
    );

    const handleClickPremium = useCallback(() => {
        if (isGuestRole) {
            GTMDataLayerPush({
                event: 'premium_touchpoint',
                option: 'premium_alert_button',
            });
        }
    }, [isGuestRole]);

    if (isCtaHidden) {
        return null;
    }

    let ctaCopy = CTA_TRIAL_COPY;
    let linkUrl: string | undefined;

    if (isGuestRole) {
        const currentSearchParams = new URLSearchParams(window.location.search);
        const acqChannelValue = currentSearchParams.get(ACQ_CHANNEL_PARAM);

        linkUrl = getDestinationUrl(
            'user/register/private',
            {
                type: 'duden-trial',
                source: 'mentor',
                itm_content: 'cta_jetzt-premium-testen',
                ...(!!acqChannelValue && { [ACQ_CHANNEL_PARAM]: acqChannelValue }),
            },
        );
    } else {
        ctaCopy = CTA_PURCHASE_COPY;

        const isLicensemanagerRole = hasAnyRole(
            USER_ROLE.LicensemanagerBasic,
            USER_ROLE.LicensemanagerPremium,
            USER_ROLE.LicensemanagerCharityBasic,
            USER_ROLE.LicensemanagerCharityPremium,
        );

        if (isLicensemanagerRole) {
            const isPremium = hasAnyRole(USER_ROLE.LicensemanagerPremium, USER_ROLE.LicensemanagerCharityPremium);

            linkUrl = getDestinationUrl(
                'user/authenticate',
                `/user/${isPremium ? 'license-dashboard' : 'b2bsubscription/overview'}`,
                {
                    itm_campaign: 'b2b-basis',
                    itm_content: 'cta_jetzt-premium-holen',
                },
            );
        } else {
            linkUrl = getDestinationUrl(
                'user/authenticate',
                '/user/premium_product/overview',
                {
                    itm_campaign: 'basis',
                    itm_content: 'cta_jetzt-premium-holen',
                },
            );
        }
    }

    if (!linkUrl) {
        return null;
    }

    return (
        <SC.Wrapper id="alert-space">
            <Knob
                href={linkUrl}
                onClick={handleClickPremium}
                appearance={KnobAppearance.Contrasted}
            >
                {ctaCopy} →
            </Knob>
        </SC.Wrapper>
    );
};
