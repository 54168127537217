import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as SC from './AiPadFooter.styled';
import useAiStore from '../../../Hooks/useAiStore';
import { getSanitizedInnerText } from '../../../Util/domHelper';
import { formatToThousandSeparator } from '../../../Util/formatingHelper';
import { gtmEventTypes, sendGTMEvent } from '../../../Util/GoogleTagManager';
import { AiTooltip as Tooltip, TOOLTIP_APPEARANCE, TOOLTIP_POSITION } from '../../AiTooltip';
import { CopyAllIcon } from '../../Icons';
import { IconKnob, KnobAppearance, KnobSize } from '../../Knob';
import { AiPadCta } from '../AiPadCta';

type AiPadFooterProps = {
    isResultView?: boolean;
    textLength?: number;
};

const AiPadFooter: React.FC<AiPadFooterProps> = ({ isResultView, textLength }) => {
    const requestsRemaining = useAiStore((store) => store.requestsRemaining);
    const { requestsLimit, charactersLimit, showCounter } = useAiStore((store) => store.summary);
    const resultEditorNode = useAiStore((store) => store.resultEditorNode);
    const bufferTextLength = useAiStore((store) => store.bufferText).length;
    const isSingleScreenMode = useAiStore((store) => store.isSingleScreenMode);
    const isSingleScreenMobileMode = useAiStore((store) => store.isSingleScreenMobileMode);
    const isSingleScreenResultMode = useAiStore((store) => store.isSingleScreenResultMode);
    const isHighlightModeActive = useAiStore((store) => store.isHighlightModeActive);

    const [successfullCopyMessage, setSuccessfullCopyMessage] = useState<string | null>(null);
    const mutableStateRef = useRef<{ timeoutId: NodeJS.Timeout | null }>({ timeoutId: null });

    const showRequestsLimit = !!showCounter;

    const formattedRequestsRemaining = requestsRemaining && formatToThousandSeparator(requestsRemaining, ' ');

    const currentTextLength = textLength ?? bufferTextLength;
    const currentTextLengthFormatted = formatToThousandSeparator(currentTextLength, ' ');

    const isCharactersLimitReached = typeof charactersLimit === 'number' && charactersLimit < bufferTextLength;

    const hasExtendedView = isSingleScreenMode && isSingleScreenResultMode;

    const showSuccessfullCopyTooltip = useCallback(() => {
        mutableStateRef.current.timeoutId && clearTimeout(mutableStateRef.current.timeoutId);

        setSuccessfullCopyMessage('Text kopiert');

        mutableStateRef.current.timeoutId = setTimeout(() => {
            setSuccessfullCopyMessage(null);
        }, 2000);
    }, []);

    useEffect(() => {
        return () => {
            mutableStateRef.current.timeoutId && clearTimeout(mutableStateRef.current.timeoutId);
        };
    }, []);

    const onCopyToClipboard = useCallback(() => {
        sendGTMEvent(gtmEventTypes.copyText);

        const textField = document.createElement('textarea');

        if (isHighlightModeActive) {
            textField.innerHTML = getSanitizedInnerText(resultEditorNode);
        } else {
            textField.innerHTML = resultEditorNode?.innerText ?? '';
        }

        textField.style.position = 'absolute';
        textField.style.opacity = '0';
        textField.style.top = '0';
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');

        document.body.removeChild(textField);

        showSuccessfullCopyTooltip();
    }, [resultEditorNode, showSuccessfullCopyTooltip, isHighlightModeActive]);

    return <SC.Wrapper>
        <SC.Info>
            <span>
                <SC.CharactersLength isReached={!isResultView && isCharactersLimitReached}>
                    {currentTextLengthFormatted}
                </SC.CharactersLength> Zeichen
            </span>
            {showRequestsLimit && (!isResultView || hasExtendedView) && <span><b>{formattedRequestsRemaining} / {requestsLimit}</b> KI-Abfragen</span>}
        </SC.Info>
        <SC.ActionsWrapper>
            <SC.Actions>
                {(isResultView && currentTextLength > 0) && (
                    <Tooltip
                        position={isSingleScreenMode
                            ? (isSingleScreenMobileMode
                                ? TOOLTIP_POSITION.Right
                                : TOOLTIP_POSITION.Left)
                            : TOOLTIP_POSITION.Top
                        }
                        appearance={successfullCopyMessage ? TOOLTIP_APPEARANCE.Success : TOOLTIP_APPEARANCE.Default}
                        content="Text kopieren"
                        controlledContent={successfullCopyMessage}
                    >
                        <IconKnob
                            onClick={onCopyToClipboard}
                            appearance={KnobAppearance.Secondary}
                            size={KnobSize.Large}
                            icon={<CopyAllIcon />}
                        />
                    </Tooltip>
                )}
            </SC.Actions>
            {(!isResultView || hasExtendedView) && <SC.CtaWrapper>
                <AiPadCta />
            </SC.CtaWrapper>}
        </SC.ActionsWrapper>
    </SC.Wrapper>;
};

export default AiPadFooter;
