import { create } from 'zustand';

import type { ObjectValues } from '../Util/typesHelper';

export const BANNER_APPEARANCE = {
    Error: 'error',
    Success: 'success',
    Info: 'info',
    Warning: 'warning',
} as const;
export type BannerAppearance = ObjectValues<typeof BANNER_APPEARANCE>

export type GenericStore = {
    banner: {
        appearance?: BannerAppearance
        message: string | null;
    } | null;
    actions: {
        setBanner: (data: Partial<GenericStore['banner'] & { visibilityTime?: number }> | null) => void;
    }
};

let bannerTimeoutId: NodeJS.Timeout | undefined;

const useGenericStore = create<GenericStore>()((set) => ({
    banner: null,

    actions: {
        setBanner: (props) => {
            bannerTimeoutId && clearTimeout(bannerTimeoutId);

            const { appearance = BANNER_APPEARANCE.Info, message, visibilityTime } = props || {};

            const bannerData = message ? {
                appearance,
                message,
            } : null;

            set({
                banner: bannerData,
            });

            if (typeof visibilityTime === 'number') {
                bannerTimeoutId = setTimeout(() => {
                    set({
                        banner: null,
                    });
                }, visibilityTime);
            }
        },
    },
}));

export default useGenericStore;