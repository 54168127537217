import React, { useCallback, useLayoutEffect } from 'react';

import * as SC from './AiPad.styled';
import { AiPadFooter } from './AiPadFooter';
import { AiPadHeader } from './AiPadHeader';
import useAiStore from '../../Hooks/useAiStore';
import { handleNormalizedEnterPress } from '../../Util/domHelper';
import { AiRootTextSegment } from '../AiTextSegment';
import { AiTooltip as Tooltip, TOOLTIP_POSITION } from '../AiTooltip';
import { CrossSimpleIcon } from '../Icons';
import { IconKnob, KnobAppearance, KnobSize } from '../Knob';

type AiPadProps = {};

const AiPad: React.FC<AiPadProps> = () => {
    const text = useAiStore(store => store.text);
    const segments = useAiStore(store => store.segments);
    const editorNode = useAiStore((store) => store.editorNode);
    const bufferTextLength = useAiStore((store) => store.bufferText).length;
    const setBufferText = useAiStore((store) => store.setBufferText);
    const setEditorNode = useAiStore((store) => store.setEditorNode);
    const cleanEditorNode = useAiStore((store) => store.cleanEditorNode);
    const selectedSegmentId = useAiStore((store) => store.selectedSegmentId);
    const selectSegment = useAiStore((store) => store.handleSelectSegment);
    const activeSegmentId = useAiStore((store) => store.activeSegmentId);
    const isSingleScreenMode = useAiStore((store) => store.isSingleScreenMode);
    const isSingleScreenMobileMode = useAiStore((store) => store.isSingleScreenMobileMode);

    useLayoutEffect(() => {
        !isSingleScreenMobileMode && editorNode?.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorNode]);

    const handleEditorChange = useCallback(() => {
        useAiStore.setState({ isCorrectionFinished: false });

        if (editorNode) {
            setBufferText(editorNode.innerText);
        }
    }, [editorNode, setBufferText]);

    const handleEditorKeyPress: React.KeyboardEventHandler<HTMLDivElement> = useCallback((event) => {
        if (event.key === 'Enter') {
            handleNormalizedEnterPress(event);

            handleEditorChange();
        }
    }, [handleEditorChange]);

    const handleOnPaste = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        let paste = event.clipboardData.getData('text');
        const selection = window.getSelection();

        if (!selection?.rangeCount) return;
        selection.deleteFromDocument();

        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
        selection.collapseToEnd();
        handleEditorChange();
    }, [handleEditorChange]);

    const handleOnFocus: React.FocusEventHandler<HTMLDivElement> = useCallback(() => {
        useAiStore.setState({ isSingleScreenFullMode: true });
    }, []);

    const handleMouseLeave = useCallback(() => {
        selectSegment(null, { isForced: true });
    }, [selectSegment]);

    const handleCleanEditorNode: React.MouseEventHandler<HTMLButtonElement> = useCallback((event) => {
        event.preventDefault();

        cleanEditorNode();
    }, [cleanEditorNode]);

    const handlePointerEnter = useCallback(() => {
        useAiStore.setState({ isResultViewInteractive: false });
    }, []);

    return (
        <SC.Wrapper>
            <AiPadHeader />
            {!!bufferTextLength &&
                <SC.CloseIconWrapper>
                    <SC.CloseIcon>
                        <Tooltip
                            content="Text löschen"
                            position={isSingleScreenMode ? TOOLTIP_POSITION.Left : TOOLTIP_POSITION.Top}
                        >
                            <IconKnob
                                onClick={handleCleanEditorNode}
                                appearance={KnobAppearance.Secondary}
                                size={KnobSize.Large}
                                icon={<CrossSimpleIcon />}
                            />
                        </Tooltip>
                    </SC.CloseIcon>
                </SC.CloseIconWrapper>
            }
            <SC.EditorWrapper onPointerEnter={handlePointerEnter} onFocus={handleOnFocus}>
                <SC.EditorSubWrapper onMouseLeave={handleMouseLeave}>
                    <SC.Editor
                        ref={setEditorNode}
                        contentEditable
                        suppressContentEditableWarning
                        onPaste={handleOnPaste}
                        onInput={handleEditorChange}
                        onKeyPress={handleEditorKeyPress}
                        placeholder="Beginnen Sie zu schreiben oder fügen Sie einen Text ein."
                        selectedId={activeSegmentId ?? selectedSegmentId}
                    >
                        {!!segments.length ? segments.map((segment, index, segments) => (
                            <AiRootTextSegment
                                key={segment.id}
                                previousKey={segments[index - 1]?.id}
                                data={segment}
                            />
                        )) : text}
                    </SC.Editor>
                </SC.EditorSubWrapper>
            </SC.EditorWrapper>
            <AiPadFooter />
        </SC.Wrapper>
    );
};

export default AiPad;
