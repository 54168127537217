import * as React from 'react';
import { Router } from './Components/Router';
import { MarketingDesk } from 'library/src/Components/Desk/MarketingDesk';
import { DocumentIdentifier } from 'library/src/Util/DocumentIdentifier';
import { PlatformTypes } from 'library/src/Util/PlatformUtil';
import { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Login } from 'library/src/Views/Login';
import useUserFeatures from 'library/src/Hooks/useUserFeatures';
import useInitializeApp from 'library/src/Hooks/useInitializeApp';

export const App = () => {
    useInitializeApp();

    const { data: userData, isGuestRole } = useUserFeatures();

    useEffect(() => {
        DocumentIdentifier.setPlatformIdentifier(isGuestRole ? PlatformTypes.lite : PlatformTypes.full);
    }, [isGuestRole]);

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/" component={userData && (isGuestRole ? MarketingDesk : Router)} />
            </Switch>
        </BrowserRouter>
    );
};
