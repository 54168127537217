import type { ObjectValues } from './typesHelper';

export const USER_ROLE = {
    Guest: 'anonymous',
    Authenticated: 'authenticated',
    Basic: 'duden_basic',
    Pur: 'duden_pur',
    Plus: 'duden_plus',
    PremiumPlus: 'duden_premium_plus',
    LicensemanagerBasic: 'duden_license_manager_basic',
    LicensemanagerCharityBasic: 'duden_license_manager_charity_basic',
    Trial: 'duden_trial',
    Premium20: 'duden_premium_monthly_995',
    Premium: 'duden_premium',
    LicensemanagerPremium: 'duden_license_manager_premium',
    LicenseeBasic: 'duden_licensee_basic',
    LicenseePremium: 'duden_licensee_premium',
    LicensemanagerCharityPremium: 'duden_license_manager_charity_premium',
    LicenseeCharityPremium: 'duden_licensee_charity_premium',
    PremiumTrial: 'duden_premium_trial',
    ParentLicenseManagerBasic: 'duden_parent_license_manager_basic',
    ParentLicensemanagerPremium: 'duden_parent_license_manager_premium',
    SchoolLicensemanagerBasic: 'duden_school_license_manager_basic',
    SchoolLicensemanagerPremium: 'duden_school_license_manager_premium',
    Student: 'student',
    StudentLicenseeBasic: 'duden_student_licensee_basic',
    StudentLicenseePremium: 'duden_student_licensee_premium',
} as const;
export type UserRole = ObjectValues<typeof USER_ROLE>