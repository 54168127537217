import React, { useCallback, useEffect, useState } from 'react';

import * as SC from './Dropdown.styled';
import { Events, GTMDataLayerPush } from '../../Util/GoogleTagManager';
import { CheckIcon } from '../Icons';

export type DropdownItem = {
    name: string;
    description?: string;
    value?: string | null;
    label?: string;
    gtmOption?: Extract<Events, { option?: string }>['option'];
}

type DropdownProps = {
    title?: string;
    labelClassName?: string;
    useMobileHeaderAsDefault?: boolean;
    header: string;
    items: DropdownItem[];
    activeValue?: string | null;
    defaultLabel?: string;
    handleClick?: React.MouseEventHandler<HTMLButtonElement>;
    handleSelect?: (value: string | null) => void;
    alignPanel?: 'left' | 'right';
    hideArrow?: boolean;
    gtmEvent?: Events['event'];
}

const Dropdown: React.FC<DropdownProps> = (props) => {
    const [isOpened, setIsOpened] = useState(false);

    const activeItem = props.activeValue !== undefined ? props.items.find(item => item.value === props.activeValue) : undefined;
    const labelCopy = activeItem?.label || activeItem?.name || props.defaultLabel;

    useEffect(() => {
        const handleClosePanel = () => {
            setIsOpened(false);
        };

        if (isOpened) {
            window.addEventListener('resize', handleClosePanel);
            window.addEventListener('click', handleClosePanel);
        }

        return () => {
            window.removeEventListener('resize', handleClosePanel);
            window.removeEventListener('click', handleClosePanel);
        };
    }, [isOpened]);

    const handleVisibilityToggle = useCallback((event) => {
        event?.preventDefault();
        event?.stopPropagation();

        setIsOpened(value => !value);
    }, []);

    const handleItemClick = (item: DropdownItem): React.MouseEventHandler<HTMLDivElement> => (event) => {
        const { value, gtmOption } = item;

        if (typeof value !== 'undefined') {
            if (props.gtmEvent && gtmOption) {
                GTMDataLayerPush({
                    event: props.gtmEvent,
                    // @ts-ignore
                    option: gtmOption,
                });
            }

            props.handleSelect?.(value);
        }
        handleVisibilityToggle(event);
    };

    return <SC.Wrapper>
        <SC.Label
            isActive={isOpened}
            hideArrow={props.hideArrow}
            className={props.labelClassName}
            onClick={props.handleClick ?? handleVisibilityToggle}
        >
            <SC.LabelTitle>{props.header}</SC.LabelTitle>
            <SC.LabelValue>{labelCopy}</SC.LabelValue>
        </SC.Label>
        {isOpened && <SC.Panel alignPanel={props.alignPanel}>
            {!!props.title && <SC.Title>
                <span>{props.title}</span>
            </SC.Title>}
            {props.items.map((item, i) => {
                if (item.name) {
                    const itemKey = item.value ?? `key_${i}`;
                    const isActive = item.value === props.activeValue;

                    if (item.name === 'separator') {
                        return <SC.Separator key={itemKey} />;
                    }

                    return <SC.Item key={itemKey} isActive={isActive} onClick={handleItemClick(item)}>
                        <div>
                            <div>{item.name}</div>
                            {!!item.description && <SC.ItemDescription>{item.description}</SC.ItemDescription>}
                        </div>
                        <SC.ItemCheckIcon className="dropdown-check-icon">
                            <CheckIcon width="14" height="14" />
                        </SC.ItemCheckIcon>
                    </SC.Item>;
                }

                return null;
            }).filter(Boolean)}
        </SC.Panel>}
    </SC.Wrapper>;
};

export default Dropdown;