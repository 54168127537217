import React, { forwardRef } from 'react';

import * as SC from './Toggle.styled';
import { CheckIcon } from '../Icons';
import { InputAppearance } from '../Input';

type ToggleProps = Omit<
    React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    >,
    'type'
> & {
    label?: React.ReactNode;
    appearance?: InputAppearance;
    type?: 'checkbox' | 'radio';
};

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
    ({ label, appearance, type = 'checkbox', ...toggleProps }, forwardedRef) => {
        const id = toggleProps.id ?? toggleProps.name;

        return (
            <SC.Wrapper>
                <SC.Toggle>
                    <input type={type} {...toggleProps} ref={forwardedRef} id={id} />
                    <SC.Circle>
                        <CheckIcon width={14} height={14} />
                    </SC.Circle>
                </SC.Toggle>
                {label && <SC.Label>{label}</SC.Label>}
            </SC.Wrapper>
        );
    },
);

export default Toggle;