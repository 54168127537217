import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import * as SC from './CorrectionPad.styled';
import useCorrectionStore, { selectActiveAdvice } from '../../Hooks/useCorrectionStore';
import { selectUserStateFromMainState } from '../../Store/UserState';
import { DocumentIdentifier } from '../../Util/DocumentIdentifier';
import { handleNormalizedEnterPress } from '../../Util/domHelper';
import { gtmEventTypes, sendGTMEvent } from '../../Util/GoogleTagManager';
import { getCaretPosition } from '../../Util/grammarcheckHelper';
import { PlatformTypes } from '../../Util/PlatformUtil';
import { hasFeature } from '../../Util/UserUtils';
import { CorrectionTextSegment } from '../CorrectionTextSegment';

type CorrectionPadProps = {};

const handleForceCrash = () => {
    const search = window.location.search;

    if (search) {
        const params = new URLSearchParams(search);
        const forceCrash = params.get('force_crash');

        if (forceCrash === 'true') {
            throw new Error('Crash forced!');
        }
    }
};

const CorrectionPad: React.FC<CorrectionPadProps> = () => {
    const user = useSelector(selectUserStateFromMainState);
    const text = useCorrectionStore(store => store.text);
    const segments = useCorrectionStore(store => store.segments);
    const [editorNode, setEditorNode] = useCorrectionStore((store) => [store.editorNode, store.setEditorNode]);
    const [bufferText, setBufferText] = useCorrectionStore((store) => [store.bufferText, store.setBufferText]);
    const syncBufferText = useCorrectionStore((store) => store.syncBufferText);
    const handleGrammarcheck = useCorrectionStore((store) => store.handleGrammarcheck);
    const synonymId = useCorrectionStore((store) => store.synonymId);
    const setSynonymQuery = useCorrectionStore((store) => store.handleSetSynonymQuery);
    const setHighlightedAdvice = useCorrectionStore((store) => store.handleSetHighlightedAdvice);
    const [activeIndex, correctionMode] = useCorrectionStore((store) => [store.activeIndex, store.correctionMode]);
    const currentAdvice = useCorrectionStore(selectActiveAdvice);

    const innerActionsRef = useRef({
        wasClicked: false,
    });

    const isSynonymsActive = hasFeature('synonyms', user.features);

    const handleDebouncedGrammarcheck = useDebouncedCallback(() => handleGrammarcheck(), 1000);

    useEffect(() => {
        if (DocumentIdentifier.getPlatformIdentifier() !== PlatformTypes.lite && editorNode) {
            editorNode.focus();
        }

        handleForceCrash();
    }, [editorNode]);

    useEffect(() => {
        syncBufferText();
    }, [syncBufferText]);

    useEffect(() => {
        handleDebouncedGrammarcheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bufferText]);

    useEffect(() => {
        if (currentAdvice?.id && editorNode) {
            const adviceNode = editorNode.querySelector(`[data-llm-id="${currentAdvice.id}"]`);

            adviceNode?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeIndex, correctionMode, editorNode]);

    useLayoutEffect(() => {
        handleDebouncedGrammarcheck();
    }, [handleDebouncedGrammarcheck]);

    const handleEditorChange = useCallback(() => {
        if (editorNode) {
            setBufferText(editorNode.innerText);
        }
    }, [editorNode, setBufferText]);

    const handleEditorKeyPress: React.KeyboardEventHandler<HTMLDivElement> = useCallback((event) => {
        if (event.key === 'Enter') {
            handleNormalizedEnterPress(event);

            handleEditorChange();
        }
    }, [handleEditorChange]);

    const handleOnPaste = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        const paste = event.clipboardData.getData('text');
        const selection = window.getSelection();

        if (!selection?.rangeCount) return;
        selection.deleteFromDocument();

        selection.getRangeAt(0).insertNode(document.createTextNode(paste));
        selection.collapseToEnd();
        handleEditorChange();
    }, [handleEditorChange]);

    const handleOnClick = useCallback(() => {
        if (!innerActionsRef.current.wasClicked) {
            sendGTMEvent(gtmEventTypes.padFocus);
            innerActionsRef.current.wasClicked = true;
        }

        setHighlightedAdvice(undefined);
    }, [setHighlightedAdvice]);

    const handleOnScroll = useCallback(() => {
        setHighlightedAdvice(undefined);
    }, [setHighlightedAdvice]);

    const handleOnCopy = useCallback(() => {
        sendGTMEvent(gtmEventTypes.textCopied);
    }, []);

    const handleOnKeyUp: React.KeyboardEventHandler<HTMLDivElement> = useCallback((event) => {
        const isSilent = !!{
            ArrowUp: 'Up',
            ArrowDown: 'Down',
            ArrowLeft: 'Left',
            ArrowRight: 'Right',
        }[event.key];

        getCaretPosition(editorNode, isSilent);
    }, [editorNode]);

    const handleOnPointerUp: React.ReactEventHandler<HTMLDivElement> = useCallback((event) => {
        getCaretPosition(editorNode, true);

        if (isSynonymsActive) {
            const selection = window.getSelection();

            if (selection?.type === 'Range') {
                const synonymQuery = selection.toString();
                const sanitizedSynonymQuery = synonymQuery.trim();

                if (sanitizedSynonymQuery && !sanitizedSynonymQuery.includes(' ')) {
                    const [leftShift, rightShift] = synonymQuery.split(sanitizedSynonymQuery);
                    let range = selection.getRangeAt(0);

                    if (leftShift || rightShift) {
                        const startOffset = range.startOffset + leftShift.length;
                        const endOffset = range.endOffset - rightShift.length;
                        selection.setPosition(selection.anchorNode, startOffset);
                        selection.extend(selection.anchorNode as Node, endOffset);

                        range = selection.getRangeAt(0);
                    }

                    if (sanitizedSynonymQuery) {
                        setSynonymQuery(sanitizedSynonymQuery);
                    }
                }
            }
        }
    }, [editorNode, isSynonymsActive, setSynonymQuery]);

    return (
        <SC.Wrapper
            ref={setEditorNode}
            contentEditable
            suppressContentEditableWarning
            onClick={handleOnClick}
            onScroll={handleOnScroll}
            onCopy={handleOnCopy}
            onPaste={handleOnPaste}
            onInput={handleEditorChange}
            onPointerUp={handleOnPointerUp}
            onKeyUp={handleOnKeyUp}
            onKeyPress={handleEditorKeyPress}
            placeholder="Beginnen Sie zu schreiben oder fügen Sie einen Text ein."
            activeId={currentAdvice?.id}
            synonymId={synonymId}
        >
            {!!segments.length
                ? segments.map((segment, index, segments) => <CorrectionTextSegment
                    key={segment.id}
                    previousKey={segments[index - 1]?.id}
                    data={segment}
                    handleGrammarcheck={handleDebouncedGrammarcheck}
                />)
                : text}
        </SC.Wrapper>
    );
};

export default CorrectionPad;