import React, { useState } from 'react';

import * as SC from './PadBanner.styled';
import { ChevronDownIcon, ChevronUpIcon } from '../Icons';
import { WarningIcon } from '../Icons/WarningIcon';

type PadBannerProps = {
    title: string;
    message: string;
    info?: string;
    className?: string;
    testId?: string;
}

const PadBanner: React.FC<PadBannerProps> = ({ title, message, info, className, testId }) => {
    const [isExpanded, setIsExpanded] = useState(true);

    const handleContentExpand = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();

        setIsExpanded(value => !value);
    };

    return <SC.Wrapper data-testid={testId} className={className}>
        <SC.ExpandIcon href="#" onClick={handleContentExpand}>{isExpanded ? <ChevronDownIcon /> : <ChevronUpIcon />}</SC.ExpandIcon>
        <SC.TitleWrapper>
            <WarningIcon />
            <span>{title}</span>
        </SC.TitleWrapper>
        {isExpanded && <SC.Content>
            <SC.Message>{message}</SC.Message>
            {!!info && <SC.Info dangerouslySetInnerHTML={{ __html: info }} />}
        </SC.Content>}
    </SC.Wrapper>;
};

export default PadBanner;