import React, { useEffect, useRef, useState } from 'react';

import { AiRephraseList } from './AiRephraseList';
import * as SC from './AiResultPopup.styled';
import { AiSynonymList } from './AiSynonymList';
import { AiToolbar } from './AiToolbar';
import useAiStore, { INTERACTION_MODE } from '../../../Hooks/useAiStore';

type AiResultPopupProps = {
    wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
    editorWrapperRef: React.MutableRefObject<HTMLDivElement | null>;
};

const AiResultPopup: React.FC<AiResultPopupProps> = ({ wrapperRef, editorWrapperRef }) => {
    const { segmentId, mode } = useAiStore(store => store.interaction);
    const resultEditorNode = useAiStore(store => store.resultEditorNode);
    const popupRef = useRef<HTMLDivElement | null>(null);

    const [isReverse, setIsReverse] = useState(false);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const handleClosePopup = () => {
            segmentId && useAiStore.setState({ interaction: {} });
        };

        const handleClickOutside = (event: MouseEvent) => {
            const node = event.target as Node | null;

            if (node && popupRef.current?.contains(node)) {
                return;
            }

            handleClosePopup();
        };

        const editorWrapperNode = editorWrapperRef.current;

        editorWrapperNode?.addEventListener('scroll', handleClosePopup);
        resultEditorNode?.addEventListener('keydown', handleClosePopup);
        window.addEventListener('click', handleClickOutside);

        return () => {
            editorWrapperNode?.removeEventListener('scroll', handleClosePopup);
            resultEditorNode?.removeEventListener('keydown', handleClosePopup);
            window.removeEventListener('click', handleClickOutside);
        };
    }, [editorWrapperRef, resultEditorNode, segmentId]);

    useEffect(() => {
        if (segmentId) {
            const wrapperNode = wrapperRef.current;
            const editorWrapperNode = editorWrapperRef.current;
            const segmentNode = resultEditorNode?.querySelector(`#${segmentId}`);

            if (!segmentNode || !wrapperNode || !editorWrapperNode) return;

            const { top: wrapperTop, bottom: wrapperBottom } = wrapperNode.getBoundingClientRect();
            const { top: segmentTop, bottom: segmentBottom, height: segmentHeight } = segmentNode.getBoundingClientRect();

            const topDelta = Math.max(segmentTop - wrapperTop, 0);
            const bottomDelta = Math.max(wrapperBottom - segmentBottom, 0);

            if (bottomDelta > 250) {
                setIsReverse(false);
                setOffset(topDelta + segmentHeight + 12);
            } else if (topDelta > 250) {
                setIsReverse(true);
                setOffset(bottomDelta + segmentHeight + 12);
            } else if (topDelta < bottomDelta) {
                setIsReverse(true);
                setOffset(10);
            } else {
                setIsReverse(false);
                setOffset(10);
            }
        }
    }, [editorWrapperRef, segmentId, resultEditorNode, wrapperRef]);

    return <SC.Wrapper
        ref={popupRef}
        isReverse={isReverse}
        offset={offset}
    >
        <AiToolbar />
        {mode === INTERACTION_MODE.Rephrase && <AiRephraseList />}
        {mode === INTERACTION_MODE.Synonym && <AiSynonymList />}
    </SC.Wrapper>;
};

export default AiResultPopup;
