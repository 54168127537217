import styled from 'styled-components';

import { Colors, MediaBreakpoints } from '../../../Styles';

export const Mark = styled.mark`
    background-color: transparent;
    background-image: linear-gradient(to top, transparent 5%, ${Colors.diamond} 5%, ${Colors.diamond} 40%, transparent 40%);
    border-bottom: 2px dotted;
    padding-bottom: 1px;

    @media screen and ${MediaBreakpoints.medium} {
        padding-bottom: 2px;
    }
`;

export const Trace = styled.span`
    user-modify: read-only;
    user-select: none;
    text-decoration: line-through;
    padding-right: 3px;
`;