import styled from 'styled-components';

import { MESSAGE_APPEARANCE, MessageAppearance } from './AiMessage';
import { Colors, Fonts, MediaBreakpoints } from '../../Styles';

export const Wrapper = styled.div<{ appearance?: MessageAppearance }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background: ${({ appearance }) => appearance === MESSAGE_APPEARANCE.General
        ? Colors.cottonBoll
        : Colors.white
    };
    filter: ${({ appearance }) => appearance === MESSAGE_APPEARANCE.General
        ? 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.10))'
        : 'drop-shadow(0px 2px 10px rgba(52, 57, 60, 0.32))'
    };
    padding: 15px;
    text-align: center;
    color: ${Colors.mineShaft};
    font-family: ${Fonts.theinhardt};
    font-size: 15px;
    font-style: normal;
    line-height: 22px;
    border-radius: 6px;

    @media screen and ${MediaBreakpoints.medium} {
        padding: 20px 30px;
    }
`;

export const CloseIcon = styled.span`
    position: absolute;
    top: 0;
    right: 0;
`;

export const SuccessIconWrapper = styled.div`
    height: 36px;

    ellipse {
        fill: ${Colors.pastelGreen};
    }

    path {
        stroke: ${Colors.black};
    }
`;

export const Title = styled.span`
    font-weight: 700;
`;

export const Content = styled.p`
    margin: 0;
    text-align: center;
`;