import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { RimActionButton } from './RimActionButton';
import useCorrectionStore from '../../Hooks/useCorrectionStore';
import useUserFeatures from '../../Hooks/useUserFeatures';
import { resetAdviceAction } from '../../Store/AdviceState';
import { resetPadAction, selectText, selectTextLength } from '../../Store/PadState';
import { selectFeatureFlags } from '../../Store/SettingsState';
import '../../Styles/component/authoring/rim/rim.sass';
import '../../Styles/component/form/knob/knob.sass';
import { formatToThousandSeparator } from '../../Util/formatingHelper';
import { gtmEventTypes, sendGTMEvent } from '../../Util/GoogleTagManager';
import { isInternetExplorer } from '../../Util/PlatformUtil';
import { ToastOverlay, toastType } from '../../Util/ToastOverlay';

const pointerOffsets = {
    positionDelete: { bottom: 35, right: 19 },
    positionCopy: { bottom: 35, right: 25 },
    deletePointer: { left: 'unset', right: 10 },
    copiedPointer: { left: 'unset', right: 49 },
    wordCounterPointer: { left: 91 },
};

export const Rim: React.FC = () => {
    const [characterLimitReached, setCharacterLimitReached] = useState(false);
    const [copiedToastVisibility, setCopiedToastVisibility] = useState(false);
    const [deleteTextToastVisibility, setDeleteTextToastVisibility] = useState(false);

    const { data: userData } = useUserFeatures();
    const characterLimit = userData?.characterLimit ?? 0;

    const text = useSelector(selectText);
    const classicTextLength = useSelector(selectTextLength);
    const featureFlags = useSelector(selectFeatureFlags);
    const isNativeEditorModeActive = featureFlags['native_editor'];

    const dispatch = useDispatch();

    const editorNode = useCorrectionStore((store) => store.editorNode);
    const editorTextSize = useCorrectionStore(store => store.bufferText.length);
    const cleanEditorNode = useCorrectionStore((store) => store.cleanEditorNode);

    const textLength = isNativeEditorModeActive ? editorTextSize : classicTextLength;

    useEffect(() => {
        if (characterLimitReached && textLength <= characterLimit && characterLimit > 0) {
            setCharacterLimitReached(false);
        } else if (!characterLimitReached && textLength > characterLimit && characterLimit > 0) {
            setCharacterLimitReached(true);
            setCopiedToastVisibility(false);
            setDeleteTextToastVisibility(false);
        }
    }, [characterLimitReached, characterLimit, textLength]);

    const onCopiedOverlayClose = (): void => setCopiedToastVisibility(false);
    const onCopyToClipboard = (): void => {
        sendGTMEvent(gtmEventTypes.copyText);

        const textField = document.createElement('textarea');
        textField.innerHTML = (isNativeEditorModeActive ? editorNode?.innerText : text) ?? '';
        textField.style.position = 'absolute';
        textField.style.opacity = '0';
        textField.style.top = '0';
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');

        // IE clipboard access check - if you can paste the text, clipboard access was granted
        if (isInternetExplorer()) {
            const tempTextField = document.createElement('textarea');
            tempTextField.style.position = 'absolute';
            tempTextField.style.opacity = '0';
            tempTextField.style.top = '0';
            document.body.appendChild(tempTextField);
            tempTextField.select();
            document.execCommand('Paste');

            const didItWork = tempTextField.value === text;

            if (!didItWork) {
                return;
            }

            document.body.removeChild(tempTextField);

            setCopiedToastVisibility(true);
            setDeleteTextToastVisibility(false);
        }

        document.body.removeChild(textField);

        setCopiedToastVisibility(true);
        setDeleteTextToastVisibility(false);
    };

    /**
     * open delete text confirmation toast
     */
    const onDeleteTextStart = (): void => {
        setDeleteTextToastVisibility(true);
        sendGTMEvent(gtmEventTypes.clickDelete);
    };

    /**
     * use deleteTextAction and finish the toast
     */
    const onDeleteTextAccept = (): void => {
        dispatch(resetAdviceAction());

        if (isNativeEditorModeActive) {
            cleanEditorNode();
        } else {
            dispatch(resetPadAction(characterLimit));
        }

        onDeleteTextFinish();
        sendGTMEvent(gtmEventTypes.confirmDelete);
    };

    /**
     * close delete text confirmation
     */
    const onDeleteTextFinish = (): void => setDeleteTextToastVisibility(false);

    const textLengthDigits = textLength.toString().length;

    return (
        <div style={{ position: 'relative' }}>
            {copiedToastVisibility && (
                <ToastOverlay
                    onOverlayClose={onCopiedOverlayClose}
                    positionOffset={pointerOffsets.positionCopy}
                    pointerOffset={pointerOffsets.copiedPointer}
                    type={toastType.success}
                >
                    Ihr Text wurde in die Zwischenablage kopiert.
                </ToastOverlay>
            )}
            {deleteTextToastVisibility && (
                <ToastOverlay
                    onOverlayClose={onDeleteTextFinish}
                    positionOffset={pointerOffsets.positionDelete}
                    pointerOffset={pointerOffsets.deletePointer}
                    type={toastType.padded}
                >
                    Sind Sie sicher, dass Sie diesen Text löschen wollen?
                    <div className="toast__bottom">
                        <button className="knob knob--small" onClick={onDeleteTextAccept}>
                            Löschen
                        </button>
                        {' '}
                        <button className="knob knob--small knob--secondary" onClick={onDeleteTextFinish}>
                            Nicht löschen
                        </button>
                    </div>
                </ToastOverlay>
            )}
            <footer className="rim">
                <dl className="rim__word-counter">
                    <dd className="rim__word-counter__data">
                        {!characterLimitReached && <>
                            <span
                                className={classNames('rim__word-counter__count', {
                                    [`rim__word-counter__count--digits_${textLengthDigits}`]: true,
                                })}
                            >
                                {formatToThousandSeparator(textLength)}
                            </span>{' '}
                            Zeichen
                        </>}
                    </dd>
                </dl>
                <div className="rim__actions">
                    <RimActionButton
                        icon="📋"
                        tooltip="Text in die Zwischenablage kopieren"
                        onClick={onCopyToClipboard}
                        disabled={textLength === 0}
                    />
                    <RimActionButton
                        icon="🗑"
                        tooltip="Text löschen"
                        onClick={onDeleteTextStart}
                        disabled={textLength === 0}
                    />
                </div>
            </footer>
        </div>
    );
};
