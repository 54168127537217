import React, { useCallback, useEffect, useMemo } from 'react';

import { renderToStaticMarkup } from 'react-dom/server';

import { BlockContent } from './BlockContent/BlockContent';
import useAiStore, { AiStore, type Segment } from '../../Hooks/useAiStore';

type AiTextSegmentProps = {
    data: Segment;
    previousKey?: string;
};

const prepareTextForInreaction = (node: HTMLElement) => {
    if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent && /\p{L}/u.test(node.textContent)) {
            const words = node.textContent.split(/(\s+|[.,!?;:]+)/);
            const fragment = document.createDocumentFragment();

            let textNodeContent = '';

            words?.filter(Boolean).forEach((word) => {
                if (!word.trim() || /\s+|[.,!?;:]+/.test(word.trim())) {
                    textNodeContent += word;
                } else {
                    if (textNodeContent) {
                        fragment.appendChild(document.createTextNode(textNodeContent));
                        textNodeContent = '';
                    }

                    const span = document.createElement('span');
                    span.className = 'interaction';
                    span.dataset.llmQuery = word;
                    span.textContent = word;
                    fragment.appendChild(span);
                }
            });

            if (textNodeContent) {
                fragment.appendChild(document.createTextNode(textNodeContent));
            }

            node.replaceWith(fragment);
        }
    } else if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.dataset.llmType === 'trace') {
            return;
        } else if (node.className !== 'interaction') {
            Array.from(node.childNodes).forEach(child => prepareTextForInreaction(child as HTMLElement));
        } else if (node.dataset.llmQuery && node.dataset.llmQuery !== node.innerText) {
            const fragment = document.createDocumentFragment();
            Array.from(node.childNodes).forEach(child => fragment.appendChild(child));

            node.replaceWith(fragment);

            Array.from(fragment.childNodes).forEach(child => prepareTextForInreaction(child as HTMLElement));
        }
    }
};

const AiTextSegment: React.FC<AiTextSegmentProps> = ({ data, previousKey }) => {
    const { id, text, blocks, traceBlocks, isSkipped, originText, issue } = data;
    const isSegmentLoading = useAiStore((store) => store.isSegmentLoading);
    const editorNode: HTMLDivElement | null = useAiStore((store) => store.resultEditorNode);

    const requestsRemaining = useAiStore((store) => store.requestsRemaining);
    const isHighlightModeActive = useAiStore((store) => store.isHighlightModeActive);
    const interactionMode = useAiStore((store) => store.interaction?.mode);
    const isCorrected = !!traceBlocks?.length;
    const hasIssue = !!issue;
    // const isSelected = id === selectedSegmentId;
    // const isClickable = !isActive && !!(isCorrected || issue);

    const segmentNode = useMemo(() => {
        const spanNode = document.createElement('span');

        spanNode.classList.add('text-segment');

        if (id) {
            spanNode.id = id;
        }

        return spanNode;
    }, [id]);

    const handleSegmentClick = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        const interactionElement = event.target.closest('.interaction');
        let interactionData: AiStore['interaction'] = {};

        if (interactionElement && segmentNode && id) {
            const range = document.createRange();
            range.selectNodeContents(segmentNode);
            range.setEndBefore(interactionElement);

            const sentence = segmentNode.innerText;
            const offset = range.toString().length;
            // const length = interactionElement.innerText.length;

            const interactionId = `int_${new Date().getTime()}`;

            interactionElement.dataset.llmIntId = interactionId;

            interactionData = {
                id: interactionId,
                segmentId: id,
                mode: interactionMode,
                text: sentence,
                query: interactionElement.innerText,
                offset,
            };
        }

        useAiStore.setState({
            activeSegmentId: hasIssue ? id : null,
            interaction: interactionData,
        });

    }, [segmentNode, id, hasIssue,interactionMode]);

    const handleSegmentHighlight = useCallback((event: MouseEvent) => {
        useAiStore.setState({ selectedSegmentId: id });

        !hasIssue && event.currentTarget && prepareTextForInreaction(event.currentTarget as HTMLElement);
    }, [id, hasIssue]);

    const isLoading = isSegmentLoading(id);
    const isBlured = requestsRemaining === 0 && !isCorrected;

    // append dom node
    useEffect(() => {
        const previousSegmentNode = previousKey && editorNode?.querySelector(`#${previousKey}`);

        if (previousSegmentNode) {
            previousSegmentNode.insertAdjacentElement('afterend', segmentNode);
        } else {
            editorNode?.insertAdjacentElement('afterbegin', segmentNode);
        }

    }, [editorNode, previousKey, segmentNode]);


    // cleanup dom node
    useEffect(() => {

        return () => {
            segmentNode?.remove();
        };
    }, [editorNode, segmentNode]);

    useEffect(() => {
        const blocksContent = isSkipped && originText ?
            renderToStaticMarkup(<>{originText}</>) :
            renderToStaticMarkup(blocks?.length ? <BlockContent blocks={isHighlightModeActive ? traceBlocks : blocks} /> : <>{text}</>);

        segmentNode && (segmentNode.innerHTML = blocksContent);
    }, [blocks, traceBlocks, isSkipped, originText, segmentNode, text, isHighlightModeActive]);

    useEffect(() => {
        if (isLoading) {
            segmentNode?.classList.add('loading');
        } else {
            segmentNode?.classList.remove('loading');
        }

    }, [isLoading, segmentNode]);

    useEffect(() => {
        if (isBlured) {
            segmentNode?.classList.add('blured');
        } else {
            segmentNode?.classList.remove('blured');
        }

    }, [isBlured, segmentNode]);

    useEffect(() => {
        if (hasIssue) {
            segmentNode?.classList.add('issue');
        } else {
            segmentNode?.classList.remove('issue');
        }

    }, [hasIssue, segmentNode]);

    useEffect(() => {
        segmentNode?.addEventListener('click', handleSegmentClick);
        segmentNode?.addEventListener('mouseenter', handleSegmentHighlight);

        return () => {
            segmentNode?.removeEventListener('click', handleSegmentClick);
            segmentNode?.removeEventListener('mouseenter', handleSegmentHighlight);
        };
    }, [handleSegmentClick, handleSegmentHighlight, segmentNode]);

    return null;
};

export default AiTextSegment;