import { useCallback, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { initializeUserAction } from '../Store/UserState';
import { apiFetch } from '../Util/RequestApi';
import { UserRole, USER_ROLE } from '../Util/userfeaturesHelper';

type UserFeatureName =
    'accessDictionary'
    | 'canActivatePrivateMode'
    | 'canActivateTrial'
    | 'check20kCharacters'
    | 'check40kCharacters'
    | 'displayAsPremium'
    | 'displayAsTrial'
    | 'fillerWords'
    | 'hideAlertSpace'
    | 'inlineAdvices'
    | 'noAdvertisements'
    | 'overlongSentences'
    | 'synonyms'
    | 'unlimitedDictionaryEntries'
    | 'wordFrequency'
    | 'debugErrorCodes';

type UserFeature = {
    name: UserFeatureName;
    available: boolean;
}

export type UserSettings = {
    spelling: boolean;
    styleFiller: boolean;
    styleOverlong: boolean;
    styleOverlongWordCount: number;
    styleWordFrequency: boolean;
    applyDpfCommaRules: boolean;
    language: 'de' | 'ch' | 'at';
    privateMode: boolean;
    liloVersion: string;
    llmVersion: string;
    messages?: {
        type: 'error' | 'success',
        text: string | JSX.Element,
    }
}

export type UserFeatures = {
    availableFeatures: UserFeature[];
    id: string;
    username: string;
    roles: UserRole[];
    hasUsedTrial: boolean;
    trialDuration: number;
    trialDaysRemaining: number;
    characterLimit: number;
    autoLogInterval: number;
    showDiscount: boolean;
    hasHadPremium: boolean;
    checkSettings: UserSettings;
    email: string;
    inlineAdvices: Record<string, string>;
    hide_consent: string;
    hide_onboarding: string;
}

const useUserFeatures = () => {
    const dispatch = useDispatch(); // TODO: remove when saga will be skipped

    const queryResult = useQuery<UserFeatures>({
        queryKey: ['userfeatures'],
        queryFn: async () => {
            try {
                const response = await apiFetch('api/userfeatures', { baseUrl: process.env.REACT_APP_GATEKEEPER_URI });

                if (response.status !== 200) {
                    return;
                }

                const responseJson = await response.json();

                // Syncronize user state with redux (to keep old logic working)
                dispatch(initializeUserAction(responseJson)); // TODO: remove when saga will be skipped

                return responseJson;
            } catch (_err) {
                return;
            }
        },
        staleTime: Number.POSITIVE_INFINITY,
    });

    const normalizedData = useMemo(() => {
        if (queryResult.data) {
            const {
                availableFeatures,
                checkSettings,
                hide_consent,
                hide_onboarding,
                ...restData
            } = queryResult.data;

            const features = availableFeatures.reduce((acc, feature) => {
                acc[feature.name] = feature.available;

                return acc;
            }, {} as Record<UserFeatureName, boolean | string>);

            return {
                ...restData,
                hideConsent: hide_consent,
                hideOnboarding: hide_onboarding,
                features,
                settings: checkSettings,
            };
        }

        return queryResult.data;
    }, [queryResult.data]);

    const hasRole = useCallback(
        (role: UserRole) => !!normalizedData?.roles.includes(role),
        [normalizedData?.roles],
    );

    const hasAnyRole = useCallback(
        (...rolesToCheck: UserRole[]) => rolesToCheck.some(hasRole),
        [hasRole],
    );

    const hasAllRoles = useCallback(
        (...rolesToCheck: UserRole[]) => rolesToCheck.every(hasRole),
        [hasRole],
    );

    const isGuestRole = hasRole(USER_ROLE.Guest);

    const isPremiumRole = hasAnyRole(
        USER_ROLE.Premium,
        USER_ROLE.Premium20,
        USER_ROLE.LicenseePremium,
        USER_ROLE.LicenseeCharityPremium,
        USER_ROLE.PremiumPlus,
    );

    const isTrialRole = hasRole(USER_ROLE.PremiumTrial);

    const isPlusRole = hasRole(USER_ROLE.PremiumPlus);

    const isStudentRole = hasRole(USER_ROLE.Student);

    const isLicenseeRole = hasAnyRole(
        USER_ROLE.LicenseePremium,
        USER_ROLE.LicenseeCharityPremium,
    );

    return {
        ...queryResult,
        data: normalizedData,
        hasRole,
        hasAnyRole,
        hasAllRoles,
        isGuestRole,
        isPremiumRole,
        isTrialRole,
        isPlusRole,
        isStudentRole,
        isLicenseeRole,
    };
};

export default useUserFeatures;