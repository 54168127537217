import styled from 'styled-components';

import { MediaBreakpoints } from '../../Styles';
import { disabledScrollbarStyles } from '../../Styles/Core';

export const Desk = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    
    ${disabledScrollbarStyles};

    @media screen and ${MediaBreakpoints.medium} {
        overflow: hidden;
    }

    @media screen and ${MediaBreakpoints.large} {
        flex-direction: row;
        width: auto;
    }

    footer {
        flex: 0 0 auto;
    }
`;

export const DeskContent = styled.div`
    flex: 1 0 auto;
    position: relative;
    max-height: calc(100vh - 508px);
    padding-top: 25px;

    @media screen and ${MediaBreakpoints.medium} {
        max-height: 340px;
        padding-top: unset;
    }

    @media screen and ${MediaBreakpoints.large} {
        padding-top: unset;
        max-height: 100%;
        height: calc(100% - 79px);
    }
`;

export const Editor = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 0 20px;
    flex: 1 0 40vh;

    @media screen and ${MediaBreakpoints.medium} {
        margin: 70px 25px 0;
        flex: 1 0 40%;
        max-height: 405px;
    }

    @media screen and ${MediaBreakpoints.large} {
        margin: 70px 25px 0;
        max-height: 100%;
    }
`;

export const RightColumnWrapper = styled.div`
    position: relative;
    background: ${(p: { columnColor: string }) => p.columnColor};
    flex: 0 1 35%;

    [role="presentation"] {
        position: relative !important;
    }

    @media screen and ${MediaBreakpoints.medium} {
        min-height: calc(100vh - 490px);
        //max-height: calc(100vh - 505px);
        height: 100%;
        flex-grow: 1;
    }

    @media screen and ${MediaBreakpoints.large} {
        max-height: 100%;
        padding-bottom: 25px;
        flex-grow: 0;
    }
`;

export const AdviceWrapper = styled.div<{ hasPlaceholder?: boolean }>`
    display: flex;
    flex-direction: column;
    height: 96%;
    overflow: visible;
    padding: ${({ hasPlaceholder }) => hasPlaceholder ? 45 : 20}px 10px 0;
    
    &::-webkit-scrollbar {
        display: none;
    }
    
    @media screen and ${MediaBreakpoints.medium} {
        overflow: auto;
        padding: ${({ hasPlaceholder }) => hasPlaceholder ? 35 : 25}px 25px 0;
    }

    @media screen and ${MediaBreakpoints.large} {
        padding: ${({ hasPlaceholder }) => hasPlaceholder ? 70 : 20}px 25px 0;
    }
`;

export const StyledLoadingIcon = styled.div`
    position: absolute;
    top: 110px;
    left: 50%;
    transform: translateX(-50%);
    height: 15px;

    @media screen and ${MediaBreakpoints.medium} {
        top: 70px;
    }

    @media screen and ${MediaBreakpoints.large} {
        top: 90px;
    }
`;