import styled from 'styled-components';

import { Colors, Fonts, MediaBreakpoints } from '../../../Styles';

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;
    background-color: #ffffff8c;
    z-index: 1;

    @media screen and ${MediaBreakpoints.medium} {
        position: absolute;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${Colors.mystic};
    padding: 30px 30px 40px;
    max-width: 280px;

    & > p {
        font-family: ${Fonts.theinhardt};
        font-size: 16px;
        line-height: 25px;
        text-align: center;
        margin: 0 0 10px;
    }
`;