import styled, { css } from 'styled-components';

import { Colors, Fonts, MediaBreakpoints } from '../../../Styles';

export const Wrapper = styled.div`
    position: absolute;
    inset: 0;
    background: ${Colors.white};
    display: flex;
    flex-direction: column;
    gap: 15px;
    z-index: 3;
    padding-top: 30px;

    @media screen and ${MediaBreakpoints.medium} {
        display: none;
    }
`;

export const Header = styled.div`
    display: flex;
    padding: 23px 15px;
    gap: 20px;
    width: 100%;
    border-bottom: 1px solid ${Colors.iron};
`;

export const CloseIconWrapper = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
`;

export const Content = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    gap: 15px;
`;

export const Panel = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    gap: 5px;
    padding: 0 15px 40px;
    font-family: ${Fonts.theinhardt};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const Separator = styled.div`
    border-bottom: 1px solid ${Colors.iron};
`;

export const Item = styled.div<{ isActive?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 10px;
    border-radius: 8px;
    color: ${Colors.mineShaft};
    cursor: pointer;

    &:hover {
        background-color: ${Colors.mystic};
    }
    
    ${({ isActive }) => isActive && css`
        background-color: ${Colors.mystic};

        .settings-check-icon {
            opacity: 1;
        }
    `}
`;

export const ItemCheckIcon = styled.div`
    display: flex;
    width: 26px;
    flex: 0 0 26px;
    justify-content: center;
    align-items: center;
    opacity: 0;
    fill: ${Colors.mineShaft};
`;

export const ItemDescription = styled.div<{ isActive?: boolean }>`
    color: #636363;
    font-size: 12px;
    line-height: 15px;
`;