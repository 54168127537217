import styled from 'styled-components';

import { Colors, MediaBreakpoints } from '../../Styles';

export const Wrapper = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 7px;
    width: 100%;
    height: 100%;
    flex-direction: row;
    box-sizing: border-box;
    border-bottom: 1px solid ${Colors.iron};
    padding: 0 11px;

    @media screen and ${MediaBreakpoints.medium} {
        flex-direction: column;
        gap: 8px;
        align-items: start;
        border-bottom: none;
        border-right: 1px solid ${Colors.iron};
        padding: 20px 0 20px;
    }

    @media screen and ${MediaBreakpoints.large} {
        padding-bottom: 25px;
    }
`;

export const MenuGroup = styled.div<{ isReverse?: boolean }>`
    display: flex;
    align-items: inherit;
    gap: 8px;
    flex-direction: ${({ isReverse }) => isReverse ? 'row-reverse' : 'row'};

    @media screen and ${MediaBreakpoints.medium} {
        flex-direction: ${({ isReverse }) => isReverse ? 'column-reverse' : 'column'};
    }
`;