import React, { useRef } from 'react';

import * as SC from './SpellcheckFull.styled';
import { AdvicePanel } from '../../Components/Advice/AdvicePanel';
import { CorrectionAdvicePanel } from '../../Components/CorrectionAdvicePanel';
import { CorrectionHandshake } from '../../Components/CorrectionHandshake';
import { CorrectionInlineAdviceWrapper } from '../../Components/CorrectionInlineAdvice';
import { CorrectionPad } from '../../Components/CorrectionPad';
import { CorrectionPadBanner } from '../../Components/CorrectionPadBanner';
import { Handshake } from '../../Components/Handshake/Handshake';
import { InlineAdviceWrapper } from '../../Components/InlineAdvice/InlineAdviceWrapper';
import { Pad } from '../../Components/Pad/Pad';
import { Profiler } from '../../Components/Profiler/Profiler';
import { Rim } from '../../Components/Rim/Rim';
import useFeatureFlags from '../../Hooks/useFeatureFlags';
import { Colors } from '../../Styles';
import { AdviceErrorWrapper } from '../../Util/AdviceErrorWrapper';
import { PadErrorWrapper } from '../../Util/PadErrorWrapper';

export const SpellcheckFull: React.FC = () => {
    const { data: featureFlags } = useFeatureFlags();

    const deskRef = useRef(null);

    const isNativeEditorModeActive = featureFlags.nativeEditor;

    return (
        <>
            <SC.Desk>
                <SC.Editor className="duden-editor">
                    <SC.DeskContent ref={deskRef}>
                        {isNativeEditorModeActive ? <>
                            <CorrectionInlineAdviceWrapper deskRef={deskRef} />
                            <CorrectionPad />
                        </> : <>
                            <InlineAdviceWrapper deskRef={deskRef} />
                            <PadErrorWrapper>
                                <Pad />
                            </PadErrorWrapper>
                        </>}
                    </SC.DeskContent>

                    <footer>
                        <Rim />
                    </footer>
                    <CorrectionPadBanner />
                </SC.Editor>

                <SC.RightColumnWrapper columnColor={Colors.mystic}>
                    <SC.AdviceWrapper hasPlaceholder>
                        <div>
                            {isNativeEditorModeActive ? <div>
                                <CorrectionAdvicePanel />
                                <CorrectionHandshake />
                            </div> : <AdviceErrorWrapper>
                                <AdvicePanel />
                                <Handshake />
                            </AdviceErrorWrapper>}
                            <Profiler />
                        </div>
                    </SC.AdviceWrapper>
                </SC.RightColumnWrapper>
            </SC.Desk>
        </>
    );
};