import React, { useCallback, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import useAiStore, { MESSAGE_CODE } from '../../../../Hooks/useAiStore';
import { sanitizeRawPromptResponse, splitTextAndWhiteSpace } from '../../../../Util/formatingHelper';
import { apiFetch, RequestMethod } from '../../../../Util/RequestApi';
import { AiTooltip as Tooltip, TOOLTIP_POSITION } from '../../../AiTooltip';
import { RefreshIcon } from '../../../Icons';
import { IconKnob, KnobAppearance, KnobSize } from '../../../Knob';
import { AiEmptyList } from '../AiEmptyList';
import * as SC from '../AiResultPopup.styled';
import { AiSuggestionItem } from '../AiSuggestionItem';
import AiSuggestionLoadingState from '../AiSuggestionLoadingState/AiSuggestionLoadingState';

type SentenceSynonymResponse = {
    checkResults: {
        code: string;
        text: string;
    }
};

type SentenceSynonym = {
    sentence?: string;
    synonym?: string;
};

const AiSynonymList: React.FC = () => {
    const { query, text: rawText, offset } = useAiStore(store => store.interaction);

    const [text, endSpace] = splitTextAndWhiteSpace(rawText);

    const { data: synonymResponse, isFetching, refetch } = useQuery<SentenceSynonymResponse>({
        queryKey: ['sentence-synonyms', query, text, offset],
        queryFn: async ({ signal }) => {
            if (!query || !text) return;

            const start = offset ?? 0;
            const end = start + query.length;

            const normalizedQueryText = text.substring(0, start) + `<word>${query}</word>` + text.substring(end, text.length);

            try {
                const response = await apiFetch(
                    'apigateway/llm',
                    {
                        method: RequestMethod.post,
                        baseUrl: process.env.REACT_APP_GATEKEEPER_URI,
                        checkFingerprintId: true,
                        body: JSON.stringify({
                            text: normalizedQueryText,
                            frontend_value: 'sentence_synonyme',
                        }),
                        signal,
                    },
                );

                if (response.status !== 200) {
                    return;
                }

                return await response.json();
            } catch (error) {
                if ((error as { name?: string }).name !== 'AbortError') {
                    useAiStore.setState({
                        error: {
                            code: MESSAGE_CODE.Common,
                        },
                    });
                }
            }
        },
        enabled: !!query,
        staleTime: Number.POSITIVE_INFINITY,
    });

    const handleRefetchClick = useCallback(() => {
        return refetch({ throwOnError: true });
    }, [refetch]);

    const synonymList = useMemo(() => {
        if (!synonymResponse?.checkResults?.text) return;

        const sanitizedList = sanitizeRawPromptResponse<SentenceSynonym>(synonymResponse?.checkResults?.text);

        return sanitizedList?.map((synonym) => {
            const newText = synonym.sentence?.replace(/[[\]]/g, '');
            const highlitedText = synonym.sentence?.replace(/\[/g, '<mark>').replace(/]/g, '</mark>');

            return {
                word: synonym.synonym,
                text: typeof newText === 'string' ? `${newText}${endSpace}` : undefined,
                highlitedText,
            };
        });
    }, [synonymResponse, endSpace]);

    const isEmpty = !isFetching && !synonymList?.length;

    return <SC.ContentWrapper>
        <SC.Header>
            <span>Synonym auswählen</span>
            <Tooltip
                content="Neue Anfrage"
                position={TOOLTIP_POSITION.Bottom}
                shift={TOOLTIP_POSITION.Left}
            >
                <IconKnob
                    icon={<RefreshIcon />}
                    appearance={KnobAppearance.Secondary}
                    size={KnobSize.Large}
                    onClick={handleRefetchClick}
                    disabled={isFetching}
                />
            </Tooltip>
        </SC.Header>
        {isEmpty
            ? <AiEmptyList message="Für dieses Wort sind keine Synonyme verfügbar." />
            : (
                <SC.ScrollWrapper>
                    <SC.Content>
                        {
                            isFetching
                                ? <AiSuggestionLoadingState />
                                : synonymList?.map(((synonym, i) =>
                                    synonym.text
                                        ? <AiSuggestionItem
                                            key={`synonym_${i}_${synonym.word || ''}`}
                                            word={synonym.word}
                                            text={synonym.text}
                                            highlitedText={synonym.highlitedText}
                                        />
                                        : null))
                        }
                    </SC.Content>
                </SC.ScrollWrapper>
            )}
    </SC.ContentWrapper>;
};

export default AiSynonymList;
