import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex: 0 1 100%;
    padding: 10px 30px;
    gap: 10px;
`;

export const Message = styled.span`
    text-align: center;
`;