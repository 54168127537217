import React, { useCallback } from 'react';

import * as SC from './AiToolbar.styled';
import useAiStore, { INTERACTION_MODE } from '../../../../Hooks/useAiStore';
import { GTMDataLayerPush } from '../../../../Util/GoogleTagManager';
import { TOOLTIP_POSITION } from '../../../AiTooltip';
import { CrossSimpleIcon, RestoreIcon } from '../../../Icons';

type AiTooltipProps = {};

const AiToolbar: React.FC<AiTooltipProps> = () => {
    const interaction = useAiStore(state => state.interaction);
    const { id, segmentId, mode, text } = interaction;

    const setSegment = useAiStore(state => state.setSegment);
    const segment = useAiStore(state => state.getSegment(segmentId!));

    const isEditedSegment = segment?.originText !== text;

    const handleClose = useCallback((event) => {
        event.preventDefault();

        useAiStore.setState({
            interaction: {},
        });
    }, []);

    const handleRestoreClick = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        GTMDataLayerPush({
            event: 'mentor_express_select_alternative',
            option: 'restore',
        });

        if (!segment) return;

        if (isEditedSegment) {
            setSegment(segmentId!, {
                text: segment.originText,
                currentText: segment.originText,
                blocks: [],
                traceBlocks: [],
            });
        }

        useAiStore.setState({
            interaction: {},
        });
    }, [isEditedSegment, segment, segmentId, setSegment]);

    const handleSynonymClick = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        if (id) {
            const isActive = mode === INTERACTION_MODE.Synonym;

            if (!isActive) {
                GTMDataLayerPush({
                    event: 'mentor_express_select_alternative',
                    option: 'synonyms',
                });
            }

            useAiStore.setState((store) => ({
                interaction: {
                    ...store.interaction,
                    mode: isActive ? undefined : INTERACTION_MODE.Synonym,
                },
            }));
        }
    }, [id, mode]);

    const handleRephraseClick = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        if (id) {
            const isActive = mode === INTERACTION_MODE.Rephrase;

            if (!isActive) {
                GTMDataLayerPush({
                    event: 'mentor_express_select_alternative',
                    option: 'rephrase',
                });
            }

            useAiStore.setState((store) => ({
                interaction: {
                    ...store.interaction,
                    mode: isActive ? undefined : INTERACTION_MODE.Rephrase,
                },
            }));
        }
    }, [id, mode]);

    return <SC.Wrapper>
        {isEditedSegment && <SC.Item href="#" maxWidth={60} onClick={handleRestoreClick}>
            <SC.Tooltip
                content="Originalsatz wiederherstellen"
                position={TOOLTIP_POSITION.Top}
                shift={TOOLTIP_POSITION.Right}
            >
                <RestoreIcon />
            </SC.Tooltip>
        </SC.Item>}
        <SC.Item isActive={mode === INTERACTION_MODE.Synonym} href="#" onClick={handleSynonymClick}>Synonyme</SC.Item>
        <SC.Item isActive={mode === INTERACTION_MODE.Rephrase} href="#" onClick={handleRephraseClick}>Satz umformulieren</SC.Item>
        <SC.Item href="#" maxWidth={45} onClick={handleClose}><CrossSimpleIcon /></SC.Item>
    </SC.Wrapper>;
};

export default AiToolbar;
